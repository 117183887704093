<template>
    <main class="training">
        <div class="article">

            <div class="title">
                <div class="left" v-if="dqksindex != 0" @click="qhh(false)">
                    上一课时- {{ dqkslist[dqksindex - 1].name }}
                </div>
                <!-- {{ dqks.name }}-课前题目 -->
                <el-select v-model="dqvalue" filterable placeholder="请选择" class="selsett" @change="selttchange">
                    <el-option v-for="item in dqkslist" :key="item.id" :label="item.name + '课前题目'" :value="item.id">
                    </el-option>
                </el-select>
                <div class="right" v-if="dqksindex != (dqkslist.length - 1)" @click="qhh(true)">
                    下一课时- {{ dqkslist[dqksindex + 1].name }}
                </div>
            </div>

        </div>
        <div v-for="item in trainQuestions" class="training-test-box">


            <div class="training-test">
                <div class="top-bg"></div>
                <div class="title" style="line-height:30px;padding: 20px ;text-align: left;"
                    v-html="swannotation(item.name)">
                    <!-- {{item ?item.name : "" }} -->
                </div>
                <div class="test-box" v-if="!isTrained">
                    <div class="title-line">
                        <div class="name">
                            <!-- v-show="trainQuestions[activeNum].answer" -->
                            <span v-if="item">
                                <div v-if="item.studentAnswer">
                                    <span style="color: #2c7bfd" v-if="item.answer ==
                                        item.studentAnswer
                                        ">
                                        本题正确答案为:{{
                                            item.answer
                                        }}
                                        {{ '您的回答为' + item.studentAnswer }}
                                        {{ $t("language.myCourses.AnswerTrue")
                                        }}<span v-if="userinfo.groupType != 0 &&
    item.score != 0
    ">
                                            获得相应积分</span></span>

                                    <span v-else-if="trainQuestions[activeNum].answer !==
                                        item.studentAnswer
                                        " style="color: #fd422c">本题正确答案为:{{
        item.answer
    }} {{ '您的回答为' + item.studentAnswer }}

                                        很遗憾您答错了</span>
                                    <!-- <p>本题解析：{{ item.subjectAnalysis
                                    ? item.subjectAnalysis : '暂无解析' }}</p> -->
                                    <p v-if="activeNum == (trainQuestions.length - 1)">

                                        <span>本次测试结束，您一共答对{{ totaldata.num }}题
                                            <span v-if="userinfo.groupType != 0">
                                                {{ $t("language.myCourses.achieving") }}{{ totaldata.fraction
                                                }}{{ $t("language.myCourses.pointsintotal") }}
                                            </span>
                                        </span>

                                    </p>
                                </div>
                            </span>
                        </div>

                    </div>
                    <div class="question-box">
                        <!-- <div class="left">{{ activeNum + 1 }}</div> -->
                        <div class="right">

                            <div class="answer" v-for="(item2, idx) in  JSON.parse(item.subjectItem)" :key="idx">
                                <span style="
                    color: rgb(44, 123, 253);
                    font-size: 20px;
                    font-weight: bold;
                    margin-right: 10px;
                  ">{{ item2.value }}</span>
                                <!-- <span style="
                    color: rgb(44, 123, 253);
                    font-size: 20px;
                    font-weight: bold;
                    margin-right: 10px;
                  " v-if="idx == 0">A</span>
                            <span style="
                    color: rgb(44, 123, 253);
                    font-size: 20px;
                    font-weight: bold;
                    margin-right: 10px;
                  " v-if="idx == 1">B</span>
                            <span style="
                    color: rgb(44, 123, 253);
                    font-size: 20px;
                    font-weight: bold;
                    margin-right: 10px;
                  " v-if="idx == 2">C</span>
                            <span style="
                    color: rgb(44, 123, 253);
                    font-size: 20px;
                    font-weight: bold;
                    margin-right: 10px;
                  " v-if="idx == 3">D</span> -->
                                <el-radio v-model="item.studentAnswer" :disabled="true" :label="item2.value" :class="{
                                    'el-radio': true,
                                    showError: showError && item == item.studentAnswer,
                                }">
                                    {{ item2.label }}
                                </el-radio>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="training-test">
                <div class="top-bg"></div>
                <div class="title" style="line-height:30px;padding: 20px ;text-align: center;">
                    本题解析
                </div>
                <div class="test-box">
                    <div class="title-line" v-if="item.studentAnswer">
                        <div class="name" v-if="item.subjectAnalysis">
                            本题解析： <p v-html="item.subjectAnalysis"></p>
                        </div>
                        <div v-else  class="name">暂无解析</div>
                    </div>
                    <div class="title-line" v-else>
                        <div  class="name">未答题 暂不显示解析</div>

                    </div>


                </div>
            </div>
        </div>
        <div class="no-data" v-if="trainQuestions.length == 0">暂无数据</div>
        <div class="article">
            <div class="title">
                <div class="left" v-if="dqksindex != 0" @click="qhh(false)">
                    上一课时- {{ dqkslist[dqksindex - 1].name }}
                </div>
                <!-- {{ dqks.name }}-课前题目 -->
                <el-select v-model="dqvalue" filterable placeholder="请选择" class="selsett" @change="selttchange">
                    <el-option v-for="item in dqkslist" :key="item.id" :label="item.name + '课堂题目'" :value="item.id">
                    </el-option>
                </el-select>
                <div class="right" v-if="dqksindex != (dqkslist.length - 1)" @click="qhh(true)">
                    下一课时- {{ dqkslist[dqksindex + 1].name }}
                </div>
            </div>
        </div>
        <div v-for="item in trainQuestions2" class="training-test-box">


            <div class="training-test">
                <div class="top-bg"></div>
                <div class="title" style="line-height:30px;padding: 20px ;text-align: left;"
                    v-html="swannotation(item.name)">
                    <!-- {{item ?item.name : "" }} -->
                </div>
                <div class="test-box" v-if="!isTrained">
                    <div class="title-line">
                        <div class="name">
                            <!-- v-show="trainQuestions[activeNum].answer" -->
                            <span v-if="item">
                                <div v-if="item.studentAnswer">
                                    <span style="color: #2c7bfd" v-if="item.answer ==
                                        item.studentAnswer
                                        ">
                                        本题正确答案为:{{
                                            item.answer
                                        }}
                                        {{ '您的回答为' + item.studentAnswer }}
                                        {{ $t("language.myCourses.AnswerTrue")
                                        }}<span v-if="userinfo.groupType != 0 &&
    item.score != 0
    ">
                                            获得相应积分</span></span>

                                    <span v-else-if="trainQuestions[activeNum].answer !==
                                        item.studentAnswer
                                        " style="color: #fd422c">本题正确答案为:{{
        item.answer
    }} {{ '您的回答为' + item.studentAnswer }}

                                        很遗憾您答错了</span>
                                    <!-- <p>本题解析：{{ item.subjectAnalysis
                        ? item.subjectAnalysis : '暂无解析' }}</p> -->
                                    <p v-if="activeNum == (trainQuestions.length - 1)">

                                        <span>本次测试结束，您一共答对{{ totaldata.num }}题
                                            <span v-if="userinfo.groupType != 0">
                                                {{ $t("language.myCourses.achieving") }}{{ totaldata.fraction
                                                }}{{ $t("language.myCourses.pointsintotal") }}
                                            </span>
                                        </span>

                                    </p>
                                </div>
                            </span>
                        </div>

                    </div>
                    <div class="question-box">
                        <!-- <div class="left">{{ activeNum + 1 }}</div> -->
                        <div class="right">

                            <div class="answer" v-for="(item2, idx) in  JSON.parse(item.subjectItem)" :key="idx">
                                <span style="
        color: rgb(44, 123, 253);
        font-size: 20px;
        font-weight: bold;
        margin-right: 10px;
      ">{{ item2.value }}</span>
                                <!-- <span style="
        color: rgb(44, 123, 253);
        font-size: 20px;
        font-weight: bold;
        margin-right: 10px;
      " v-if="idx == 0">A</span>
                <span style="
        color: rgb(44, 123, 253);
        font-size: 20px;
        font-weight: bold;
        margin-right: 10px;
      " v-if="idx == 1">B</span>
                <span style="
        color: rgb(44, 123, 253);
        font-size: 20px;
        font-weight: bold;
        margin-right: 10px;
      " v-if="idx == 2">C</span>
                <span style="
        color: rgb(44, 123, 253);
        font-size: 20px;
        font-weight: bold;
        margin-right: 10px;
      " v-if="idx == 3">D</span> -->
                                <el-radio v-model="item.studentAnswer" :disabled="true" :label="item2.value" :class="{
                                    'el-radio': true,
                                    showError: showError && item == item.studentAnswer,
                                }">
                                    {{ item2.label }}
                                </el-radio>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="training-test">
                <div class="top-bg"></div>
                <div class="title" style="line-height:30px;padding: 20px ;text-align: center;">
                    本题解析
                </div>
                <div class="test-box">
                    <div class="title-line" v-if="item.studentAnswer">
                        <div class="name" v-if="item.subjectAnalysis">
                            本题解析： <p v-html="item.subjectAnalysis"></p>
                        </div>
                        <div v-else  class="name">暂无解析</div>
                    </div>
                    <div class="title-line" v-else>
                        <div  class="name">未答题 暂不显示解析</div>

                    </div>

                </div>
            </div>
        </div>
        <div class="no-data" v-if="trainQuestions2.length == 0">暂无数据</div>
        <div class="bah2">
      <a href="https://beian.miit.gov.cn" target="_blank">ICP备:京ICP备2024071304号-1</a>
    </div>
    </main>
</template>
  
<script>
import { setToken, setCookie, getCookie } from "@/service/lib/localToken";
import { getSystemTime } from "@/service/userapi";
import { findClassHourListByParam } from "@/service/myCourses";
import {
    findSubjectListByFkId,
    addStudentSubjectRecord,
} from "@/service/examinationDetail";
import { DebounceBy } from "@/utlis/DebounceBy";
export default {
    name: "training",
    async asyncData() { },
    data() {
        return {
            dqks: {},
            dqkslist: [],
            dqvalue: '',
            dqksindex: 0,
            userinfo: JSON.parse(getCookie("userdata")),
            btnLoadings: {
                prev: false,
                next: false,
                finish: false,
            },
            activeNum: 0,
            curAnswerEn: "",
            answers: [],
            showError: false,
            JsonsubjectItem: [],
            fkId: "", //课程ID
            subjectTag: "", //答题类型(0-课前,1-课堂,2-问卷 3-课后)
            studentId: "", //学生ID
            showSubmit: false,
            showNext: false,
            answerParams: {
                answerType: this.$route.query.subjectTag,
                studentId: this.$route.query.studentId,
                hourId: this.$route.query.fkId,
                createBy: JSON.parse(getCookie("userdata")).name,
                list: [
                    {
                        answer: "",
                        subjectId: "",
                    },
                ],
            },
            isTrained: false,
            trainQuestions: [],
            trainQuestions2: [],
            params: {},
            // 总分
            totaldata: {
                num: 0,
                fraction: 0,
            },
            start_date: this.$route.query.start_date,
            end_date: this.$route.query.end_date,

        };
    },

    watch: {

        curAnswerEn() {
            this.showError = false;
        },
        activeNum() {
            if (this.trainQuestions[this.activeNum].studentAnswer) {
                this.showNext = true;
                this.showSubmit = false;
                this.curAnswerEn = this.trainQuestions[this.activeNum].studentAnswer;
            } else {
                this.showSubmit = true;
                this.showNext = false;
                this.curAnswerEn = "";
            }
        },
    },
    created() {
        console.log(this.userinfo)
        findClassHourListByParam({
            courseId: '',
            reqType: 1,
            studentId: getCookie("userId")
        }).then(res => {
            // 查询所有课程
            console.log(res)
            this.dqkslist = res.data
            this.params = {
                fkId: this.dqkslist[this.dqksindex].id,
                subjectTag: 0,
                studentId: getCookie("userId"),
            };
            this.dqvalue = this.dqkslist[this.dqksindex].id
            this.dqks = this.dqkslist[this.dqksindex]
            this.init();
        })
        //   this.fkId = this.$route.query.fkId;
        //   this.subjectTag = this.$route.query.subjectTag;
        //   this.studentId = this.$route.query.studentId;
        //   this.params = {
        //     fkId: this.$route.query.fkId,
        //     subjectTag: this.$route.query.subjectTag,
        //     studentId: this.$route.query.studentId,
        //   };
    },

    async mounted() {
        //   console.log("userinfo.groupType", this.userinfo.groupType);


    },
    methods: {
        selttchange(e) {
            console.log(e)
            this.dqkslist.forEach((item, index) => {
                if (e == item.id) {
                    this.dqksindex = index
                    this.dqvalue = this.dqkslist[this.dqksindex].id
                    this.params = {
                        fkId: this.dqkslist[this.dqksindex].id,
                        subjectTag: 0,
                        studentId: getCookie("userId"),
                    };
                    this.dqks = this.dqkslist[this.dqksindex]
                    this.init();
                }
            })
        },
        qhh(type) {
            if (type) {
                this.dqksindex++
            } else {
                this.dqksindex--
            }
            this.dqvalue = this.dqkslist[this.dqksindex].id
            this.params = {
                fkId: this.dqkslist[this.dqksindex].id,
                subjectTag: 0,
                studentId: getCookie("userId"),
            };
            this.dqks = this.dqkslist[this.dqksindex]
            this.init();
        },
        swannotation(text) {

            return text ? text.replace(/[\n\r]/g, '<br>') : ""
        },
        init() {
            this.getTrainCultivate();
            //   this.findFinishCultivateId();
        },
        // 计算总分
        counttotale() {
            console.log(this.trainQuestions);
            let num = 0;
            let fraction = 0;
            this.trainQuestions.forEach((item) => {
                if (item.answer == item.studentAnswer) {
                    num++;
                    fraction += item.score;
                }
            });
            console.log(num, fraction);
            this.totaldata.num = num;
            this.totaldata.fraction = fraction;
        },
        async getTrainCultivate() {
            console.log("查询");
            findSubjectListByFkId(this.params).then((res) => {
                console.log("res", res);
                if (res.code == "000" && res.data.length > 0) {
                    this.trainQuestions = res.data;
                    this.counttotale();
                    this.JsonsubjectItem = JSON.parse(
                        this.trainQuestions[this.activeNum].subjectItem
                    );

                    if (this.trainQuestions[this.activeNum].studentAnswer) {
                        this.showNext = true;
                        this.showSubmit = false;
                        this.curAnswerEn =
                            this.trainQuestions[this.activeNum].studentAnswer;
                    } else {
                        this.showSubmit = true;
                        this.showNext = false;
                    }
                    console.log("   this.subjectItem", this.JsonsubjectItem);
                } else {
                    this.trainQuestions = []
                }
            });
            let params2 = { ...this.params }
            params2.subjectTag = 1
            findSubjectListByFkId(params2).then((res) => {
                console.log("res", res);
                if (res.code == "000" && res.data.length > 0) {
                    this.trainQuestions2 = res.data;
                    // this.counttotale();
                    // this.JsonsubjectItem = JSON.parse(
                    //     this.trainQuestions[this.activeNum].subjectItem
                    // );

                    // if (this.trainQuestions[this.activeNum].studentAnswer) {
                    //     this.showNext = true;
                    //     this.showSubmit = false;
                    //     this.curAnswerEn =
                    //         this.trainQuestions[this.activeNum].studentAnswer;
                    // } else {
                    //     this.showSubmit = true;
                    //     this.showNext = false;
                    // }
                    // console.log("   this.subjectItem", this.JsonsubjectItem);
                } else {
                    this.trainQuestions2 = []
                }
            });
        },


        estimateTime(start_date, end_date, stamp) {

            //开始时间
            let start = new Date(
                start_date.replace(/-/g, "/").replace(/-/g, "/")
            ).getTime();
            //结束时间
            let end = new Date(
                end_date.replace(/-/g, "/").replace(/-/g, "/")
            ).getTime();
            //当前时间
            stamp = new Date(stamp.replace(/-/g, "/").replace(/-/g, "/")).getTime();
            if (stamp > end) {

                let msg;
                if (this.$i18n.locale == "zh-CN") {
                    msg = this.$confirm("对不起，答题时间已结束", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    });
                } else {
                    msg = this.$confirm("Sorry, the quiz is over", {
                        confirmButtonText: "confirm",
                        cancelButtonText: "cancel",
                        type: "warning",
                    });
                }
                msg.then(() => {
                    this.$router.push({
                        path: "/myCourses",
                    });
                });

            } else if (start <= stamp && stamp <= end) {
                /*写你自己的业务代码*/
                if (!this.curAnswerEn) {
                    if (this.$i18n.locale == "zh-CN") {
                        return this.$message({
                            message: "请选择答案！",
                            type: "warning",
                        });
                    } else {
                        return this.$message({
                            message: "PleaseSelectTheAnswer！",
                            type: "warning",
                        });
                    }

                }
                console.log("this.value", this.curAnswerEn);
                this.answerParams.list[0].answer = this.curAnswerEn;
                this.answerParams.list[0].subjectId =
                    this.trainQuestions[this.activeNum].id;
                console.log("this.answerParams", this.answerParams);
                addStudentSubjectRecord(this.answerParams).then((res) => {
                    console.log("res", res);
                    if (res.code == "000" && res.data.length == 0) {
                        this.init();
                    } else {
                        if (this.$i18n.locale == "zh-CN") {
                            return this.$message({
                                message: "请勿重复作答！",
                                type: "warning",
                            });
                        } else {
                            return this.$message({
                                message: "Please do not repeat answering！",
                                type: "warning",
                            });
                        }
                    }
                });
            }
        },


    },
};
</script>
<style lang="scss">
.article {
    position: relative;

    .selsett {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 800px;

        input {
            height: 2.5625rem;
            border: none !important;
            font-size: 1.5625rem;
            font-weight: bold;
            color: #324057;
            line-height: 2.5625rem;
            text-align: center;
        }

    }
}
</style>
<style lang="scss" scoped>
.training {
    // background: #fafbfc;

}

.article {
    position: relative;
    margin: 0 auto;
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
    // padding: 2rem 6.25rem;
    background: #fff;
    border-bottom: 0.052083rem solid #c3cad6;
    height: 2.5625rem;
    box-sizing: border-box;
    overflow: hidden;

    // padding:  0 5px;
    .left {
        color: #349BE3;
        float: left;
        cursor: pointer;
        font-size: 18px;
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }

    .right {
        color: #349BE3;
        float: right;
        cursor: pointer;
        font-size: 18px;
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }

    .title {
        font-size: 1.5625rem;
        font-weight: bold;
        color: #324057;
        line-height: 1.5625rem;
        text-align: center;
    }

    .time {
        margin: 0.9375rem 0 1.458333rem;
        font-size: 14px;
        font-weight: bold;
        color: #c3cad6;
        line-height: 1.5625rem;
        text-align: center;
    }

    .content {
        word-break: break-all;
    }
}

.training-test-box {
    display: flex;
}

.training-test {
    position: relative;
    margin: 28px auto;
    width: 48%;
    background: #fff;
    padding-bottom: 1.458333rem;
    // float: left;
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);

    .top-bg {
        width: 100%;
        height: 8px;
        background: linear-gradient(90deg, #0160A3, #349BE3);
        border-radius: 8px 8px 0 0;
    }

    .title {
        font-size: 1.5625rem;
        font-weight: bold;
        color: #324057;
        line-height: 5.833333rem;
        text-align: center;
    }

    .test-box {
        position: relative;
        margin: 0 24px;
        // border: 1px solid #c3cad6;
        border-radius: 8px;

        .title-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 1.875rem;
            // height: 4.583333rem;
            padding-bottom: 10px;

            // border-bottom: 0.052083rem solid #c3cad6;
            .name {
                font-size: 20px;
                font-weight: bold;
                color: #324057;
            }

            .pos-count {
                text-align: bottom;
                font-size: 1.041667rem;
                font-weight: bold;
                color: #324057;

                span {
                    font-size: 24px;
                }
            }
        }
    }

    .question-box {
        display: flex;
        padding: 36px;
        margin-bottom: 15px;

        background: #F5F7FB;

        .left {
            font-size: 1.25rem;
            font-weight: bold;
            color: #324057;
        }

        .right {
            margin-left: 34px;
            word-wrap: break-word;
            word-break: break-all;
            flex: 1;

            .question {
                padding-bottom: 30px;
                font-size: 20px;
                font-weight: bold;
                color: #324057;
            }

            .answer {
                margin-bottom: 20px;
                border-bottom: 1px dashed #D9D9D9;
                padding-bottom: 20px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .el-radio {
                    word-wrap: break-word;
                    word-break: break-all;
                    white-space: normal;
                    font-size: 1.041667rem;

                    &.showError {
                        ::v-deep {
                            .el-radio__label {
                                color: #d52c0b;
                            }
                        }
                    }
                }
            }
        }
    }

    .btns {
        padding-bottom: 36px;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-button {
            border-radius: 8px;
            margin: 0 12px;
            box-sizing: border-box;
            width: 120px;
            height: 48px;
        }

        .el-button--primary {
            color: #fff;
        }

        .el-button--primary.is-active,
        .el-button--primary:active {
            background: #3a8ee6;
            border-color: #3a8ee6;
            color: #fff;
        }
    }
}

.no-data {
    min-height: 50vh;
    margin: auto;
    box-sizing: border-box;
    padding-top: 10%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

::v-deep {
    .el-radio__label {
        color: #959dab;
        line-height: 1.5;
        font-size: 18px !important;
    }
}
</style>
  