<template>
  <div class="examination">
    <div class="container">
      <div class="application-train">
        <!-- 标题栏 -->
        <ApMaTitleLine    :title="$t('language.headerNav.MyTest')">
          <!-- 搜索栏 -->
<!--          <ApMaTitleLineSearchBox-->
<!--            v-model="searchForm.name"-->
<!--            @toSearch="handleToSearch"-->
<!--            :maxlength="32"-->
<!--            placeholder="请输入课程名称搜索"-->
<!--          />-->
        </ApMaTitleLine>
        <!-- 搜索条件 -->
        <!-- <KnAnTrSearch
          :boxData="boxData"
          @change="selectSearch"
         
        /> -->
            <div class="search-box">
      <div class="line" >
        <!-- <div class="label">{{ item.label }}</div> -->
         <div class="label"> {{ $t("language.myCourses.Course") }}</div>
        <ul>
          <li
         v-for="(item, index) in boxData" :key="index"
            :class="{
              opt: true,
              active: selectId === index,
            }"
            @click="doSelect(index, item.id)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
        <!-- 培训列表 -->
   
        <ul class="train-list" v-loading="loading">

          <template v-if="list.length"
            ><KnAnTrCard
              v-for="card in list"
              :cardData="card"
              :key="card.id"
              @change="selectSearch"
          /></template>
          <div class="no-data" v-else> {{ $t("language.Nodatatemporarily") }}</div>
          <div class="pagination-wrap" v-if="list.length">
            <pagination
              :total.sync="ClassHourParam.total"
              :page.sync="ClassHourParam.page"
              :pageSizes="pageSizes"
              :limit.sync="ClassHourParam.pageSize"
              @pagination="handleCurrentChange"
            />
          </div>
        </ul>
      </div>
    </div>
    <div class="bah2">
      <a href="https://beian.miit.gov.cn" target="_blank">ICP备:京ICP备2024071304号-1</a>
    </div>
  </div>
</template>

<script>
import ApMaTitleLine from "./components/ApMaTitleLine.vue";
import ApMaTitleLineSearchBox from "./components/ApMaTitleLineSearchBox.vue";
import KnAnTrSearch from "./components/KnAnTrSearch.vue";
import KnAnTrCard from "./components/KnAnTrCard.vue";
import pagination from "../../components/Pagination.vue";
import {  getCookie } from "@/service/lib/localToken";
import { findCourseListByStudentId } from "@/service/userapi";
import { findTestPaperByPage } from "@/service/examination";
export default {
  name: "examination",
  components: {
    ApMaTitleLine,
    ApMaTitleLineSearchBox,
    KnAnTrSearch,
    KnAnTrCard,
    pagination,
  },
  data() {
    return {
      
          selectId:0,
      loading: false,
      pageSizes: [6, 12, 24, 36, 48],
      list: [],
      trainList: [],
 

      searchForm: {
        name: "",
        published: 1,
        moduleId: "",
        levelId: "",
      },
      ClassHourParam:{
        courseId:'',
        studentId:getCookie('userId'),
        page:1,
        pageSize:6,
        total:0,
      },
      boxData: [],
    };
  },
  mounted() {
    this.getCourses()
  },
  methods: {
    getCourses(){

      let id=  getCookie('userId')
      console.log('getCookieid',id)
      findCourseListByStudentId({id:id}).then(res =>{
        if(res.code == "000" && res.data){
          this.boxData = res.data
          this.ClassHourParam.courseId = res.data[0].id
          this.getClassHour()
        }
      })
    },
       doSelect(index, id) {
  
        this.selectId = index;
         this.ClassHourParam.courseId = id;
            this.getClassHour()
          console.log(index,id,this.selectId)
      // if (this.selectIds !== id) {
      //   this.$set(this.selectIds, idx, id);
      //   this.$emit("change", this.selectIds);
      // }
    },
    //分页查询考卷列表
    getClassHour(){
      
      
       findTestPaperByPage(this.ClassHourParam).then(res =>{
      
        if(res.code == "000"&& res.data){
           console.log('findClassHourListByParam',res)
           this.list = res.data.list,
           this.ClassHourParam.total = res.data.total
        }
      })
    },
    handleToSearch() {},
    selectSearch() {},
    change() {},
    handleCurrentChange(val) {
      console.log(val)
      this.getClassHour()
    },
 
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/scss/mixin.scss";
@import "/src/assets/scss/config.scss";
.application-train {
  box-sizing: border-box;
  padding-top: 0px !important;
  // background: #fafbfc;
  .search-box {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  padding: 0.520833rem 0;
  border-bottom: 0.052083rem dashed #c3cad6;
  .line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .label {
      margin: 0 1.041667rem 0 0;
      font-size: 1.041667rem;
      font-weight: bold;
      color: #7c8697;
      line-height: 2.083333rem;
      cursor: default;
    }
    ul {
      display: flex;
    }
    .opt {
      margin-right: 1.145833rem;
      font-size: 1.041667rem;
      font-weight: bold;
      color: #324057;
      line-height: 2.083333rem;
      cursor: pointer;
      transition: 0.5s;
      &.active {
        color: #1d7be3;
        cursor: default;
      }
    }
  }
}
  .train-list {
    position: relative;
    margin: 10px auto;
    width: 1440px;
    padding-bottom: 2.604167rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .card {
      margin: 0 14px 14px 0;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
    .pagination-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.no-data {
  min-height: 50vh;
  margin: auto;
  box-sizing: border-box;
  padding-top: 10%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
</style>
