<template>
    <div class="integralExchange">
        <div class="container">
            <div class="classHour-title">
        <!-- <img src="../../../assets/images/back.png" alt=""> -->
        <el-button class="title-bottom" type="info" icon="el-icon-back" circle @click="$router.go(-1)"></el-button>

        <p class="title-text">{{ this.$route.query.courseName }}</p>
      </div>
            <!-- <div style="color: red;
          font-size: 18px;
          font-weight: 600;">


                {{ $t("language.myCourses.Mypoints") }}:{{ gridData[0].totalIntegral }}
            </div> -->
            <!-- <div style="color: green;
          font-size: 18px;
          font-weight: 600;
          text-align: right;">
                {{ $t("language.myCourses.Myaccount") }}:{{ gridData[0].currency }} <span
                    v-if="$i18n.locale != 'zh-CN'">{{ $t("language.myCourses.coin") }}</span>
            </div> -->
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="发起提问" name="exchange">
                    <template v-if="exchangeList.length > 0">
                        <exchange @show="handRankingList" :IntegralEnd="IntegralEnd" :exchangeList="exchangeList">
                        </exchange>

                        <!-- <div class="pagination-wrap">
                            <pagination :total.sync="total" :pageSizes="pageSizes" :limit.sync="parms.pageSize"
                                @pagination="handleCurrentChange" />
                        </div> -->
                    </template>

                    <div class="no-data" v-else>暂无数据</div>
                </el-tab-pane>
                <el-tab-pane label="提问记录" name="history">
                    <template v-if="exchangeHistoryList.length > 0">
                        <exchangeHistory :exchangeHistoryList="exchangeHistoryList"></exchangeHistory>
                        <div class="pagination-wrap">
                            <!-- <pagination
                :total.sync="Historytotal"
  
                :pageSizes="HistorypageSizes"
                :limit.sync="historyParms.pageSize"
                @pagination="handleHistoryCurrentChange"
              /> -->
                        </div>
                    </template>
                    <div class="no-data" v-else>暂无数据</div>
                </el-tab-pane>
                <!-- <el-tab-pane label="明细" name="detail">
                    <integralTable :tableData="tableData"></integralTable>
                    <pagination v-if="tableData.length > 0" :total.sync="Historytotal" :pageSizes="TablepageSizes"
                        :limit.sync="tableParms.pageSize" @pagination="handleTableCurrentChange" />
                </el-tab-pane> -->
            </el-tabs>
        </div>

    </div>
</template>
  
<script>
import exchange from "./components/exchange.vue";
import exchangeHistory from "./components/exchangeHistory.vue";
import integralTable from "./components/integralTable.vue";
import pagination from "../../components/Pagination.vue";
import { findPrizeExchangeListByPage, findExchangeRecordListBy, findIntegralDetailBy } from "@/service/integralExchange";
import { getCookie } from "@/service/lib/localToken";
import { findMidListByPage } from "@/service/rankingList";
export default {
    name: "integralExchange",
    components: {
        exchange,
        exchangeHistory,
        integralTable,
        pagination
    },
    data() {
        return {
            activeName: 'exchange',
            pageSizes: [3, 6, 12],
            HistorypageSizes: [3, 6, 12],
            TablepageSizes: [10, 20, 30],
            exchangeList: [],
            exchangeHistoryList: [],
            tableData: [],
            parms: {
                hourId: this.$route.query.hourId,
                page: 1,
                pageSize: 6,
                exchangeType:'4'

            },
            inparms: {
                hourId: this.$route.query.hourId,
                name: JSON.parse(getCookie("userdata")).name,
                page: 1,
                pageSize: 999
            },
            historyParms: {
                hourId: this.$route.query.hourId,
                studentId: getCookie("userId"),
                page: 1,
                pageSize: 3,
            },
            tableParms: {
                hourId: this.$route.query.hourId,
                studentId: getCookie("userId"),
                page: 1,
                pageSize: 10,
            },
            total: 0,
            Historytotal: 0,
            Tabletotal: 0,
            IntegralEnd: true,
            gridData: [],
        }


    },
    created() {
        if (this.$route.query.IntegralEnd == 0) {
            this.IntegralEnd = true;
        } else {
            this.IntegralEnd = false;
        }
    },
    mounted() {
        this.getFindPrizeExchangeListByPage();
        this.handRankingList()

    },
    methods: {
        //积分排行点击事件
        handRankingList() {
            findMidListByPage(this.inparms).then((res) => {

                this.gridData = res.data.list;
                console.log('res', res)
            });
        },
        getFindPrizeExchangeListByPage() {
            findPrizeExchangeListByPage(this.parms).then(res => {
                console.log(res)
                if (res.data.list) {
                    this.exchangeList = res.data.list
                    this.total = res.data.total

                }

            })
        },
        getFindExchangeRecordListBy() {
            findExchangeRecordListBy(this.historyParms).then(res => {
                if (res.code == "000" && res.data) {
                    this.exchangeHistoryList = res.data
                    this.Historytotal = res.data.length
                }

            })
        },
        getFindIntegralDetailBy() {
            findIntegralDetailBy(this.tableParms).then(res => {
                if (res.code == "000" && res.data) {
                    this.tableData = res.data.list
                    this.Historytotal = res.data.total
                }
                console.log(' this.tableData', this.tableData)
            })
        },
        handleClick() {
            console.log(this.activeName)
            if (this.activeName == "exchange") {
                this.getFindPrizeExchangeListByPage()
            } else if (this.activeName == "history") {
                this.getFindExchangeRecordListBy()
            } else if (this.activeName == "detail") {
                console.log('123')
                this.getFindIntegralDetailBy()
            }
        },
        handleCurrentChange(val) {
            this.parms.page = val.page;
            this.parms.pageSize = val.limit;
            this.getFindPrizeExchangeListByPage()

        },
        handleHistoryCurrentChange(val) {
            this.historyParms.page = val.page;
            this.historyParms.pageSize = val.limit;
            this.getFindExchangeRecordListBy()

        },
        handleTableCurrentChange(val) {
            this.tableParms.page = val.page;
            this.tableParms.pageSize = val.limit;
            this.getFindIntegralDetailBy()
        }
    }


}
</script>
  
<style lang="scss" scoped>
.integralExchange {
    margin-top: 25px;
    .container {



        .classHour-title {
    position: relative;
    text-align: center;
    margin-bottom: 50px;

    .title-bottom {
      position: absolute;
      left: 0;
    }

    .title-text {
      font-size: 32px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #303133;

    }

  }

        .no-data {
            min-height: 50vh;
            margin: auto;
            box-sizing: border-box;
            padding-top: 10%;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
        }
    }

}
</style>
  