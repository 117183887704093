import request from '@/service/lib/request';


// 学员服务
export function addStudent (data) {

  return request({
    url: 'module/tProjectStudent/addStudent',
    method: 'post',
    data
  });
}
export function getStudent (data) {

  return request({
    url: 'module/tProjectStudent/findStudentListByPage',
    method: 'post',
    data
  });
}
export function modifyStudent (data) {

  return request({
    url: 'module/tProjectStudent/modifyStudent',
    method: 'post',
    data
  });
}
export function modifyAgree (data) {

  return request({
    url: `module/tProjectStudent/modifyAgree/${data.id}/1`,
    method: 'put',
    data
  });
}

export function removeStudent (data) {

  return request({
    url: 'module/tProjectStudent/removeStudent',
    method: 'post',
    data
  });
}


// 课程服务

export function getCourse (data) {

  return request({
    url: 'module/tProjectCourse/findCourseListByPage',
    method: 'post',
    data
  });
}
export function addCourse (data) {

  return request({
    url: 'module/tProjectCourse/addCourse',
    method: 'post',
    data
  });
}
export function modifyCourse (data) {

  return request({
    url: 'module/tProjectCourse/modifyCourse',
    method: 'post',
    data
  });
}

export function deletecustomer (data) {
  return request({
    url: 'module/tProjectCourse/' + data.id,
    method: 'delete'
  });

}

// 学员与课程关联关系
export function findMidStudentListByPage (data) {
  return request({
    url: 'module/tMidStudentCourse/findMidStudentListByPage',
    method: 'post',
    data
  });

}
export function addMidStudentCourse (data) {
  return request({
    url: 'module/tMidStudentCourse/addMidStudentCourse',
    method: 'post',
    data
  });

}

export function deletetMidStudentCourse (data) {
  return request({
    url: 'module/tMidStudentCourse/deleteMidStudentCourse',
    method: 'post',
    data
  });

}


//课时服务
export function addClassHour (data) {
  return request({
    url: 'module/tProjectClassHour/addClassHour',
    method: 'post',
    data
  });

}
export function getClassHour (data) {
  return request({
    url: 'module/tProjectClassHour/findClassHourListByPage',
    method: 'post',
    data
  });

}
export function modifyClassHour (data) {
  return request({
    url: 'module/tProjectClassHour/modifyClassHour',
    method: 'post',
    data
  });

}
export function deleteClassHour (data) {
  return request({
    url: 'module/tProjectClassHour/' + data.id,
    method: 'delete'
  });

}

//绑定题目接口
export function addCourseSubjectList (data) {
  return request({
    url: 'module/tProjectCourseSubject/addCourseSubjectList',
    method: 'post',
    data
  });

}
// 获取题目
// export function findSubjectListByFkId (data) {
//   return request({
//     url: 'module/tProjectCourseSubject/findSubjectListByFkId?fkId=' + data.id + '&subjectTag=' + data.type,
//     method: 'get'
//   });

// }
export function findSubjectListByFkId (data) {
  return request({
    url: 'module/tProjectCourseSubject/findSubjectListByPage',
    method: 'post',
    data
  });

}

//考试服务
export function addPaper (data) {
  return request({
    url: 'module/tProjectTestPaper',
    method: 'post',
    data
  });

}
export function deletePaper (data) {
  return request({
    url: 'module/tProjectTestPaper/' + data.id,
    method: 'delete'
  });

}
export function modifyPaper (data) {
  return request({
    url: 'module/tProjectTestPaper/' + data.id + '/modify',
    method: 'POST',
    data
  });

}
export function getPaper (data) {
  return request({
    url: 'module/tProjectTestPaper/findTestPaperByPage',
    method: 'post',
    data
  });

}


//学员积分排名
export function findMidListByPage (data) {
  return request({
    url: 'module/tMidStudentHour/findMidListByPage',
    method: 'post',
    data
  });

}

//手动给积分
export function addStudentFraction (data) {
  return request({
    url: 'module/tMidStudentHour/addStudentFraction?studentId='+data.studentId+'&hourId='+data.hourId+'&number='+data.number,
    method: 'get'
  });

}

// 探索列表
export function findClassHourExplorePage (data) {
  return request({
    url: 'module/classHourExplore/findClassHourExplorePage',
    method: 'post',
    data
  });

}