import request from '@/service/lib/request';

//分页查询考卷列表
export function findTestPaperByPage (data) {

    return request({
        url: 'module/tProjectTestPaper/findTestPaperByPage',
        method: 'post',
        data
    });
}