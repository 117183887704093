<template>
    <div class="groupMember">
        <!-- <p class="title">组员查询</p> -->
        <!-- <el-divider></el-divider> -->
        <div class="content">
            <div class="content-search">
                <!-- <el-input
    placeholder="请输入您要查询的组员名称"
    v-model="input4">
    <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch"></i>
  </el-input> -->
            </div>
            <!-- <div style="text-align: right;">
              
            </div> -->
            <div  style="text-align: right;margin-bottom: 30px;"> 
                【团队任务一】背诵打卡是否完成;
                【团队任务二】课后论坛是否参与（发帖/回复/点赞/评论）;
            </div>
            <div class="content-result-box" v-for="item in groupMemberList">


                <el-avatar class="avatar" :size="40" :src="item.picturePath
                    ">
                </el-avatar>
                <div class="username">{{ item.nickName }}</div>


                <div class="typebox">【团队任务一】：
                    <span v-if="item.afterClassCheckinTimes" style="color: #42ff48;">已完成</span>
                    <span v-else style="color: red;">未完成</span>
                </div>
                <div class="typebox">【团队任务二】：
                    <span v-if="item.likeCnt || item.replyCnt" style="color: #42ff48;">已参与</span>
                    <span v-else style="color: red;">未参与</span>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'groupMember',
    props: {

        groupMemberList: {
            type: Array,
            default: []
        },

    },
    data() {
        return {
            input4: ''
        }
    },
    methods: {
        handleSearch() {
            console.log('handleSearch');

        }
    }
}
</script>

<style lang="scss" scoped>
.groupMember {
    text-align: center;

    .title {
        font-size: 24px;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        color: #030303;
    }

    .content {
        margin: 40px auto;
        width: 90%;

        .content-search {
            margin-bottom: 25px;
        }

        .content-result-box {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .username {
                width: 30%;
                font-size: 16px;
                font-family: Adobe Heiti Std;
                font-weight: bold;
                color: #1A1A1A;
                margin-left: 14px;
                text-align: left;
            }

            .typebox {
                width: 30%;
                font-size: 14px;
            }
        }
    }
}
</style>