import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

export const language = {
  // 头部导航栏
  headerNav: {
    myCourses: "我的课程",
    MyTest: "考试中心",
    MyAchievements: "我的成就",
    MyLeaderboard: "我的排行榜"
  },
  // 课程选择
  myCourses: {
    CourseSelection: "课程选择",
    Course: "课程",
    TheCurrentSession: "当前课时",
    AllSections: "所有课时",
    point: "1积分=1货币",
    PreclassTime: "课前时间",
    InclassTime: "课堂时间",
    AfterclassTime: "课后时间",
    PreclassMaterialsDownload: "资料下载",
    PreclassQuiz: "课前答题",
    InclassQuiz: "课堂答题",
    AfterclassDiscussion: "课后讨论",
    RewardsRedemption: "奖品兑换",
    LeaderboardbySession: "本课时排行",
    BadgesandPointsbySession: "获得徽章与积分",
    NotStarted: "未开始",
    Finished: "已结束",
    AnswerHint: "本题正确答案为",
    AnswerTrue: "恭喜你答对了!",
    AnswerFalse: "很遗憾您答错了!",
    AnswerIntegral: " 获得相应积分",
    Sortedby: "请选择排序方式:",
    Likedmostfirst: "点赞正序",
    Likedleastfirst: "点赞倒序",
    timeearliestfirst: "时间正序",
    timelastfirst: "时间倒序",
    Viewhereply: "查看回复",
    PackUp: "收起",
    Next: "下一题",
    Submit: "提交",
    Reply: '回复',
    RewardsRedemption: "奖品兑换",
    Redemption: "兑换",
    Records: "兑换记录",
    Details: "明细",
    RedeemedRewards: "兑换奖品",
    Coins: "货币兑换",
    Download: "下载文件",
    PersonalizedFeedback: "个性化答疑",
    Pleasetypeyourquestionbelow: "请在下方输入框输入问题内容",
    Submit: "提交问题",
    HomeworkMarking: "作业批改",
    Pleasesubmityourhomeworkbelow: "请在下方上传作业",
    Clicktosubmit: "点击上传",
    Leaveyournoteshere: "请输入上传备注",
    Submit: "提交作业",
    SubmittedQuestion: "提交问题内容",
    Replier: "回复人",
    Replied: "回复内容",
    SubmittedHomework: "学生提交作业",
    Notes: "备注",
    Dates: "日期",
    Descriptions: "详细描述",
    sum: "积分总数",
    Names: "姓名",
    ProfilePhotos: "头像",
    Points: "本课时获得总积分",
    Preclassquiz: "课前答题积分",
    Preclassdownload: "课前下载积分",
    Preclasstaskcompletion: "课前完成任务积分",
    Inclassquiz: "课堂答题积分",
    Inclassvoluntaryparticipation: "课堂主动参与得分",
    Receivinglikesinafterclassdiscussions: "课后讨论点赞分",
    coin: "货币",
    zerointegral: "0积分",
    Thequiz: "本次测试结束，您一共答对",
    questions: '题',

    achieving: "，获得",

    pointsintotal: "积分!",

    publish: "发表",
    Mypoints: "该课时我已获得的积分",
    Myaccount: "该课时我的现有货币余额",

  },
  //考试中心
  examination: {
    UnfinishedTest: "未答题",
    Graded: "已批改",
    NotGraded: "未批改",
    ViewHistory: "查看历史",
    Achievedscores: "学生得分",
    Scores: "本试卷满分为",
    TestTime: "考试时间",
    Start: "开始考试",
    Rater: "批改老师",
    examinationScores: "分值",

    Correct: "回答正确",
    Wrong: "回答错误",

  },
  //我的成就
  MyAchievements: {
    Badges: "该课程累计获得徽章",
    Points: "该课程累计获得积分",
    Certificate: "已获得证书",
    Title: "证书名称",
    IssueDate: "发放日期",
  },
  //我的排行榜
  MyLeaderboard: {
    LeaderboardSum: "总积分排行榜",
    Rank: "排名",
    Name: "昵称",
    TotalPoints: "总积分"
  },
  //用户设置
  UserSettings: {
    UserSettings: "用户设置",
    BasicInformation: "基本资料",
    Account: "账号",
    Name: "姓名",
    Gender: "性别",
    Age: "年龄",
    Major: "专业",
    Grade: "年级",
    Mail: "邮箱",
    PhoneNumber: "手机号码",
    UserName: "昵称（仅可改一次）",
    ProfilePhoto: "头像上传",
    Upload: "提交",
    ChangePassword: "修改密码",
    OldPassword: "旧密码",
    NewPassword: "新密码",
    ConfirmPassword: "确认密码",
    Submit: "提交",
    UploadPicture: "上传图片",
    Reset: "重置",
    man: "男",
    woman: "女",
    submission:'提交试卷时请勿操作'
  },
  // 登录
  login: {
    logout: '退出登录', //logout
    Lanugages: '中英文切换', //Lanugages
    chinese: '中文',
    english: '英文',
    LearningSystem: "学习平台",
    UserName: "用户名",
    Password: "密码",
    Login: "登录"
  },
  Nodatatemporarily: "暂无数据"
}
