
<template>
  <section id="login">
    <div class="login-container" :class="isel ? 'PingFang' : 'Arial'">
      <div class="container">
        <!-- <div style="    position: absolute;
    right: 85px;
    top: 15px;">
        <el-dropdown class="dropdown" @command="handleLang">
          <img style="
                width: 30px;
              " src="../assets/images/lang.png" alt="" />

          <el-dropdown-menu slot="dropdown" style="margin-top: 30px">
            <el-dropdown-item command="chinese">{{
                $t("language.login.chinese")
              }}</el-dropdown-item>
            <el-dropdown-item command="english">{{
                $t("language.login.english")
              }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div> -->
        <!-- <div class="textlogin">GOPLAY游戏化在线学习系统</div> -->
        <div class="lefttext">
          <div class="logoinbox"><img src="../assets/logox.png" alt="">
            <span>GoPlay</span>
          </div>
          <div class="text" v-if="isel">
            <p style="margin-top: 50px;">欢迎来到GoPlay（游戏化在线个性化学习与成长系统）！</p>
            <p>GoPlay是一个游戏化在线学习系统，致力于为您提供量身定制的学习体验。</p>
            <p>GoPlay融合了游戏中引人入胜的元素，如积分、排行榜、挑战升级等。系统将根据您的“玩家类型”（即游戏化学习偏好），为您匹配相应的游戏化学习活动。</p>
            <p>趣玩无畏，让我们一起“趣玩”Go Play 吧！</p>

          </div>
          <div class="text" v-else style="line-height: 33px;">
            <p style="margin-top: 42px;">Welcome to GoPlay (Gamified Personalized Online Learning and Yielding System)!
            </p>
            <p>GoPlay is a gamified online learning system dedicated to providing you with a personalized learning
              experience.</p>
            <p>GoPlay integrates engaging elements from games, such as points, leaderboards, challenge, and level up. The
              system is equipped with gamified learning activities that match your "player type" (i.e., your preferences
              for
              gamified learning).</p>
            <p>Fun and Fearless! Let's Go Play together!</p>
          </div>
          <div class="switchbox" @click="isel = !isel">
            {{ isel ? 'English' : '中文' }} <span class="el-icon-sort"></span>
          </div>
        </div>
        <transition name="fade-down">

          <el-form class="login-box" :model="loginForm" :rules="rules" ref="form">
            <!-- <div class="left">
            <img src="../assets/images/login_img.png" />
          </div>
            <div class="mid"></div> -->
            <div class="right">
              <div class="riicon">
                <img src="../assets/logintitle.png" alt="">
              </div>
              <div class="login-title" v-if="isel">学习平台登录</div>
              <div class="login-title" v-else>Log in</div>
              <el-form-item prop="account">
                <el-input size="medium" v-model="loginForm.account" :placeholder="!isel ? 'UserName' : '用户名'">
                  <i slot="prefix" class="el-input__icon el-icon-user"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input size="medium" type="password" auto-complete="new-password" v-model="loginForm.password"
                  :placeholder="!isel ? 'Password' : '密码'">
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
              <div class="" style="text-align: center;"> <el-button class="btn" type="primary" :loading="loading"
                  @click="handleLogin">
                  {{ !isel ? 'Log in' : '登录' }}
                </el-button></div>

              <!-- <el-checkbox v-model="keepPassword">记住密码</el-checkbox> -->
            </div>
          </el-form>
        </transition>
      </div>
      <div class="bah">
        <a href="https://beian.miit.gov.cn" target="_blank">ICP备:京ICP备2024071304号-1</a>
      </div>
    </div>
  </section>
</template>

<script>
import { setToken, setCookie } from "@/service/lib/localToken";
import { login } from "@/service/userapi";
export default {
  name: "login",
  data() {
    return {
      isel: true,
      redirectUrl: "/",
      loading: false,
      loginForm: {
        account: '',
        password: '',
        userId: ''
      },
      rules: {
        account: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  components: {},
  created() {

  },
  methods: {
    //切换语言
    handleLang(command) {
      if (command == "chinese") {
        this.$i18n.locale = "zh-CN";
        window.sessionStorage.setItem("lang", this.$i18n.locale);
      }
      if (command == "english") {
        this.$i18n.locale = "en-US";
        window.sessionStorage.setItem("lang", this.$i18n.locale);
      }
    },
    //点击登录事件
    handleLogin() {


      console.log('loginForm', this.loginForm)
      let { account, password } = this.loginForm;
      let data = {
        account, password
      }

      login(data).then(res => {
        console.log(res.data.student)
        if (!res.data.student.groupType) {
          this.$message.error('此账号暂未分配角色，无法登陆');
          return
        }
        const { access_token } = res.data;

        setToken('bearer ' + access_token);
        setCookie('userdata', JSON.stringify(res.data.student));
        setCookie('userId', res.data.student.id);
        this.$nextTick(() => {
          this.$router.push({
            path: 'myCourses',
            params: {
              from: 'login'
            }
          });
        });

      })
      // this.axios.get('http://124.222.5.115:8008/study-manager/module/tProjectStudent/login2',{
      //   username,
      //   password
      // }).then((res)=>{

      //   this.$cookie.set('userId',res.id,{expires:'1M'});
      //   // this.$store.dispatch('saveUserName',res.username);
      //   this.saveUserName(res.username);

      //   // this.saveUserName(res.username);
      //   // this.$router.push({
      //   //   name:'index',
      //   //   params:{
      //   //     from:'login'
      //   //   }
      //   // });
      // })
    },


  },
  mounted() {

  },
  beforeDestroy() {

  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'PingFang-SC-Regular';
  src: url('../assets/PingFang SC Semibold.ttf');
}

@font-face {
  font-family: 'Arial';
  src: url('../assets/Arial.ttf');
}

@font-face {
  font-family: 'Arial-Black';
  src: url('../assets/Arial-Black.ttf');
}

#login {
  .PingFang {
    font-family: PingFang-SC-Regular;
  }

  .Arial {
    font-family: Arial;
  }

  width: 100%;
  height: 100vh;
  background-color: #f1f1f1;
  margin: 0;

  .lefttext {
    position: absolute;
    width: 480px;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);

    .switchbox {
      width: 90px;
      height: 30px;
      background: #429DFE;
      border-radius: 5px;
      line-height: 30px;
      font-size: 14px;
      text-align: center;
      color: #fff;
      margin-top: 20px;
      position: absolute;
      bottom: -50px;
      left: 0;
      cursor: pointer;

      span {
        transform: rotate(90deg);
      }
    }

    .logoinbox {
      // margin-bottom: 35px;

      position: absolute;
      top: -40px;

      img {
        height: 51px;
      }

      span {
        font-family: Arial-Black;
        font-size: 46px;
        font-weight: 800;
        line-height: 51px;
        margin-left: 15px;
        position: relative;
        top: -8px;
        color: #429DFE;
      }
    }

    .text {
      font-size: 16px;
      // font-size: 1.05448vw;
      line-height: 45px;
      color: #585858;
      // font-family: PingFang-SC-Regular;

      p {
        margin-top: 20px;

      }
    }
  }

  .textlogin {
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 40px;
    width: 100%;
    text-align: center;
  }

  .login-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: url('../assets/loginbg.png');
    background-size: 100% 100%;
    // animation: animate-cloud 20s linear infinite;
  }

  .login-box {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    width: 400px;
    // height: 400px;
    // overflow: hidden;
    box-shadow: 0 5px 11px 0 rgba(2, 15, 58, 0.79);
    padding: 40px 40px;
    background: #FFFFFF;
    display: flex;

    .riicon {
      position: absolute;
      top: -153px;
      right: 0;

      img {
        width: 175px;
      }
    }

    .icon-safetycertificate {
      font-size: 18px;
      display: inline-block;
      height: 100%;
      width: 25px;
      text-align: center;
      line-height: 45px;
    }

    .el-input__icon {
      font-size: 18px;
    }

    .left {
      img {
        width: 280px;
        cursor: pointer;
      }
    }

    .mid {
      margin: 0 50px;
      width: 2px;
      height: 380px;
      background: linear-gradient(0deg,
          rgba(18, 71, 163, 0) 0%,
          rgba(0, 216, 215, 0.99) 49%,
          rgba(18, 70, 162, 0) 100%);
    }

    .right {
      flex: 1;
      position: relative;


      .btn {}

      .login-title {
        font-size: 28px;
        font-weight: 400;
        color: #424242;
        text-align: left;
        padding-bottom: 20px;
        // font-family: PingFang-SC-Regular;
        // font-weight: bold;
      }

      .el-checkbox {
        ::v-deep .el-checkbox__label {
          color: #fff;
        }
      }

      .el-form-item {
        margin-top: 15px;

        ::v-deep .el-input {
          input {
            background: #F3F3F3;
            border-radius: 10px;
            border: 2px solid #E5E5E5;
            color: #333333;
            border-color: #E5E5E5;
            height: 60px;
            line-height: 60px;
            font-size: 16px;
            padding-left: 35px;

            &:focus {
              border-color: #1890ff;
            }
          }
        }
      }

      .el-button {
        margin-top: 20px;
        width: 200px;
        height: 52px;
        background: #429DFE;
        border-radius: 10px;
      }

      .code-box {
        ::v-deep .el-form-item__content {
          display: flex;
        }

        .code {
          width: 125px;
          height: 45px;
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
  }
}

.fade-down-leave-active,
.fade-down-enter-active {
  transition: all 0.5s;
}

.fade-down-enter,
.fade-down-leave-to {
  opacity: 0;
  transform: translate(-70%, -50%);
}</style>
