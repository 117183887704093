<template>
  <div>课前</div>
</template>

<script>
export default {
name:"beforeClass"
}
</script>

<style>

</style>