import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import Home from '../views/home.vue'
import BeforeClass from '../views/beforeClass/beforeClass.vue'
import Examination from '../views/examination/examination.vue'
import PointsDetails from '../views/PointsDetails/index.vue'
import ExaminationDetail from '../views/examination/components/examinationDetail.vue' //考试中心
import MyCoursesExaminationDetail from '../views/myCourses/components/examinationDetail.vue' // 我的课程考试中心
import MyCoursesExaminationDetail2 from '../views/myCourses/components/examinationDetail2.vue' // 我的课程考试中心
import MyCoursesExaminationDetail3 from '../views/myCourses/components/examinationDetail3.vue' // 我的课程 答题历史
import MyCourses from '../views/myCourses/myCourses.vue' //我的课程
import MyBadge from '../views/myBadge/myBadge.vue' //我的徽章
import userInfo from '../views/userInfo/userInfo.vue' //用户设置
import IntegralExchange from '../views/integralExchange/integralExchange.vue' //积分兑换
import IntegralExchange2 from '../views/integralExchange/integralExchange2.vue' //自主者兑换
import ClassDiscussion from '../views/classDiscussion/classDiscussion.vue' //课堂讨论
import RankingList from '../views/rankingList/rankingList.vue' //排行榜
import myClassHour from '../views/myCourses/components/myClassHour.vue' //我的课时
import RoleIntroduction from '../views/RoleIntroduction/RoleIntroduction.vue' //角色介绍页
import ExploreList from '../views/myCourses/components/ExploreList.vue' //探索列表页

// import AfterClass from '../views/beforeClass/afterClass.vue'
// import Classroom from '../views/beforeClass/classroom.vue'
// import Participation from '../views/beforeClass/participation.vue'
// import Reward from '../views/beforeClass/reward.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    // name: 'login',
    name: '登录页',
    component: Login,
  },
  {
    path: '/',
    // name: 'home',
     name: '主页',
    component: Home,
    redirect:'/myCourses',
  
    children: [
      {
        path: 'beforeClass',
        name: '课前',
        component: BeforeClass,
      },
      {
        path: 'examination',
        name: '考试',
        component: Examination,
      },
      {
        path: 'PointsDetails',
        name: '课时积分明细',
        component: PointsDetails,
      },
      {
        path: '/examination/examinationDetail',
        name: '考试详情',
        component: ExaminationDetail,
      },
      {
        path: '/myCourses/myCoursesExaminationDetail',
        name: '课前，课堂答题',
        component: MyCoursesExaminationDetail,
      },
      {
        path: '/myCourses/myCoursesExaminationDetail2',
        name: '课后答题',
        component: MyCoursesExaminationDetail2,
      },
      {
        path: '/myCourses/myCoursesExaminationDetail3',
        name: '答题历史',
        component: MyCoursesExaminationDetail3,
      },
      {
        path: 'myCourses',
        name: '我的课程',
        component: MyCourses,
        
      },
      {
        path: 'myBadge',
        name: '我的徽章',
        component: MyBadge,
      },
      {
        path: 'userInfo',
        name: '用户信息',
        component: userInfo,
      },
      {
        path: 'integralExchange',
        name: '积分兑换',
        component: IntegralExchange,
      },
      {
        path: 'integralExchange2',
        name: '自主者提出探讨',
        component: IntegralExchange2,
      },
      {
        path: 'classDiscussion',
        name: '课堂讨论',
        component: ClassDiscussion,
      },
      {
        path: 'rankingList',
        name: '排名',
        component: RankingList,
      },
      {
        path: 'myClassHour',
        name: '我的课时',
        component: myClassHour,
      },
      {
        path: 'RoleIntroduction',
        name: '角色介绍',
        component: RoleIntroduction,
        
      },
      {
        path: 'ExploreList',
        name: '探索列表',
        component: ExploreList,
        
      },
      
    ]
  },

 
]

const router = new VueRouter({
  routes
})

export default router
