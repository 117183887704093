import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookie from 'vue-cookie'
import store from './store'
import ElementUI from 'element-ui';
import axios from 'axios'
import dayjs from 'dayjs';
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n';
import Dialog from '@/components/Dialog'
import 'element-ui/lib/theme-chalk/index.css';
import '/src/assets/scss/base.scss';
import "/src/assets/scss/mixin.scss";
import "/src/assets/scss/config.scss";
import locale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import { getCookie } from "@/service/lib/localToken";
import { addOperationLog } from "@/service/record";
import '@/router/permission';
Vue.config.productionTip = false
Vue.prototype.axios = axios;
Vue.prototype.dayjs = dayjs;
Vue.use(VueAxios, axios)
Vue.use(VueCookie)
Vue.use(ElementUI);
Vue.use(VueI18n);
Vue.component('Dialog',Dialog)
const i18n = new VueI18n({
  locale: window.sessionStorage.getItem('lang') || 'zh-CN', // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': Object.assign(require('./common/lang/zh'), zhLocale),
    'en-US': Object.assign(require('./common/lang/en'), enLocale),


  }
});
locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换
// 通用方法手动记录日志
Vue.prototype.$addLog = function (actionType,courseId,hourId) {
  let userinfo= JSON.parse(getCookie("userdata"))
  let postData= {
    actionType,
    courseId,
    hourId,
    userId:userinfo.id,
    username:userinfo.account
  }
  addOperationLog(postData).then(res => {
    console.log(res)
  })
  console.log(postData)
};
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
