<template>
  <main class="training">
    <div class="article">
      <div class="title">{{ name }}</div>
      <div class="time" v-if="modifyBy">
        {{ $t("language.examination.Rater") }}：{{ modifyBy }}
      </div>
      <!-- <div class="content" v-html="trainQuestions.content">333</div> -->
    </div>
    <div class="training-test" v-for="(item, index) in trainQuestions" :key="index">
      <div class="top-bg"></div>
      <div class="title" v-html="swannotation(item.subjectDemand)">
        <!--        <span v-if="item.subjectType == '0'">单选题</span>-->
        <!--        <span v-if="item.subjectType == '1'">简答题</span>-->
        <!--        {{ item.sort }}-->
        <!-- {{ item.subjectDemand }} -->
      </div>
      <div class="test-box">
        <div class="title-line">
          <div class="name">
            <div v-html="swannotation(item.name)">

            </div>
            <span class="score">({{ $t("language.examination.examinationScores") }}：{{ item.score }})</span>
          </div>
          <div class="pos-count" v-if="state == 2">
            {{ $t("language.examination.Achievedscores") }}：{{ item.subjecObj.score }}
          </div>
        </div>
        <template v-if="state == 2">
          <div style="color:red" v-if="item.subjectType == '1'" class="title-line" v-html="swannotation(item.subjecObj.annotation)">
            <!-- <textarea  type="textarea" :rows="4" placeholder="请输入内容"
             v-model="item.subjecObj.annotation" disabled  style ="background-color: transparent;color: red;width: 100%;"
                      > </textarea> -->

       

          </div>
        </template>

        <div class="question-box">
          <div class="right" v-if="item.subjectType == 0">
            <div class="answer" v-for="(msg, idx) in JSON.parse(item.subjectItem)" :key="idx">
              <el-radio @change="sevePaper" v-model="answerParams.list[index].answer" :label="msg.value" :class="{
                'el-radio': true,
                showError: showError && msg == curAnswerEn,
              }" :disabled="showSubmit">
                <span style="font-weight: bold">{{ msg.value }}.</span> {{ msg.label }}
              </el-radio>
            </div>
            <template v-if="state == 2">

              <span v-if="item.subjecObj.answer == item.subjecObj.correctAnswer" class="TFprompt">{{
                $t("language.examination.Correct") }}</span>
              <span v-else class="TFprompt2">{{ $t("language.examination.Wrong") }}</span>
            </template>

          </div>
          <div class="right" v-if="item.subjectType == 1">

            <el-input style="margin-bottom:20px" type="textarea" :rows="2" placeholder="请输入内容"
              v-model="answerParams.list[index].answer" :disabled="showSubmit">
            </el-input>
            <el-upload v-if="!showSubmit" class="avatar-uploader" :headers="headers"
              action="https://admin.goplayedu.com/study-manager/module/file/fileUpload" :show-file-list="false"
              :on-success="(res, file) => handleAvatarSuccess(answerParams.list[index], res, file)"
              :before-upload="beforeAvatarUpload">
              <img v-if="answerParams.list[index].imageUrl" :src="answerParams.list[index].imageUrl" class="avatar">
              <!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
              <el-button v-else type="primary"> 上传图片</el-button>

            </el-upload>
            <div v-else>
              <img :src="answerParams.list[index].imageUrl" alt="" style="width: 100%">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <el-button v-if="!showSubmit" type="primary" size="medium" @click="goSubmit()">
        {{ $t("language.UserSettings.Submit") }}</el-button>
    </div>
    <div class="Mask" v-if="ismake">
      <i class="el-icon-loading"></i>
      <div>
        {{ $t("language.UserSettings.submission") }}
      </div>

    </div>
  </main>
</template>

<script>
import {
  findSubjectListByFkId,
  queryStudentTestByParam,
  addStudentTestRecord,
} from "@/service/examinationDetail";
import { DebounceBy } from "@/utlis/DebounceBy";
import { setToken, setCookie, getCookie } from "@/service/lib/localToken";
import { getSystemTime } from "@/service/userapi";
import Cookies from 'js-cookie';
import { siteTokenKey } from "@/settings";
import {
  getToken
} from "@/service/lib/localToken";

export default {
  name: "training",
  async asyncData() { },
  data() {
    return {
      ismake: false,
      btnLoadings: {
        prev: false,
        next: false,
        finish: false,
      },
      modifyBy: '',
      name: '',
      showSubmit: false,
      textarea: "",
      activeNum: 0,
      curAnswerEn: "",
      answers: [],
      showError: false,
      fkId: "", //试卷ID
      subjectTag: "", //答题类型(0-课前,1-课堂,2-问卷)
      studentId: "", //学生ID
      trainQuestions: [],
      params: {},
      answerParams: {
        testId: this.$route.query.fkId,
        studentId: this.$route.query.studentId,
        createBy: JSON.parse(getCookie("userdata")).name,
        list: [],
      },
      start_date: this.$route.query.start_date,
      end_date: this.$route.query.end_date,
      imageUrl: '',
      headers: {
        'Authorization': getToken()
      }
    };
  },

  watch: {
    curAnswerEn() {
      this.showError = false;
    },
  },
  created() {
    this.fkId = this.$route.query.fkId;
    this.subjectTag = this.$route.query.subjectTag;
    this.studentId = this.$route.query.studentId;
    this.state = this.$route.query.state;
    this.name = this.$route.query.name;
    this.params = {
      fkId: this.$route.query.fkId,
      subjectTag: this.$route.query.subjectTag,
      studentId: this.$route.query.studentId,
    };
    this.paramsHistory = {
      testId: this.$route.query.fkId,
      studentId: this.$route.query.studentId,
    };
    this.keyDown()
  },
  async mounted() {
    this.init();
  },
  methods: {
    handleAvatarSuccess(item, res, file) {
      item.imageUrl = res.location;
      this.$forceUpdate()
      this.sevePaper()
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt2M;
    },
    showPaper() {
      //获取浏览器缓存答案
      let paperdata = JSON.parse(Cookies.get('paperdata'))[this.$route.query.fkId]

      //判断答案 答题人是否和相同
      if (this.answerParams.studentId == paperdata.studentId) {
        //判断题目数量是否相同
        if (this.answerParams.list.length == paperdata.list.length)
          this.answerParams.list = paperdata.list
      }
    },
    //保存题目
    sevePaper() {
      let studentId = this.answerParams.studentId
      let testId = this.answerParams.testId
      let paperdata = {

      }
      //保存数据 考卷id为建值
      paperdata[testId] = this.answerParams
      Cookies.set('paperdata', JSON.stringify(paperdata));

    },
    keyDown() {
      document.onkeydown = (e) => {

        if (!this.showSubmit) {
          this.sevePaper()
        }

      }
    },
    init() {
      this.getTrainCultivate();
    },
    getStr(string, str) {
      var str_before = string.split(str)[0];
      var str_after = string.split(str)[1];

      return {
        str_before, str_after
      }
    },
    swannotation(text) {

    
      return text?text.replace(/[\n\r]/g, '<br>'):""
    },
    //    查询题目接口
    async getTrainCultivate() {

      findSubjectListByFkId(this.params).then((res) => {

        if (res.code == "000" && res.data) {
          // this.trainQuestions = res.data;
          let dxlist = [];
          let tklist = [];
          res.data.forEach((item) => {
            if (item.subjectType == 0) {
              dxlist.push(item);
            } else {
              tklist.push(item);
            }
          });
          let trainQuestions = dxlist.concat(tklist);
          trainQuestions.forEach((item, index) => {

            this.answerParams.list.push({ answer: "", subjectId: item.id });
          });
          if (this.$route.query.state !== "0") {

            queryStudentTestByParam(this.paramsHistory).then((res2) => {
              if (res2.code == "000" && res2.data.details) {
                this.modifyBy = res2.data.modifyBy
                res2.data.details.forEach((item2) => {
                  trainQuestions.forEach((item) => {


                    if (item.id == item2.subjectId) {
                      item.subjecObj = item2;
                    }
                  });
                  this.answerParams.list.forEach((item3) => {
                    if (item2.subjectId == item3.subjectId) {
                      //已答题目回显
                      //截取是否有上传图片
                      let answer = this.getStr(item2.answer, '|||')
                      item3.answer = answer.str_before;
                      item3.imageUrl = answer.str_after;
                    }
                  });
                });
                this.trainQuestions = trainQuestions;
                this.$nextTick(() => {
                  this.showSubmit = true;
                });
              }
            });
          } else {
            this.trainQuestions = trainQuestions;
            this.showPaper()
          }
        }
      });
    },
    estimateTime(start_date, end_date, stamp) {
      //开始时间
      let start = new Date(
        start_date.replace(/-/g, "/").replace(/-/g, "/")
      ).getTime();
      //结束时间
      let end = new Date(
        end_date.replace(/-/g, "/").replace(/-/g, "/")
      ).getTime();
      //当前时间
      stamp = new Date(stamp.replace(/-/g, "/").replace(/-/g, "/")).getTime();
      if (stamp > end) {
        let msg
        if (this.$i18n.locale == "zh-CN") {
          msg = this.$confirm('对不起，考试时间已结束', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } else {
          msg = this.$confirm('Sorry, the test is over', {
            confirmButtonText: 'confirm',
            cancelButtonText: 'cancel',
            type: 'warning'
          })
        }
        msg.then(() => {
          this.$router.push({
            path: "/examination",
          });
        })
      }
      else if (start <= stamp && stamp <= end) {
        //处理已上传图片题目  用|||拼接
        this.answerParams.list.forEach(item => {
          if (item.imageUrl) {
            item.answer = item.answer + '|||' + item.imageUrl
          }
        })
        this.ismake = true
        addStudentTestRecord(this.answerParams).then((res) => {
          this.ismake = false
          if (res.code == "000") {
            let title
            if (this.$i18n.locale == "zh-CN") {
              title = this.$confirm('提交成功，请等待批改', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
            } else {
              title = this.$confirm('Sorry, the test is over', {
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                type: 'warning'
              })
            }
            title.then(() => {
              this.$router.push({
                path: "/examination",
              });
            }).catch(() => {
              this.$router.push({
                path: "/examination",
              });
            });
          }
        });
      }
    },
    goSubmit: DebounceBy(function (value) {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(
            this.start_date,
            this.end_date,
            res.data
          );
        }
      });

    }, 1000),
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style lang="scss" scoped>
.Mask {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  color: white;
  padding-top: 20%;
}

.training {
  // background: #fafbfc;
}

.article {
  position: relative;
  margin: 0 auto;
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;
  padding: 2rem 6.25rem;
  background: #fff;
  border-bottom: 0.052083rem solid #c3cad6;
  margin-bottom: 28px;

  .title {
    font-size: 32px;
    font-weight: bold;

    color: #303133;
    line-height: 1.5625rem;
    text-align: center;
  }

  .time {
    margin: 0.9375rem 0 1.458333rem;
    font-size: 14px;
    font-weight: bold;
    color: #c3cad6;
    line-height: 1.5625rem;
    text-align: center;
  }

  .content {
    word-break: break-all;
  }
}

.training-test {
  position: relative;
  margin: 0 auto;
  width: 100rem;
  background: #fff;
  padding-bottom: 1.458333rem;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;

  .top-bg {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, #0160A3, #349BE3);
    border-radius: 8px 8px 0 0;
  }

  .title {
    min-height: 5.833333rem;
    font-size: 1.5625rem;
    font-weight: bold;
    color: #324057;
    line-height: 3.833333rem;
    padding: 0 30px;
    text-align: left;
  }

  .test-box {
    position: relative;
    margin: 0 24px;
    // border: 1px solid #c3cad6;
    border-radius: 8px;

    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.875rem;

      /*height: 4.583333rem;*/
      // border-bottom: 0.052083rem solid #c3cad6;
      .name {
        width: 90%;

        .score {
          font-size: 16px;
          color: #999999;
        }
      }

      .pos-count {
        /*text-align: bottom;*/
        /*text-align: b;*/
        font-size: 1.041667rem;
        font-weight: bold;
        color: red;

        span {
          font-size: 24px;
        }
      }
    }
  }

  .question-box {
    display: flex;
    padding: 36px;
    background: #F5F7FB;

    .left {
      font-size: 1.25rem;
      font-weight: bold;
      color: #324057;
    }

    .right {
      margin-left: 34px;
      word-wrap: break-word;
      word-break: break-all;
      flex: 1;

      .question {
        padding-bottom: 30px;
        font-size: 20px;
        font-weight: bold;
        color: #324057;
      }

      .TFprompt {
        color: rgb(23, 235, 23);
        float: right;
      }

      .TFprompt2 {
        color: red;
        float: right;
      }

      .answer {
        margin-bottom: 20px;
        border-bottom: 1px dashed #D9D9D9;
        padding-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .el-radio {
          word-wrap: break-word;
          word-break: break-all;
          white-space: normal;
          font-size: 1.041667rem;

          &.showError {
            ::v-deep {
              .el-radio__label {
                color: #d52c0b;
              }
            }
          }
        }
      }
    }
  }

  .btns {
    padding-bottom: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-button {
      border-radius: 8px;
      margin: 0 12px;
      box-sizing: border-box;
      width: 120px;
      height: 48px;
    }

    .el-button--primary {
      color: #fff;
    }

    .el-button--primary.is-active,
    .el-button--primary:active {
      background: #3a8ee6;
      border-color: #3a8ee6;
      color: #fff;
    }
  }
}

.btn-box {
  display: flex;
  justify-content: space-around;
  margin: 5px;
  padding-bottom: 30px;
}

::v-deep {
  .el-radio__label {
    color: #959dab;
    line-height: 1.5;
    font-size: 18px !important;
  }
}</style>
