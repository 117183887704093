<template>
  <main class="training">
    <div class="role" v-if="roleData">
      <div style="display: flex;
          align-items: center;">


        <div>
          <img :src="roleImg" alt="">

        </div>
        <div class="role-text">
          <p class="role-title" v-if="userinfo.groupType != 1">{{ roleData[roleName].roleName }}规则</p>
          <p class="role-title" v-else>系统使用规则</p>
          <div class="role-introduce" v-if="userinfo.groupType != 1" v-html="roleData[roleName].rule"></div>
          <div class="role-introduce" v-else v-html="roleData[roleName].rule2"></div>
        </div>
      </div>
      <!-- <div>
        <el-button type="primary" v-if="roleName == 'ZZZ' && userinfo.groupType != 1" icon="el-icon-setting"
          @click="handleSetting">配置游戏元素</el-button>
      </div> -->
    </div>
    <div class="article">

      <!-- <div class="title">{{name}}1</div>
        <div class="time" v-if="modifyBy">
           批改老师：{{modifyBy}}
        </div> -->

    </div>
    <div style="font-size: 30px;
            font-family: Adobe Heiti Std;
            text-align: center;
                margin-bottom: 30px;
                font-weight: normal;
            color: #000000;">意见反馈</div>
    <div class="training-test" v-for="(item, index) in trainQuestions" :key="index">
      <div class="top-bg"></div>
      <div class="title" style="min-height:auto;">

        {{ item.subjectDemand }}
      </div>
      <div class="test-box">
        <div class="title-line" style="font-size: 28px;">
          <div class="name">
            <div v-html="item.name">

            </div>

          </div>

        </div>


        <div class="question-box">
          <div class="right" v-if="item.subjectType == 0">
            <div class="answer" v-for="(msg, idx) in JSON.parse(item.subjectItem)" :key="idx">
              <el-radio @change="sevePaper" v-model="answerParams.list[index].answer" :label="msg.value" :class="{
                'el-radio': true,
                showError: showError && msg == curAnswerEn,
              }" :disabled="showSubmit">
                <span style="font-weight: bold">{{ msg.value }}.</span> {{ msg.label }}
              </el-radio>
            </div>


          </div>
          <div class="right" v-if="item.subjectType == 1">

            <el-input style="margin-bottom:20px" type="textarea" :rows="2" placeholder="请输入内容"
              v-model="answerParams.list[index].answer" :disabled="showSubmit">
            </el-input>
            <el-upload v-if="!showSubmit" class="avatar-uploader" :headers="headers"
              :action="VUE_APP_APIHOST + '/module/file/fileUpload'" :show-file-list="false"
              :on-success="(res, file) => handleAvatarSuccess(answerParams.list[index], res, file)"
              :before-upload="beforeAvatarUpload">
              <img v-if="answerParams.list[index].imageUrl" :src="answerParams.list[index].imageUrl" class="avatar">
              <!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
              <el-button v-else type="primary"> 上传图片</el-button>

            </el-upload>
            <div v-else>
              <img :src="answerParams.list[index].imageUrl" alt="" style="width: 100%">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-box" v-if="trainQuestions.length > 0">

      <el-button v-if="!showSubmit" type="primary" size="medium" @click="submitBtn()">
        提交</el-button>
    </div>
    <div class="Mask" v-if="ismake">
      <i class="el-icon-loading"></i>
      <!-- <div>
        提交试卷时请勿操作
      </div> -->

    </div>
    <el-dialog title="配置游戏元素" :visible.sync="dialogSetting">
      <el-form :model="form">
        <div class="isbtn">
          <span>挑战1【简单】: </span>
          <el-switch v-model="autonomousButton.challenge1" :active-value="1" :inactive-value='0'>
          </el-switch>
        </div>
        <div class="isbtn">
          <span>挑战2【一般】: </span>
          <el-switch v-model="autonomousButton.challenge2" :active-value="1" :inactive-value='0'>
          </el-switch>
        </div>
        <div class="isbtn">
          <span>挑战3【困难】: </span>
          <el-switch v-model="autonomousButton.challenge3" :active-value="1" :inactive-value='0'>
          </el-switch>
        </div>
        <div class="isbtn">
          <span>我的等级: </span>
          <el-switch v-model="autonomousButton.myLevel" :active-value="1" :inactive-value='0'>
          </el-switch>
        </div>
        <div class="isbtn">
          <span>团队任务1: </span>
          <el-switch v-model="autonomousButton.teamTask1" :active-value="1" :inactive-value='0'>
          </el-switch>
        </div>
        <div class="isbtn">
          <span>团队任务2: </span>
          <el-switch v-model="autonomousButton.teamTask2" :active-value="1" :inactive-value='0'>
          </el-switch>
        </div>
    
        <div class="isbtn">
          <span>探索天地: </span>

          <el-switch v-model="autonomousButton.explorerList" :active-value="1" :inactive-value='0'>
          </el-switch>
        </div>
        <div class="isbtn">
          <span>积分实时排行榜: </span>
          <el-switch v-model="autonomousButton.pointRank" :active-value="1" :inactive-value='0'>
          </el-switch>
        </div>
        <div class="isbtn">
          <span>我要提问: </span>
          <el-switch v-model="autonomousButton.topicProposal" :active-value="1" :inactive-value='0'>
          </el-switch>
        </div>




      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSetting = false">取 消</el-button>
        <el-button type="primary" @click="savebutoom">确 定</el-button>
      </div>
    </el-dialog>
  </main>
</template>
  
<script>
// import {
//   findSubjectListByFkId,
//   queryStudentTestByParam,
//   addStudentTestRecord,
// } from "@/service/examinationDetail";
import { getrole, findSubjectListByFkId, submitCdmSubjectAnswer, getAutonomousButton,
  saveOrUpdateAutonomousButton} from "@/service/roleApi"
import { DebounceBy } from "@/utlis/DebounceBy";
import { setToken, setCookie, getCookie } from "@/service/lib/localToken";
import { getSystemTime } from "@/service/userapi";
import Cookies from 'js-cookie';
import { siteTokenKey } from "@/settings";
import VUE_APP_APIHOST from "@/service/lib/requestcofig";
import dayjs from "dayjs";
import {
  getToken
} from "@/service/lib/localToken";
export default {
  name: "RoleIntroduction",
  async asyncData() { },
  data() {
    return {
      dialogSetting: false,
      autonomousButton: {
        challenge1: 1,
        challenge2: 1,
        challenge3: 1,
        explorerList: 1,
        myLevel: 1,
        pointRank: 1,
        teamTask1: 1,
        teamTask2: 1,
        teamTask1: 1,
        teamTask2: 1,
        topicProposal: 1,

      },
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      roleName: this.$route.query.roleName,
      roleImg: require(`../../assets/images/${this.$route.query.roleName}.png`),
      ismake: false,
      btnLoadings: {
        prev: false,
        next: false,
        finish: false,
      },
      VUE_APP_APIHOST,
      modifyBy: '',
      name: '',
      showSubmit: false,
      textarea: "",
      activeNum: 0,
      curAnswerEn: "",
      answers: [],
      showError: false,
      fkId: "", //试卷ID
      subjectTag: "", //答题类型(0-课前,1-课堂,2-问卷)
      studentId: "", //学生ID
      trainQuestions: [

      ],
      params: {},
      answerParams: {

        studentId: JSON.parse(getCookie("userdata")).id,
        studentName: JSON.parse(getCookie("userdata")).name,
        list: [],
      },
      start_date: this.$route.query.start_date,
      end_date: this.$route.query.end_date,
      imageUrl: '',
      headers: {
        'Authorization': getToken()
      },
      roleData: null,
      userinfo: JSON.parse(getCookie("userdata")),
    };
  },

  watch: {
    curAnswerEn() {
      this.showError = false;
    },
    '$route'(newVal, oldVal) {
      console.log(newVal.query.roleName)
      this.roleName = this.$route.query.roleName
      this.getTrainCultivate();
      this.roleImg = require(`../../assets/images/${newVal.query.roleName}.png`)


    }
  },

  created() {
  },
  async mounted() {


    // this.init();
    this.getData()
  },
  methods: {
    savebutoom(){
      let postData = this.autonomousButton
      postData.studentId = this.answerParams.studentId
      postData.studentName = this.answerParams.studentName
      postData.createBy = this.answerParams.studentName
      postData.createTime = dayjs(new Date()).format('YYYYMMDDHHmmss');
      console.log(postData)
      saveOrUpdateAutonomousButton(postData).then(res => {
        this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.dialogSetting =false
      })
    },
    handleSetting() {
      getAutonomousButton(this.answerParams.studentId).then(res => {
        console.log(res)
        if(res.data){
          this.autonomousButton = res.data
        }

        this.dialogSetting = true
      })
    
    },
    // 获
    getData() {
      getrole().then(res => {
        console.log(res)
        let roleData = {}
        res.data.forEach(item => {
          roleData[item.roleCode] = item
        })
        this.roleData = roleData
        this.getTrainCultivate();

      })
    },

    handleAvatarSuccess(item, res, file) {
      item.imageUrl = res.location;
      this.$forceUpdate()
      this.sevePaper()
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt2M;
    },
    showPaper() {
      //获取浏览器缓存答案
      let paperdata = JSON.parse(Cookies.get('paperdata'))[this.$route.query.fkId]

      //判断答案 答题人是否和相同
      if (this.answerParams.studentId == paperdata.studentId) {
        //判断题目数量是否相同
        if (this.answerParams.list.length == paperdata.list.length)
          this.answerParams.list = paperdata.list
      }
    },
    //保存题目
    sevePaper() {
      let studentId = this.answerParams.studentId
      let testId = this.answerParams.testId
      let paperdata = {

      }
      //保存数据 考卷id为建值
      paperdata[testId] = this.answerParams
      Cookies.set('paperdata', JSON.stringify(paperdata));

    },
    keyDown() {
      document.onkeydown = (e) => {

        if (!this.showSubmit) {
          this.sevePaper()
        }

      }
    },

    getStr(string, str) {
      var str_before = string.split(str)[0];
      var str_after = string.split(str)[1];

      return {
        str_before, str_after
      }
    },
    //    查询题目接口
    async getTrainCultivate() {
      let postdata = {
        roleId: this.roleData[this.roleName].id,

        page: 1,
        pageSize: 99,
        subjectType: ''
      };
      findSubjectListByFkId(postdata).then((res) => {
        console.log(res)
        console.log('重新获取')
        this.answerParams.list = []
        if (res.code == "000" && res.data) {
          // this.trainQuestions = res.data;
          let dxlist = [];
          let tklist = [];
          res.data.list.forEach((item) => {
            if (item.subjectType == 0) {
              dxlist.push(item);
            } else {
              tklist.push(item);
            }
          });
          let trainQuestions = dxlist.concat(tklist);
          this.trainQuestions = trainQuestions;
          trainQuestions.forEach((item, index) => {

            this.answerParams.list.push({ answer: "", subjectId: item.id });
          });
          // if (this.$route.query.state !== "0") {
          //   // 查询
          //   // queryStudentTestByParam(this.paramsHistory).then((res2) => {
          //   //   if (res2.code == "000" && res2.data.details) {
          //   //     this.modifyBy = res2.data.modifyBy
          //   //     res2.data.details.forEach((item2) => {
          //   //       trainQuestions.forEach((item) => {
          //   //         if (item.id == item2.subjectId) {
          //   //           item.subjecObj = item2;
          //   //         }
          //   //       });
          //   //       this.answerParams.list.forEach((item3) => {
          //   //         if (item2.subjectId == item3.subjectId) {
          //   //           //已答题目回显
          //   //           //截取是否有上传图片
          //   //           let answer = this.getStr(item2.answer, '|||')
          //   //           item3.answer = answer.str_before;
          //   //           item3.imageUrl = answer.str_after;
          //   //         }
          //   //       });
          //   //     });
          //   //     this.trainQuestions = trainQuestions;
          //   //     this.$nextTick(() => {
          //   //       this.showSubmit = true;
          //   //     });
          //   //   }
          //   // });
          // } else {
          //   this.trainQuestions = trainQuestions;
          //   this.showPaper()
          // }
        }
      });
    },

    submitBtn() {

      //处理已上传图片题目  用|||拼接
      this.answerParams.list.forEach(item => {
        if (item.imageUrl) {
          item.answer = item.answer + '|||' + item.imageUrl
        }
      })
      this.ismake = true

      let postdata = this.answerParams
      postdata.createTime = dayjs(new Date()).format('YYYYMMDDHHmmss');
      postdata.roleId = this.roleData[this.roleName].id
      postdata.roleName = this.roleData[this.roleName].roleName 
      if(this.userinfo.groupType  == 1){
        postdata.studentName =   postdata.studentName + '(实验组1)'
      }
      postdata.answerList = postdata.list
      delete postdata.list
      console.log(postdata)

      // 提交
      submitCdmSubjectAnswer(postdata).then((res) => {
        this.ismake = false
        if (res.code == "000") {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
          this.$router.go(0)
        } else {
          this.ismake = false
          this.$message.error('提交失败');
        }
      });

    },

  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style lang="scss" scoped>
.isbtn {
  margin-top: 20px;
  font-size: 18px;
}

.Mask {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  color: white;
  padding-top: 20%;
}

.training {
  // background: #fafbfc;
}

.role {
  width: 100rem;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .role-text {
    margin-left: 15px;

    .role-title {
      font-size: 24px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #303133;
      margin-bottom: 10px;
    }

    .role-introduce {
      // font-size: 14px;
      // font-family: Adobe Heiti Std;
      // font-weight: normal;
      // color: #666666;
    }
  }

}

.article {
  position: relative;
  margin: 0 auto;
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;
  // padding: 2rem 6.25rem;
  background: #fff;
  border-bottom: 0.052083rem solid #c3cad6;
  margin-bottom: 28px;

  .title {
    font-size: 32px;
    font-weight: bold;

    color: #303133;
    line-height: 1.5625rem;
    text-align: center;
  }

  .time {
    margin: 0.9375rem 0 1.458333rem;
    font-size: 14px;
    font-weight: bold;
    color: #c3cad6;
    line-height: 1.5625rem;
    text-align: center;
  }

  .content {
    word-break: break-all;
  }
}

.training-test {
  position: relative;
  margin: 0 auto;
  width: 100rem;
  background: #fff;
  padding-bottom: 1.458333rem;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;

  .top-bg {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, #0160A3, #349BE3);
    border-radius: 8px 8px 0 0;
  }

  .title {
    min-height: 5.833333rem;
    font-size: 1.5625rem;
    font-weight: bold;
    color: #324057;
    line-height: 3.833333rem;
    padding: 0 30px;
    text-align: left;
  }

  .test-box {
    position: relative;
    margin: 0 24px;
    // border: 1px solid #c3cad6;
    border-radius: 8px;

    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.875rem;

      // font-size: 28px;
      /*height: 4.583333rem;*/
      // border-bottom: 0.052083rem solid #c3cad6;
      .name {
        width: 90%;

        .score {
          font-size: 16px;
          color: #999999;
        }
      }

      .pos-count {
        /*text-align: bottom;*/
        /*text-align: b;*/
        font-size: 1.041667rem;
        font-weight: bold;
        color: red;

        span {
          font-size: 24px;
        }
      }
    }
  }

  .question-box {
    display: flex;
    padding: 36px;
    background: #F5F7FB;

    .left {
      font-size: 1.25rem;
      font-weight: bold;
      color: #324057;
    }

    .right {
      margin-left: 34px;
      word-wrap: break-word;
      word-break: break-all;
      flex: 1;

      .question {
        padding-bottom: 30px;
        font-size: 20px;
        font-weight: bold;
        color: #324057;
      }

      .TFprompt {
        color: rgb(23, 235, 23);
        float: right;
      }

      .TFprompt2 {
        color: red;
        float: right;
      }

      .answer {
        margin-bottom: 20px;
        border-bottom: 1px dashed #D9D9D9;
        padding-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .el-radio {
          word-wrap: break-word;
          word-break: break-all;
          white-space: normal;
          font-size: 1.041667rem;

          &.showError {
            ::v-deep {
              .el-radio__label {
                color: #d52c0b;
              }
            }
          }
        }
      }
    }
  }

  .btns {
    padding-bottom: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-button {
      border-radius: 8px;
      margin: 0 12px;
      box-sizing: border-box;
      width: 120px;
      height: 48px;
    }

    .el-button--primary {
      color: #fff;
    }

    .el-button--primary.is-active,
    .el-button--primary:active {
      background: #3a8ee6;
      border-color: #3a8ee6;
      color: #fff;
    }
  }
}

.btn-box {
  display: flex;
  justify-content: space-around;
  margin: 5px;
  padding-bottom: 30px;
}

::v-deep {
  .el-radio__label {
    color: #959dab;
    line-height: 1.5;
    font-size: 18px !important;
  }
}
</style>
  