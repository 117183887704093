import { render, staticRenderFns } from "./examination.vue?vue&type=template&id=48f60cf0&scoped=true&"
import script from "./examination.vue?vue&type=script&lang=js&"
export * from "./examination.vue?vue&type=script&lang=js&"
import style0 from "./examination.vue?vue&type=style&index=0&id=48f60cf0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f60cf0",
  null
  
)

export default component.exports