export const language = {
  // 头部导航栏
  headerNav: {
    myCourses: "My Course",
    MyTest: "My Test",
    MyAchievements: "My Achievements",
    MyLeaderboard: "My Leaderboard"
  },
  // 课程选择
  myCourses: {
    CourseSelection: "Course Selection",
    Course: "Course",
    TheCurrentSession: "The Current Session",
    AllSections: "All Sessions",
    point: "1 point = 1 coin ",
    PreclassTime: "Pre-class Time",
    InclassTime: "In-class Time",
    AfterclassTime: "After-class Time",
    PreclassMaterialsDownload: "Pre-class Materials Download",
    PreclassQuiz: "Pre-class Quiz",
    InclassQuiz: "In-class Quiz",
    AfterclassDiscussion: "After-class Discussion",
    RewardsRedemption: "Rewards Redemption",
    LeaderboardbySession: "Leaderboard by Session",
    BadgesandPointsbySession: "Badges and Points by Session",
    NotStarted: "Not Started",
    Finished: "Finished",
    AnswerHint: "The correct answer is",
    AnswerTrue: "Congratulations!",
    AnswerFalse: "Unfortunately! You are wrong!",
    AnswerIntegral: " You've achieved corresponding points!",
    Sortedby: "Sorted by:",
    Likedmostfirst: "'Liked' times: most first",
    Likedleastfirst: "'Liked' times: least first",
    timeearliestfirst: "Post time: earliest first",
    timelastfirst: "Post time: last first",
    Viewhereply: "View the reply",
    PackUp: "pack up",
    Next: "Next",
    Submit: "Submit",
    Reply: 'Reply',
    RewardsRedemption: "Rewards Redemption",
    Redemption: "Redemption",
    Records: "Records",
    Details: "Details",
    RedeemedRewards: "Redeemed Rewards",
    Coins: "Coins",
    Download: "Download",
    PersonalizedFeedback: "Personalized Feedback",
    Pleasetypeyourquestionbelow: "Please type your question below",
    Submit: "Submit",
    HomeworkMarking: "Homework Marking",
    Pleasesubmityourhomeworkbelow: "Please submit your homework below",
    Clicktosubmit: "Click to submit",
    Leaveyournoteshere: "Leave your notes here",
    Submit: "Submit",
    SubmittedQuestion: "Submitted Question",
    Replier: "Replier",
    Replied: "Replied",
    SubmittedHomework: "Submitted Homework",
    Notes: "Notes",
    Dates: "Dates",
    Descriptions: "Descriptions",
    sum: "sum",
    Names: "Names",
    ProfilePhotos: "Profile Photos",
    Points: "Total points achieved in this session",
    Preclassquiz: "Pre-class quiz",
    Preclassdownload: "Pre-class download",
    Preclasstaskcompletion: "Pre-class task completion",
    Inclassquiz: "In-class quiz",
    Inclassvoluntaryparticipation: "In-class voluntary participation",
    Receivinglikesinafterclassdiscussions: "Receiving 'likes' in after-class discussions ",
    coin: "coins",
    zerointegral: "0 point",
    Thequiz: "The quiz is over. You've answered   ",

    questions: '   questions correctly,   ',
    achieving: 'achieving  ',
    pointsintotal: "points in total!",
    publish: "Submit",
    Mypoints: "My points achieved in this session",
    Myaccount: "My account balance",

  },
  //考试中心
  examination: {
    UnfinishedTest: "Unfinished Test",
    Graded: "Graded",
    NotGraded: "NotGraded",
    ViewHistory: "View History",
    Achievedscores: "Achieved scores",

    TestTime: "Test Time",
    Start: "Start",
    Rater: "Rater",
    Scores: "Full marks of the test paper",
    Correct: "Correct",
    Wrong: "Wrong",
    examinationScores: "Score"
  },
  //我的成就
  MyAchievements: {
    Badges: "Accumulated badges in this course",
    Points: "Accumulated points in this course",

    Certificate: "Certificate",
    Title: "Title",
    IssueDate: "Issue Date",
  },
  //我的排行榜
  MyLeaderboard: {
    LeaderboardSum: "Leaderboard Sum",
    Rank: "Rank",
    Name: "Name",
    TotalPoints: "Total Points"
  },
  //用户设置
  UserSettings: {
    UserSettings: "User Settings",
    BasicInformation: "Basic Information",
    Account: "Account",
    Name: "Name",
    Gender: "Gender",
    Age: "Age",
    Major: "Major",
    Grade: "Grade",
    Mail: "Mail",
    PhoneNumber: "Phone Number",
    UserName: "User Name (One chance to change)",
    ProfilePhoto: "Profile Photo",
    Upload: "Upload",
    ChangePassword: "Change Password",
    OldPassword: "Old Password",
    NewPassword: "New Password",
    ConfirmPassword: "Confirm Password",
    Submit: "Submit",
    UploadPicture: "UploadPicture",
    Reset: "Reset",
    man: "Male",
    woman: "Female",
    submission:'Please do not operate during test paper submission ',
  },
  // 登录
  login: {
    logout: 'Logout',
    Lanugages: 'Language',
    chinese: 'Chinese',
    english: 'English',
    LearningSystem: "Learning System",
    UserName: "Username",
    Password: "Password",
    Login: "Login"

  },
  Nodatatemporarily: "No data temporarily"

}
