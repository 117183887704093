import request from '@/service/lib/request';

//查询学员各类徽章总数
export function queryBadgeTotal (params) {

    return request({
        url: 'module/tProjectStudent/queryBadgeTotal',
        method: 'GET',
        params
    });
}
//学员id查询证书列表
export function findAnnexListByFkId (params) {

    return request({
        url: 'module/tProjectAnnex/findAnnexListByFkId',
        method: 'GET',
        params
    });
}