import request from '@/service/lib/request';
import request2 from '@/service/lib/request2';
export function getSystemTime () {
    return request2({
        url: 'module/other/getSystemTime',
        method: 'get',
    });
}

export function login (data) {
    return request2({
        url: 'module/tProjectStudent/login2',
        method: 'post',
        data
    });
}

//修改学员信息
export function modifyStudent (data) {

    return request({
        url: 'module/tProjectStudent/modifyStudentInfo',
        method: 'post',
        data
    });
}
//修改密码
export function modifyPassword (data) {

    return request({
        url: 'module/tProjectStudent/modifyPassword',
        method: 'post',
        data
    });
}

//查询课程
export function findCourseListByStudentId (data) {

    return request({
        url: 'module/tMidStudentCourse/findCourseListByStudentId?studentId='+data.id,
        method: 'get'
    });
}

