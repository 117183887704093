<template>
    <div>
    <div class="dialog-wrap">
     <!-- <div class="dialog-cover" v-if="isShow" @click="closeMyself"></div> -->
     <div class="dialog-cover" v-if="isShow" ></div>
     <transition name="drop">
     <div class="dialog-content" v-if="isShow">
        <div class="top-bg"></div>
      <!-- <p class="dialog-close" @click="closeMyself">x</p> -->
      <el-button class="dialog-buttonclose" icon="el-icon-close" circle size="mini" @click="closeMyself" v-if="buttonShow"></el-button>
      <p class="title">{{ title }}</p>
      <el-divider></el-divider>
      <div class="slot-box">
        <slot></slot>
      </div>
      
     </div>
     </transition>
    </div>
    </div>
   </template>

<script>
 export default {
 props: {
  isShow: {
  type: Boolean,
  default: false
  },
  title: {
  type: String,
//   default: false
  },
  buttonShow:{
    type: Boolean,
  default: true
  }
 },
 data () {
  return {
  }
 },
 methods: {
  closeMyself () {
  this.$emit('on-close')
  
  }
 }
 }
</script>

<style scoped>
 .drop-enter-active {
 transition: all .5s ease;
 }
 .drop-leave-active {
 transition: all .3s ease;
 }
 .drop-enter {
 transform: translateY(-500px);
 }
 .drop-leave-active {
 transform: translateY(-500px);
 }
 .dialog-wrap {
 /* position: fixed; */
 width: 100%;
 height: 100%;
 }
 .top-bg {
        width: 100%;
        height: 8px;
        background: linear-gradient(90deg, #0160A3, #349BE3);
        border-radius: 8px 8px 0 0;
      }
      .title{
      font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: bold;
  color: #030303;
  text-align: center;
  margin: 50px 0 40px 0;
   }
   .slot-box{
    /* padding: 50px; */
    margin: auto;
   }
 .dialog-cover {
 background: #000;
 opacity: .3;
 position: fixed;
 z-index: 5;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 }
 .dialog-content {
 /* width: 50%; */
 width: 700px;
 position: fixed;
 /* max-height: 50%; */
 overflow: auto;
 background: #fff;
 top: 20%;
 left: 50%;
 /* margin-left: -25%; */
 margin-left: -350px;
 z-index: 10;
 /* border: 2px solid #464068; */
 /* padding: 2%; */
 line-height: 1.6;
 
border-radius: 8px;
 }
 .dialog-close {
 position: absolute;
 right: 5px;
 top: 5px;
 width: 20px;
 height: 20px;
 text-align: center;
 cursor: pointer;
 }
 .dialog-close:hover {
 color: #4fc08d;
 }
 .dialog-buttonclose{
    position: absolute;
 right: 20px;
 top: 30px;
 /* width: 31px;
 height: 31px;
 text-align: center; */
 }
</style>