import request from '@/service/lib/request';
import request2 from '@/service/lib/request2';

//课程id和类型获取课时列表
export function findClassHourListByParam (params) {

    return request({
        url: 'module/tProjectClassHour/findClassHourListByParam',
        method: 'get',
        params
    });
}
//资料下载
export function downloadClassHourAnnex (params) {

    return request({
        url: 'module/tProjectClassHour/downloadClassHourAnnex',
        method: 'get',
        params
    });
}

//课后打卡
export function afterClassCheckin (data) {

    return request({
        url: `module/tMidStudentHour/afterClassCheckin/${data.studentId}/${data.hourId}`,
        method: 'post',
        data
    });
}

//查询等级次数

export function findMidListByPage1 (data) {

    return request({
        url: `module/tMidStudentHour/findMidListByPage1`,
        method: 'post',
        data
    });
}