<template>
  <div class="trophyQuery">

    <div class="content">
      <div v-if="ClassType == 'BeforeClass'">
        <div class="content-result">
          <div class="content-result-left">


            <img v-if="userfind.downloadNumber" src="../../../assets/images/star2.png" alt="">
            <img v-else src="../../../assets/images/star1.png" alt="">
            <div class="introduce">
              <p class="title">资料下载</p>
              <p class="describe">下载次数 {{ userfind.downloadNumber }}</p>
            </div>




          </div>
          <div class="content-result-right">
            <div class="text-undone" @click="handleDownload">去下载</div>
          </div>
        </div>
        <div class="content-result">
          <div class="content-result-left">


            <img v-if="userfind.frontAnswer" src="../../../assets/images/star2.png" alt="">
            <img v-else src="../../../assets/images/star1.png" alt="">
            <div class="introduce">
              <p class="title">课前答题</p>
              <p class="describe">得分{{ userfind.frontAnswer }}</p>
            </div>




          </div>
          <div class="content-result-right">
            <div class="text-undone" @click=handleBeforeClassAnswer()>去答题</div>
          </div>
        </div>
        <!-- <div class="content-result">
          <div class="content-result-left">
            <img v-if="userfind.frontAnswer" src="../../../assets/images/star2.png" alt="">
            <img v-else src="../../../assets/images/star1.png" alt="">
            <div class="introduce">
              <p class="title">课前答题历史</p>
              <p class="describe">得分{{ userfind.frontAnswer }}</p>
            </div>
          </div>
          <div class="content-result-right">
            <div class="text-undone" @click=handleBeforeClassAnswer()>查看</div>
          </div>
        </div> -->
      </div>

      <div class="content-result" v-if="ClassType == 'Classroom'">
        <div class="content-result-left">


          <div style="margin-right: 10px;">

            <img v-if="userfind.classroomAnswer" src="../../../assets/images/star2.png" alt="">
            <img v-else src="../../../assets/images/star1.png" alt="">
          </div>
          <div class="introduce">
            <p class="title">课堂答题</p>
            <p class="describe">得分{{ userfind.classroomAnswer }}</p>
          </div>




        </div>
        <div class="content-result-right">
          <div class="text-undone" @click="handleClassroomAchieve">去答题</div>
        </div>
      </div>
      <div v-if="ClassType == 'AfterClass'">
        <div class="content-result">
          <div class="content-result-left">

            <img v-if="userfind.afterClassCheckinTimes" src="../../../assets/images/star2.png" alt="">
            <img v-else src="../../../assets/images/star1.png" alt="">
            <!-- <img src="../../../assets/images/star1.png" alt=""> -->
            <div class="introduce">
              <p class="title">课后打卡（我已完成本课时的所有背诵内容）
              </p>
              <p class="describe">打卡次数 {{ userfind.afterClassCheckinTimes ? userfind.afterClassCheckinTimes : '0' }}</p>
            </div>




          </div>
          <div class="content-result-right">
            <div class="text-undone" @click="handleClock">打卡</div>
          </div>
        </div>
        <div class="content-result">
          <div class="content-result-left">


            <img v-if="userfind.afterClassScore" src="../../../assets/images/star2.png" alt="">
            <img v-else src="../../../assets/images/star1.png" alt="">
            <div class="introduce">
              <p class="title">课后答题</p>
              <p class="describe">得分{{ userfind.afterClassScore ? userfind.afterClassScore : '0' }}</p>
            </div>




          </div>
          <div class="content-result-right">
            <div class="text-undone" @click=handleAnswer>去答题</div>
          </div>
        </div>
      </div>
      <div v-if="ClassType == 'AfterClass2'">
        <div class="content-result">
          <div class="content-result-left">


            <img v-if="userfind.afterClassCheckinTimes" src="../../../assets/images/star2.png" alt="">
            <img v-else src="../../../assets/images/star1.png" alt="">
            <div class="introduce">
              <p class="title">背诵打卡（我已完成本周背诵任务））</p>
              <p class="describe">打卡次数 {{ userfind.afterClassCheckinTimes ? userfind.afterClassCheckinTimes : '0' }}；
                除解锁下列徽章，积极参与打卡还将获得其他权益</p>
            </div>




          </div>
          <div class="content-result-right">
            <div class="text-undone" @click="handleClock">打卡</div>
          </div>
        </div>
        <div class="content-result">
          <div class="content-result-left">


            <img v-if="outstandingTeamMember == 1" src="../../../assets/images/dy1.png" alt="">
            <img v-else src="../../../assets/images/dy2.png" alt="">
            <div class="introduce">
              <p class="title">最佳队员</p>
              <!-- <p class="describe">积分+500</p> -->
            </div>




          </div>
          <div class="content-result-right">
            <div :class="outstandingTeamMember == 1 ? 'text-undone' : 'text-done'"> {{ outstandingTeamMember == 1 ? '已获得'
              : '未获得'
            }}</div>
          </div>
        </div>
        <!-- <div class="content-result">
          <div class="content-result-left">
            <img src="../../../assets/images/navIcon_2.png" alt="">
            <div class="introduce">
              <p class="title">队员情况</p>
            </div>
          </div>
          <div class="content-result-right">
            <div class="text-undone" @click="handleGroupMember"> 查看</div>
          </div>
        </div> -->
      </div>
    </div>
    <Dialog :isShow="dialogGroupMember" title="组员查询" @on-close="handleGroupMemberClose">
      <groupMember :groupMemberList='groupMemberList'></groupMember>
    </Dialog>
  </div>
</template>
  
<script>
import groupMember from "./groupMember2.vue";
import { getCookie } from "@/service/lib/localToken";
import { downloadClassHourAnnex, findMidListByPage1 } from "@/service/myCourses";
import { findMidStudentListByPage } from "@/service/roleApi";
import dayjs from "dayjs";
export default {
  name: 'ClassHouerDialog',
  components: {

    groupMember


  },
  props: {

    ClassType: {
      type: String,
      //   default: false
    },
    dialogData: {
      type: Object,
      default: {}
    },
    kcid: {
      type: String,
      default: ''
    },
    hourId: {
      type: String,
      default: ''
    },
    isadd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      input4: '',
      outstandingTeamMember: '',
      userfind: {},
      userinfo: JSON.parse(getCookie("userdata")),
      dialogGroupMember: false,
      groupMemberList: []
    }
  },
  mounted() {
    console.log('开启组件')
    if (this.ClassType == 'AfterClass2') {
      console.log('ddd')


    }
    this.getDta()
  },

  methods: {
    handleGroupMemberClose() {
      this.dialogGroupMember = false
    },
    handleGroupMember() {


      findMidStudentListByPage({ studentId: this.userinfo.id, id: this.kcid, page: 1, pageSize: 1 }).then(res => {
        console.log(res.data.list[0])
        if (res.data.list[0].collaboratorTeam) {
          // 用自己的分组序号去查询学员
          let getdata = {
            pageSize: 999,
            page: 1,
            name: '',
            groupType: '',
            hourId: this.hourId,
            roleCodes: [],
            collaboratorTeam: res.data.list[0].collaboratorTeam
          };
          findMidListByPage1(getdata).then(res2 => {
            console.log(res2);
            // this.list = res.data.list;
            this.groupMemberList = res2.data.list
            this.dialogGroupMember = true
          });
          // findMidStudentListByPage({ collaboratorTeam: res.data.list[0].collaboratorTeam, id:this.kcid, page: 1, pageSize: 999 }).then(res2 => {
          //   console.log(res2)
          //   this.groupMemberList = res2.data.list
          //   this.dialogGroupMember = true

          // })


        } else {
          this.$message({
            message: "暂未设置分组，请联系管理员",
            type: "warning",
          });
        }
      })
    },
    getDta() {
      let postData = {
        hourId: this.hourId,
        studentId: getCookie("userId"),
        page: 1,
        pageSize: 1
      }
      findMidListByPage1(postData).then(res => {
        console.log(res)
        this.outstandingTeamMember = res.data.list[0].outstandingTeamMember
        this.userfind = res.data.list[0]
      })
    },
    handleAnswer() {
      console.log('dialogData', this.dialogData);
      this.$router.push({
        path: "/myCourses/myCoursesExaminationDetail2",
        query: {
          fkId: this.dialogData.id,
          subjectTag: 3,
          studentId: getCookie("userId"),
          start_date: this.formatDate(this.dialogData.startTime),
          courseId: this.dialogData.courseId,
          end_date: this.formatDate(this.dialogData.endTime),
        },
      });
    },
    formatDate(data) {
      return dayjs(data).format("YYYY-MM-DD HH:mm:ss");
    },
    handleClassroomAchieve() {
      console.log('dialogData', this.dialogData);
      this.$router.push({
        path: "/myCourses/myCoursesExaminationDetail",
        query: {
          fkId: this.dialogData.id,
          subjectTag: 1,
          studentId: getCookie("userId"),
          start_date: this.formatDate(this.dialogData.startTime),

          end_date: this.formatDate(this.dialogData.endTime),
        },
      });
    },
    handleBeforeClassAnswer() {
      this.$router.push({
        path: "/myCourses/myCoursesExaminationDetail",
        query: {
          fkId: this.dialogData.id,
          subjectTag: 0,
          studentId: getCookie("userId"),
          start_date: this.formatDate(this.dialogData.startTime),
          end_date: this.formatDate(this.dialogData.endTime),

        },
      });
    },
    handleClock() {
      if (this.isadd) {
        this.userfind.afterClassCheckinTimes++
      }

      // this.getDta()
      this.$emit('handleClock', this.dialogData)
    },
    handleDownload() {
      if (this.isadd) {
        this.userfind.downloadNumber++
      }

      // this.getDta()
      this.$emit('handleDownload', this.dialogData)
    }
  }


}
</script>
  
<style lang="scss" scoped>
.trophyQuery {
  text-align: center;

  .title {
    font-size: 24px;
    font-family: Adobe Heiti Std;
    font-weight: bold;
    color: #030303;
  }

  .content {
    margin: 40px auto;
    width: 90%;

    .content-search {
      margin-bottom: 25px;
    }

    .content-result {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content-result-left {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        img {
          width: 52px;
          margin-right: 20px;
        }

        .introduce {
          margin-left: 5px;
          text-align: left;

          .title {
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: bold;
            color: #1A1A1A;
            margin-bottom: 5px;
          }

          .describe {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #C7C8C8;
          }
        }
      }

      .content-result-right {
        .text-undone {
          width: 100px;
          height: 42px;
          background: linear-gradient(0deg, #E74C32, #F19826, #F8CA66);
          border-radius: 21px;
          line-height: 42px;

          color: #FFFFFF;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          cursor: pointer;
        }

        .text-done {
          width: 100px;
          height: 42px;
          background: rgba(206, 206, 206, 1);
          border-radius: 21px;
          line-height: 42px;

          color: #FFFFFF;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          cursor: pointer;
        }
      }
    }
  }
}
</style>