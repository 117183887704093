<template>
  <li class="card">
    <div class="line-1">
      <div class="module">
        <span v-if="cardData.state == 0"> {{ $t("language.examination.UnfinishedTest") }}</span>
        <span v-else-if="cardData.state == 1">{{ $t("language.examination.NotGraded") }}</span>
        <span v-else-if="cardData.state == 2">{{ $t("language.examination.Graded") }}</span>
      </div>
      <div class="level">
        <div v-show="cardData.state == 2">
         {{ $t("language.examination.Achievedscores") }}：<span>{{ cardData.studentScore }}</span
          > 
        </div>
      </div>
      <div class="score">
        {{ $t("language.examination.Scores") }}：<span>{{ cardData.testScore }}</span
        > <span v-if="$i18n.locale == 'zh-CN'">分</span>
      </div>
    </div>
    <div class="name">{{ cardData.name }}</div>
    <!-- <div class="intro"></div> -->

    <div class="btn-bottom">
      <div class="time">
           {{ $t("language.examination.TestTime") }}：
        <span
          >{{ formatDate(cardData.startTime) }} ~{{
            formatDate(cardData.endTime)
          }}
        </span>
      </div>
    </div>
    <div class="btn-bottom" v-if="isBtns" @click.stop>
      <div @click="handleBegin" class="btn untrained" v-if="cardData.state == 0">{{ $t("language.examination.Start") }}</div>
      <div @click="handleHistory" class="btn untrained" v-else>  {{ $t("language.examination.ViewHistory") }}</div>
    </div>
  </li>
</template>

<script>
import { getCookie } from "@/service/lib/localToken";
import { getSystemTime } from "@/service/userapi";
import dayjs from "dayjs";
export default {
  props: {
    cardData: {
      type: Object,
      default: () => {},
    },
    isBtns: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    console.log("this.cardData", this.cardData);
  },
  data() {
    return {
      userinfo: JSON.parse(getCookie("userdata")),
      //  用户分组为2的时候才显示积分兑换  用户为0的时候不显示积分
    };
  },
  methods: {
    formatDate(data) {
      return dayjs(data).format("YYYY-MM-DD HH:mm:ss");
    },
    //判断点击按钮时时间是否在范围内
    estimateTime(start_date, end_date, stamp) {
      //开始时间
      let start = new Date(
        start_date.replace(/-/g, "/").replace(/-/g, "/")
      ).getTime();
      //结束时间
      let end = new Date(
        end_date.replace(/-/g, "/").replace(/-/g, "/")
      ).getTime();
      //当前时间
      stamp = new Date(stamp.replace(/-/g, "/").replace(/-/g, "/")).getTime();
      if (start > stamp) {
           if (this.$i18n.locale == "zh-CN") {
          this.$message({
            message: "未开始",
            type: "warning",
          });
        } else {
          this.$message({
            message: "Not Started",
            type: "warning",
          });
        }
      } else if (stamp > end) {
       if (this.$i18n.locale == "zh-CN") {
          this.$message({
            message: "已结束",
            type: "warning",
          });
        } else {
          this.$message({
            message: "Finished",
            type: "warning",
          });
        }
      } else if (start <= stamp && stamp <= end) {
        console.log("进行中");

        this.$router.push({
          path: "/examination/examinationDetail",
          query: {
            fkId: this.cardData.id,
            subjectTag: 2,
            studentId: getCookie("userId"),
            state:this.cardData.state,
            name:this.cardData.name,
               start_date,
               end_date, 
          },
        });
      }
    },
    //开始考试按钮
    handleBegin() {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(
            this.formatDate(this.cardData.startTime),
            this.formatDate(this.cardData.endTime),
            res.data
          );
        }
      });
    },
    //  查看历史按钮
    handleHistory() {
         this.$router.push({
          path: "/examination/examinationDetail",
          query: {
            fkId: this.cardData.id,
            subjectTag: 2,
            studentId: getCookie("userId"),
            state:this.cardData.state,
            name:this.cardData.name
          },
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 464px;
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 10px;

  .line-1 {
    padding: 0 1.041667rem;
    height: 3.229167rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.052083rem dashed #c3cad6;
    font-size: 0.833333rem;
    font-weight: bold;
    color: #959eab;
    .module {
      margin-right: 22px;
    }
    .level {
      flex: 1;
      span {
        margin-right: 0.520833rem;
        font-size: 0.9375rem;
        color: #1d7be3;
      }
    }
    .score {
      font-size: 0.625rem;
      color: #959eab;
      vertical-align: text-bottom;
      span {
        margin-right: 0.520833rem;
        font-size: 0.9375rem;
        color: #1d7be3;
      }
    }
  }
  .name {
    margin: 1.25rem 1.041667rem 0;
    font-size: 0.9375rem;
    font-weight: bold;
    color: #324057;
    line-height: 0.9375rem;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .intro {
    margin: 0.625rem 1.041667rem 0.9375rem;
    height: 3.4375rem;
    font-size: 0.729167rem;
    color: #959eab;
    line-height: 1.145833rem;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .btn-bottom {
    margin: 0 1.041667rem;
    margin-top: 1rem;
    padding-bottom: 1.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .time {
      font-size: 0.729167rem;
      color: #959eab;
      span {
        color: #1d7be3;
      }
    }
    .btn {
      width: 5.25rem;
      height: 2.5rem;
      font-size: 0.729167rem;
      font-weight: bold;
      color: #fafbfc;
      line-height: 2.5rem;
      text-align: center;
      border-radius: 0.416667rem;
      transition: 0.5s;
      &.untrained {
        background: #1d7be3;
      }
      &:hover {
        background: rgba($color: #1d7be3, $alpha: 0.7);
      }
      &.trained {
        background: #c3cad6;
        &:hover {
          background: rgba($color: #c3cad6, $alpha: 0.7);
        }
      }
    }
  }
  .time-bottom {
    margin: 0 20px;
    padding-bottom: 36px;
    .time {
      color: #1d7be3;
    }
  }
}
</style>
