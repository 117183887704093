import { render, staticRenderFns } from "./ClassHouerDialog.vue?vue&type=template&id=a033d354&scoped=true&"
import script from "./ClassHouerDialog.vue?vue&type=script&lang=js&"
export * from "./ClassHouerDialog.vue?vue&type=script&lang=js&"
import style0 from "./ClassHouerDialog.vue?vue&type=style&index=0&id=a033d354&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a033d354",
  null
  
)

export default component.exports