<template>
  <div class="examination">
    <div class="container">
      <div class="classHour-title">
        <!-- <img src="../../../assets/images/back.png" alt=""> -->
        <el-button class="title-bottom" type="info" icon="el-icon-back" circle @click="$router.go(-1)"></el-button>

        <p class="title-text">{{ this.$route.query.courseName }}</p>
      </div>
      <!-- <el-divider></el-divider> -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="当前课时" name="now"></el-tab-pane>
        <el-tab-pane label="所有课时" name="history"></el-tab-pane>
      </el-tabs>
      <div class="card-box" v-if="cardData.length">


        <li class="card" v-for="(cardData, index) in cardData" :key="index">
          <div class="top-bg"></div>
          <div class="card-content">
            <!-- <div class="line-1">
        <div class="module">
          {{ isBtns ? cardData.moduleName : cardData.module }}
        </div>
        <div class="level">
          {{ isBtns ? cardData.levelName : cardData.level }}
        </div>
        <div
          style="display: flex; align-items: center"
          class="score"
          v-if="userinfo.groupType != 0 && userinfo.groupType != 1"
        >
          1积分=1货币
          <img src="../../../assets/images/coin.png" alt="" />
        </div>
      </div> -->

            <div class="name" :title="cardData.name">{{ cardData.name }}</div>
            <!-- <div class="intro"></div> -->
            <div class="btn-bottom">
              <div class="time">
                课前时间：
                <span>{{ formatDate(cardData.startTime) }} ~{{
                  formatDate(cardData.beforeClassTime)
                }}
                </span>
              </div>
            </div>
            <div class="btn-bottom">
              <div class="time">
                课堂时间：
                <span>{{ formatDate(cardData.beforeClassTime) }} ~{{
                  formatDate(cardData.answerTime)
                }}
                </span>
              </div>
            </div>
            <div class="btn-bottom">
              <div class="time">
                课后时间：
                <span>{{ formatDate(cardData.answerTime) }} ~{{
                  formatDate(cardData.endTime)
                }}
                </span>
              </div>
            </div>
            <!-- <div class="btn-bottom-group" v-if="isBtns" @click.stop>
              <div title="资料下载" class="btn untrained" @click="handleDownload(cardData)">
                资料下载
              </div>
              <div title="课前答题" @click="handleBeforeClass(cardData)" class="btn untrained">
                课前答题
              </div>
              <div title="课堂答题" @click="handleClassroom(cardData)" class="btn untrained">
                课堂答题
              </div>
              <div title="课后讨论" @click="handleAfterClass(cardData)" class="btn untrained">
                课后讨论
              </div>

            </div>
            <div class="btn-bottom-group" v-if="isBtns" @click.stop>
              <div v-if="userinfo.groupType == 2" @click="handleIntegral(cardData)" class="btn untrained" title="奖品兑换">
                奖品兑换
              </div>
              <div @click="handRankingList" class="btn untrained" v-if="userinfo.groupType != 0" title="本课时排行">
                本课时排行
              </div>
              <div @click="handleBadge" class="btn untrained" v-if="userinfo.groupType != 0" title="获得徽章与积分">
                获得徽章与积分
              </div>
            </div> -->
            <!-- 实验组1按钮 -->
            <div v-if="userinfo.groupType == 1">
              <div>

                <div class="btn-bottom-group" @click.stop>
                  <div class="btn challenger" title=" 挑战1【简单】" @click="handleBeforeClass(cardData, '挑战1【简单】')">
                    挑战1【简单】
                  </div>
                  <div class="btn challenger" title="挑战2【一般】" @click="handleClassroom(cardData, '挑战2【一般】')">
                    挑战2【一般】
                  </div>
                  <div class="btn challenger" title="挑战3【困难】" @click="handleAfterClass(cardData, '挑战3【困难】')">
                    挑战3【困难】
                  </div>

                </div>
                <div class="btn-bottom-group" @click.stop>
                  <div class="btn challenger" title="我的等级" @click="handleMyLevel(cardData)">
                    我的等级
                  </div>

                  <div class="btn collaborator" @click="handleAfterClass2(cardData)" title="团队任务1">
                    团队任务1
                  </div>
                  <div class="btn collaborator" title="团队任务2" @click="handleTeamMission2(cardData)">
                    团队任务2
                  </div>
                  <div class="btn explorer" title="探索天地" @click="handleExploreList(cardData)">
                    探索天地
                  </div>
                  <div class="btn challenger integral" title="积分实时排行榜" @click="handRankingList(cardData)">
                    积分实时排行榜
                  </div>
                  <div class="btn autonomous integral" title="我要提问" @click="discuss(cardData)">
                    我要提问
                  </div>

                </div>
              </div>
            </div>
            <!-- 非实验组1 -->
            <div v-else>
              <!-- 非自主者按钮   userinfo.autonomous != 1   -->
              <div v-if="false">
                <div v-if="userinfo.challenger != 1" class="btn-bottom-group" @click.stop>
                  <div class="btn " @click="handleBeforeClass(cardData, '课前任务')" title="课前任务">
                    课前任务
                  </div>
                  <div class="btn " title="课中任务" @click="handleClassroom(cardData, '课中任务')">
                    课中任务
                  </div>
                  <div class="btn " title="课后任务" @click="handleAfterClass(cardData, '课后任务')">
                    课后任务
                  </div>
                </div>
                <div v-else class="btn-bottom-group" @click.stop>
                  <div class="btn challenger" title=" 挑战1【简单】" @click="handleBeforeClass(cardData, '挑战1【简单】')">
                    挑战1【简单】
                  </div>
                  <div class="btn challenger" title="挑战2【一般】" @click="handleClassroom(cardData, '挑战2【一般】')">
                    挑战2【一般】
                  </div>
                  <div class="btn challenger" title="挑战3【困难】" @click="handleAfterClass(cardData, '挑战3【困难】')">
                    挑战3【困难】
                  </div>

                </div>
                <div class="btn-bottom-group" @click.stop>
                  <div v-if="userinfo.challenger == 1" class="btn challenger" title="我的等级"
                    @click="handleMyLevel(cardData)">
                    我的等级
                  </div>

                  <div v-if="userinfo.collaborator == 1" class="btn collaborator" @click="handleAfterClass2(cardData)"
                    title="团队任务1">
                    团队任务1
                  </div>
                  <div v-if="userinfo.collaborator == 1" class="btn collaborator" title="团队任务2"
                    @click="handleTeamMission2(cardData)">
                    团队任务2
                  </div>
                  <div v-if="userinfo.explorer == 1" class="btn explorer" title="探索天地"
                    @click="handleExploreList(cardData)">
                    探索天地
                  </div>
                  <div v-if="userinfo.competitor == 1" class="btn challenger integral" title="积分实时排行榜"
                    @click="handRankingList(cardData)">
                    积分实时排行榜
                  </div>
                  <div v-if="userinfo.autonomous == 1" class="btn autonomous integral" title="我要提问"
                    @click="discuss(cardData)">
                    我要提问
                  </div>

                </div>
              </div>
              <!-- 修改为都按自主者算 -->
              <!-- 自主者按钮  v-if="userinfo.autonomous == 1 && isButton"-->
              <div >
                <div class="btn-bottom-group" @click.stop>
                  <div class="btn " v-if="autonomousButton.challenge1 == 0" @click="handleBeforeClass(cardData, '课前任务')"
                    title="课前任务1">
                    课前任务
                  </div>
                  <div class="btn challenger" v-else title=" 挑战1【简单】" @click="handleBeforeClass(cardData, '挑战1【简单】')">
                    挑战1【简单】
                  </div>
                  <div class="btn " v-if="autonomousButton.challenge2 == 0" title="课中任务"
                    @click="handleClassroom(cardData, '课中任务')">
                    课中任务
                  </div>
                  <div class="btn challenger" v-else title="挑战2【一般】" @click="handleClassroom(cardData, '挑战2【一般】')">
                    挑战2【一般】
                  </div>
                  <div class="btn " v-if="autonomousButton.challenge3 == 0" title="课后任务"
                    @click="handleAfterClass(cardData, '课后任务')">
                    课后任务
                  </div>
                  <div class="btn challenger" v-else title="挑战3【困难】" @click="handleAfterClass(cardData, '挑战3【困难】')">
                    挑战3【困难】
                  </div>
                </div>

                <div class="btn-bottom-group" @click.stop>
                  <div v-if="autonomousButton.myLevel == 1" class="btn challenger" title="我的等级"
                    @click="handleMyLevel(cardData)">
                    我的等级
                  </div>

                  <div v-if="autonomousButton.teamTask1 == 1" class="btn collaborator"
                    @click="handleAfterClass2(cardData)" title="团队任务1">
                    团队任务1
                  </div>
                  <div v-if="autonomousButton.teamTask2 == 1" class="btn collaborator" title="团队任务2"
                    @click="handleTeamMission2(cardData)">
                    团队任务2
                  </div>
                  <div v-if="autonomousButton.explorerList == 1" class="btn explorer" title="探索天地"
                    @click="handleExploreList(cardData)">
                    探索天地
                  </div>
                  <div v-if="autonomousButton.pointRank == 1" class="btn challenger integral" title="积分实时排行榜"
                    @click="handRankingList(cardData)">
                    积分实时排行榜
                  </div>
                  <div v-if="autonomousButton.topicProposal == 1" class="btn autonomous integral" title="我要提问"
                    @click="discuss(cardData)">
                    我要提问
                  </div>

                </div>
              </div>
            </div>




          </div>
          <el-dialog title="本课时排行" :visible.sync="dialogTableVisible">

            <el-table :data="gridData" border style="width: 100%; margin-top: 20px">

              <el-table-column label="排名" type="index" width="80"></el-table-column>
              <el-table-column prop="nickName" label="姓名" width=""></el-table-column>

              <el-table-column prop="picturePath" label="头像" width="">
                <template slot-scope="scope">
                  <img :src="scope.row.picturePath" alt="" width="80" height="80" />
                </template>
              </el-table-column>
              <el-table-column prop="totalIntegral" sortable label="本课时获得总积分" width=""></el-table-column>
            </el-table>
          </el-dialog>
          <el-dialog width="1200px" title="获得徽章与积分" :visible.sync="dialogTableVisibleBadge">
            <el-table :data="BadgeData" border style="width: 100%; margin-top: 20px">
              <el-table-column prop="fullyPrepared" sortable label="徽章(Fully Prepared)" width="180">
                <template slot-scope="scope">
                  <span :class="scope.row.fullyPrepared != 0 ? 'greencolor' : 'redcolor'">{{ scope.row.fullyPrepared == 0
                    ? "未获得" : "已获得" }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="taskCompletion" sortable label="徽章(Task Completion)" width="180">
                <template slot-scope="scope">
                  <span :class="scope.row.taskCompletion != 0 ? 'greencolor' : 'redcolor'">{{ scope.row.taskCompletion ==
                    0 ? "未获得" : "已获得" }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="quizWhiz" sortable label="徽章(Quiz Whiz)" width="180">
                <template slot-scope="scope">
                  <span :class="scope.row.quizWhiz != 0 ? 'greencolor' : 'redcolor'">{{ scope.row.quizWhiz == 0 ? "未获得" :
                    "已获得" }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="taskMaster" sortable label="徽章 (Task Master)" width="180">
                <template slot-scope="scope">
                  <span :class="scope.row.taskMaster != 0 ? 'greencolor' : 'redcolor'">{{ scope.row.taskMaster == 0 ?
                    "未获得" : "已获得" }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="topPerformer" sortable label="徽章(Top Performer)" width="180">
                <template slot-scope="scope">
                  <span :class="scope.row.topPerformer != 0 ? 'greencolor' : 'redcolor'">{{ scope.row.topPerformer == 0 ?
                    "未获得" : "已获得" }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="totalIntegral" sortable label="本课时获得总积分" width="80"></el-table-column>

              <el-table-column prop="frontAnswer" sortable :label="$t('language.myCourses.Preclassquiz')"
                width="80"></el-table-column>
              <el-table-column prop="frontDownload" sortable label="课前下载积分" width="80"></el-table-column>
              <el-table-column prop="frontTask" sortable label="课前完成任务积分" width="80"></el-table-column>
              <el-table-column prop="classroomAnswer" sortable label="课堂答题积分" width="80"></el-table-column>
              <el-table-column prop="classroomManual" sortable label="课堂主动参与得分" width="80"></el-table-column>
              <el-table-column prop="currency" sortable label="货币" width="80"></el-table-column>
            </el-table>
          </el-dialog>
        </li>
      </div>
      <div class="no-data" v-else> 暂无数据！</div>
    </div>
    <Dialog :isShow="ClassHouerDialog" :title="DialogTitle" @on-close="ClassHouerDialog = false">
      <ClassHouerDialog :isadd="isadd" :kcid="ClassHourParam.courseId " :hourId="hourId" :ClassType="ClassType" :dialogData="dialogData" @handleDownload="handleDownload"
        @handleClock="handleClock">
      </ClassHouerDialog>
    </Dialog>
    <level :isShow="levelShow" :startNum="startNum" :challengerLevel="challengerLevel" @on-close="levelShow = false">
    </level>
  </div>
</template>
  
<script>
import { findMidListByPage } from "@/service/rankingList";
import { getCookie } from "@/service/lib/localToken";
import { getSystemTime } from "@/service/userapi";
import { downloadClassHourAnnex, findClassHourListByParam, afterClassCheckin, findMidListByPage1 } from "@/service/myCourses";
import { getAutonomousButton } from "@/service/roleApi"
import ClassHouerDialog from "./ClassHouerDialog.vue";
import level from "./level.vue";
import dayjs from "dayjs";

export default {
  name: 'myClassHour',
  components: {


    ClassHouerDialog,
    level


  },

  created() {


  },
  mounted() {
    this.getClassHour()
    console.log('cardata', this.$route.query.cardData);
    this.isZZZ()
  },
  data() {
    return {
      isadd:true,
      ClassHouerDialog: false,
      levelShow: false,
      ClassType: '',
      DialogTitle: "",
      activeName: "now",
      isBtns: true,
      courseName: this.$route.query.courseName,
      cardData: [],
      dialogData: {},
      ClassHourParam: {
        courseId: "",
        reqType: 0,
        studentId: getCookie("userId"),
      },
      language: window.sessionStorage.getItem("lang"),
      userinfo: JSON.parse(getCookie("userdata")),
      parms: {
        page: 1,
        pageSize: 999,
        // hourId: this.$route.query.courseId,
        // groupType: JSON.parse(getCookie("userdata")).groupType
      },
      Badgeparms: {
        page: 1,
        pageSize: 999,
        hourId: this.$route.query.courseId,
        name: JSON.parse(getCookie("userdata")).name,
        groupType: ''
        // groupType: JSON.parse(getCookie("userdata")).groupType
      },
      gridData: [],
      BadgeData: [],
      dialogTableVisible: false,
      dialogTableVisibleBadge: false,
      autonomousButton: {
        challenge1: 1,
        challenge2: 1,
        challenge3: 1,
        explorerList: 1,
        myLevel: 1,
        pointRank: 1,
        teamTask1: 1,
        teamTask2: 1,
        teamTask1: 1,
        teamTask2: 1,
        topicProposal: 1,

      },
      challengerLevel: 0,
      startNum: 0,
      isButton: false,
      hourId: ''
      //  用户分组为2的时候才显示积分兑换  用户为0的时候不显示积分
    };
  },
  methods: {
    // 我要提问 ++
    discuss(cardData) {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          let stamp = new Date(res.data.replace(/-/g, "/").replace(/-/g, "/")).getTime();
          let start = new Date(
            this.formatDate(cardData.startTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          console.log(stamp, start)
          if (start > stamp) {
            this.$message({
              message: "该课时未开始",
              type: "warning",
            });
            return

          }
          // 开始以后逻辑
          this.$addLog('ENTER_DISCUSS_PAGE', cardData.courseId, cardData.id)
          console.log('我要提问')
          this.$router.push({
            path: "/integralExchange2",
            query: {
              hourId: cardData.id,
              IntegralEnd: 1,
              courseName: cardData.name,
            },
          });

        }
      });

    },
    // 判断是否是自主者
    isZZZ() {
      // 修改为所以角色都请求
      // if (this.userinfo.autonomous == 1) {
      //   getAutonomousButton(this.userinfo.id).then(res => {
      //     console.log(res)
      //     this.isButton = true
      //     if (res.data) {
      //       this.autonomousButton = res.data
      //     }

      //   })
      // }
      getAutonomousButton(this.userinfo.id).then(res => {
          console.log(res)
          this.isButton = true
          if (res.data) {
            this.autonomousButton = res.data
          }

        })
    },
    // 切换课时
    handleClick() {
      console.log(this.activeName);
      if (this.activeName == "now") {
        this.ClassHourParam.reqType = 0;
        this.getClassHour();
      } else {
        this.ClassHourParam.reqType = 1;
        this.getClassHour();
      }
    },
    //探索天地 ++ 
    handleExploreList(item) {
      let cardData = item
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          let stamp = new Date(res.data.replace(/-/g, "/").replace(/-/g, "/")).getTime();
          let start = new Date(
            this.formatDate(cardData.startTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          console.log(stamp, start)
          if (start > stamp) {
            this.$message({
              message: "该课时未开始",
              type: "warning",
            });
            return

          }
          // 开始以后逻辑
          this.$addLog('ENTER_EXPLORER_PAGE', item.courseId, item.id)
          this.$router.push({
            path: "/ExploreList",
            query: {
              RouterCourseId: item.id,
              courseName: item.name,
              courseId: item.courseId



            },
          });

        }
      });

    },
    //课程id和类型获取课时列表
    getClassHour() {
      this.ClassHourParam.courseId = this.$route.query.courseId
      findClassHourListByParam(this.ClassHourParam).then((res) => {
        if (res.code == "000" && res.data) {
          console.log("findClassHourListByParam", res);
          this.cardData = res.data;
        }
      });
    },
    formatDate(data) {
      return dayjs(data).format("YYYY-MM-DD HH:mm:ss");
    },
    //判断点击按钮时时间是否在范围内
    estimateTime(start_date, end_date, stamp, BtnType, cardData) {
      console.log(start_date, end_date)
      //开始时间
      let start = new Date(
        start_date.replace(/-/g, "/").replace(/-/g, "/")
      ).getTime();
      //结束时间
      let end = new Date(
        end_date.replace(/-/g, "/").replace(/-/g, "/")
      ).getTime();
      //当前时间
      stamp = new Date(stamp.replace(/-/g, "/").replace(/-/g, "/")).getTime();
      console.log(stamp);
      if (start > stamp) {
        // 未开始
        if (BtnType == "Integral") {
          this.$router.push({
            path: "/integralExchange",
            query: {
              hourId: cardData.id,
              IntegralEnd: 0
            },
          });
        } else {
          this.$message({
            message: "未开始",
            type: "warning",
          });
        }

      } else if (stamp > end) {
        // 已超过
        if (BtnType == "PreclassMaterialsDownload") {
          downloadClassHourAnnex({
            studentId: getCookie("userId"),
            hourId: cardData.id,
          }).then((res) => {
            // console.log(this.cardData.annexPath)
            if (res.data != 0) {
              this.$message.success(res.message);
            }
            if (res.code == "000") {
              window.location.href = cardData.annexPath;
            }
          });
        } else if (BtnType == "AfterClass") {
          this.$router.push({
            path: "/classDiscussion",
            query: {
              fkId: cardData.id,
              subjectTag: 1,
              studentId: getCookie("userId"),
              AfterClassEnd: 0
            },
          });
        } else if (BtnType == "Integral") {
          this.$router.push({
            path: "/integralExchange",
            query: {
              hourId: cardData.id,
              IntegralEnd: 0
            },
          });
        }
        else {
          this.$message({
            message: "已结束",
            type: "warning",
          });
        }

      } else if (start <= stamp && stamp <= end) {
        // 时间中
        if (BtnType == "BeforeClass") {
          this.$router.push({
            path: "/myCourses/myCoursesExaminationDetail",
            query: {
              fkId: cardData.id,
              subjectTag: 0,
              studentId: getCookie("userId"),
              start_date,
              end_date,

            },
          });
        } else if (BtnType == 'handleClock') {
          console.log('正常时间打卡')

          afterClassCheckin({
            studentId: getCookie("userId"),
            hourId: cardData.id,
          }).then((res) => {
            // console.log(this.cardData.annexPath)

            if (res.data != 0) {
              this.$message.success(res.message);
            } else {
              this.$message.success('打卡成功');
            }

          });

        }
        else if (BtnType == "Classroom") {
          this.$router.push({
            path: "/myCourses/myCoursesExaminationDetail",
            query: {
              fkId: cardData.id,
              subjectTag: 1,
              studentId: getCookie("userId"),
              start_date,
              end_date,
            },
          });
        } else if (BtnType == "AfterClass") {
          this.$router.push({
            path: "/classDiscussion",
            query: {
              fkId: cardData.id,
              subjectTag: 1,
              studentId: getCookie("userId"),
              AfterClassEnd: 1,
              start_date,
              end_date,
            },
          });
        } else if (BtnType == "PreclassMaterialsDownload") {
          downloadClassHourAnnex({
            studentId: getCookie("userId"),
            hourId: cardData.id,
          }).then((res) => {
            // console.log(this.cardData.annexPath)
            if (res.data != 0) {
              this.$message.success(res.message);
            }
            if (res.code == "000") {
              window.location.href = cardData.annexPath;
            }
          });
        } else if (BtnType == "Integral") {
          this.$router.push({
            path: "/integralExchange",
            query: {
              hourId: cardData.id,
              IntegralEnd: 1
            },
          });
        }
      }
    },
    //团队任务2 ++ 
    handleTeamMission2(cardData) {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          let stamp = new Date(res.data.replace(/-/g, "/").replace(/-/g, "/")).getTime();
          let start = new Date(
            this.formatDate(cardData.startTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          console.log(stamp, start)
          if (start > stamp) {
            this.$message({
              message: "该课时未开始",
              type: "warning",
            });
            return

          }
          // 开始以后逻辑
          this.$addLog('ENTER_FORUM', cardData.courseId, cardData.id)
          this.$router.push({
            path: "/classDiscussion",
            query: {
              fkId: cardData.id,
              subjectTag: 1,
              studentId: getCookie("userId"),
              AfterClassEnd: 0
            },
          });

        }
      });

    },
    // 我的等级按钮 ++
    handleMyLevel(item) {
      let cardData = item
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          let stamp = new Date(res.data.replace(/-/g, "/").replace(/-/g, "/")).getTime();
          let start = new Date(
            this.formatDate(cardData.startTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          console.log(stamp, start)
          if (start > stamp) {
            this.$message({
              message: "该课时未开始",
              type: "warning",
            });
            return

          }
          // 开始以后逻辑
          let postData = {
            hourId: item.id,
            studentId: getCookie("userId"),
            page: 1,
            pageSize: 1
          }
          findMidListByPage1(postData).then(res2 => {
            console.log(res2)

            this.challengerLevel = res2.data.list[0].challengerLevel
            this.startNum = res2.data.list[0].preClassStar + res2.data.list[0].inClassStar + res2.data.list[0].postClassStar
            this.levelShow = true
            this.$addLog('ENTER_LEVEL_UP', item.courseId, item.id)
          })

        }
      });


    },
    // 课后打卡
    handleClock(cardData) {
      console.log(cardData)
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(
            this.formatDate(cardData.startTime),
            this.formatDate(cardData.endTime),
            res.data,
            "handleClock",
            cardData
          );
        }
      });
    },
    //资料下载按钮事件
    handleDownload(cardData) {
      console.log('123123', cardData);

      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(
            this.formatDate(cardData.startTime),
            this.formatDate(cardData.endTime),
            res.data,
            "PreclassMaterialsDownload",
            cardData
          );
        }
      });

    },
    //课前按钮事件 ++
    handleBeforeClass(cardData, title) {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          let stamp = new Date(res.data.replace(/-/g, "/").replace(/-/g, "/")).getTime();
          let start = new Date(
            this.formatDate(cardData.startTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          let end = new Date(
            this.formatDate(cardData.endTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          console.log(stamp, start)
          if (start > stamp) {
            this.$message({
              message: "该课时未开始",
              type: "warning",
            });
            return

          }
          this.isadd = true
        
        
          this.hourId = cardData.id;
          this.$addLog('TASK_CLICK_1', cardData.courseId, cardData.id)
          console.log('handleBeforeClass', cardData);
          this.DialogTitle = title
          this.ClassHouerDialog = true
          this.ClassType = 'BeforeClass'
          this.dialogData = cardData
        }
      });

      //当前时间



      // this.$router.push({
      //       path: "/myCourses/myCoursesExaminationDetail",
      //       query: {
      //         fkId: cardData.id,
      //         subjectTag: 0,
      //         studentId: getCookie("userId"),
      //         // start_date,
      //         // end_date,

      //       },
      //     });

      // getSystemTime().then((res) => {
      //   if (res.code == "000" && res.data) {


      //     this.estimateTime(
      //       this.formatDate(cardData.startTime),
      //       this.formatDate(cardData.beforeClassTime),
      //       res.data,
      //       "BeforeClass",
      //       cardData
      //     );
      //   }
      // });
    },

    //课堂按钮事件 ++ 
    handleClassroom(cardData, title) {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          let stamp = new Date(res.data.replace(/-/g, "/").replace(/-/g, "/")).getTime();
          let start = new Date(
            this.formatDate(cardData.startTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          console.log(stamp, start)
          if (start > stamp) {
            this.$message({
              message: "该课时未开始",
              type: "warning",
            });
            return

          }

          this.hourId = cardData.id;
          this.$addLog('TASK_CLICK_2', cardData.courseId, cardData.id)
          console.log('handleClassroom', cardData);
          this.DialogTitle = title
          this.ClassType = 'Classroom'
          this.ClassHouerDialog = true
          this.dialogData = cardData
        }
      });


    },
    //团队任务1事件 ++ 
    handleAfterClass2(cardData) {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          let stamp = new Date(res.data.replace(/-/g, "/").replace(/-/g, "/")).getTime();
          let start = new Date(
            this.formatDate(cardData.startTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          let end = new Date(
            this.formatDate(cardData.endTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          console.log(stamp, start)
          if (start > stamp) {
            this.$message({
              message: "该课时未开始",
              type: "warning",
            });
            return

          }
          if(stamp > end ){
            this.isadd = false
          }else{
            this.isadd = true
          }
          // 开始以后逻辑
          this.$addLog('GROUP_TASK_CLICK', cardData.courseId, cardData.id)
          console.log('handleAfterClass', cardData);
          this.hourId = cardData.id;
          this.DialogTitle = "团队任务1"
          this.ClassHouerDialog = true
          this.ClassType = 'AfterClass2'
          this.dialogData = cardData

        }
      });

    },
    //课后按钮事件
    handleAfterClass(cardData, title) {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          let stamp = new Date(res.data.replace(/-/g, "/").replace(/-/g, "/")).getTime();
          let start = new Date(
            this.formatDate(cardData.startTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          let end = new Date(
            this.formatDate(cardData.endTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          console.log(stamp, start)
          if (start > stamp) {
            this.$message({
              message: "该课时未开始",
              type: "warning",
            });
            return

          }
          if(stamp > end ){
            this.isadd = false
          }else{
            this.isadd = true
          }
          // 开始以后逻辑
          this.hourId = cardData.id;
          this.$addLog('TASK_CLICK_3', cardData.courseId, cardData.id)
          console.log('handleAfterClass', cardData);
          this.DialogTitle = title
          this.ClassHouerDialog = true
          this.ClassType = 'AfterClass'
          this.dialogData = cardData

        }
      });

      // getSystemTime().then((res) => {
      //   if (res.code == "000" && res.data) {
      //     this.estimateTime(
      //       this.formatDate(cardData.answerTime),
      //       this.formatDate(cardData.endTime),
      //       res.data,
      //       "AfterClass",
      //       cardData
      //     );
      //   }
      // });
    },
    // 奖品 弃用
    handleIntegral(cardData) {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(
            this.formatDate(cardData.startTime),
            this.formatDate(cardData.endTime),
            res.data,
            "Integral",
            cardData
          );
        }
      });

    },
    //积分排行点击事件
    handRankingList(item) {
      let cardData = item
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          let stamp = new Date(res.data.replace(/-/g, "/").replace(/-/g, "/")).getTime();
          let start = new Date(
            this.formatDate(cardData.startTime).replace(/-/g, "/").replace(/-/g, "/")
          ).getTime();
          console.log(stamp, start)
          if (start > stamp) {
            this.$message({
              message: "该课时未开始",
              type: "warning",
            });
            return

          }
          // 开始以后逻辑
          console.log('item', item);
          this.$addLog('ENTER_RANK', item.courseId, item.id)
          this.parms.hourId = item.id
          findMidListByPage(this.parms).then((res2) => {
            // this.dialogTableVisible = true;
            // this.gridData = res.data.list;
            this.$router.push({
              path: "/rankingList",
              query: {
                list: res2.data.list,
                courseName: item.name
              },
            });
          });

        }
      });

    },
    // 徽章 弃用
    handleBadge() {
      findMidListByPage(this.Badgeparms).then((res) => {
        this.dialogTableVisibleBadge = true;
        this.BadgeData = res.data.list;
      });
    },
  },
};
</script>
<style>
.redcolor {
  color: red;
}

.greencolor {
  color: #3cd53c;
}
</style>
<style lang="scss" scoped>
.examination {
  margin-top: 25px;

  .classHour-title {
    position: relative;
    text-align: center;
    margin-bottom: 50px;

    .title-bottom {
      position: absolute;
      left: 0;
    }

    .title-text {
      font-size: 32px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #303133;

    }

  }

  .card-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    width: 1440px;

    .card {
      width: 440px;
      background: #fff;
      border: 1px solid #eeeeee;
      border-radius: 8px;
      margin-bottom: 30px;
      box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);
      margin-right: 30px;

      .top-bg {
        width: 440px;
        height: 8px;
        background: linear-gradient(90deg, #0160A3, #349BE3);
        border-radius: 8px 8px 0 0;
      }

      .card-content {
        padding: 37px 42px 40px 47px;

        .line-1 {
          padding: 0 1.041667rem;
          height: 3.229167rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 0.052083rem dashed #c3cad6;
          font-size: 0.833333rem;
          font-weight: bold;
          color: #959eab;

          .module {
            margin-right: 22px;
          }

          .level {
            flex: 1;
          }

          .score {
            font-size: 0.625rem;
            color: #959eab;
            vertical-align: text-bottom;

            span {
              margin-right: 0.520833rem;
              font-size: 0.9375rem;
              color: #1d7be3;
            }
          }
        }

        .name {
          // margin: 1.25rem 1.041667rem 0;
          font-size: 20px;
          // font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #0B151D;
          line-height: 81px;
          border-bottom: 1px dashed #E6E6E6;
          margin-bottom: 20px;
          // line-height: 0.9375rem;
          word-break: break-all;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .intro {
          margin: 0.625rem 1.041667rem 0.9375rem;
          height: 3.4375rem;
          font-size: 0.729167rem;
          color: #959eab;
          line-height: 1.145833rem;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }

        .btn-bottom {
          // margin: 0 1.041667rem;
          // padding-bottom: 1.875rem;
          line-height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .time {
            font-size: 14px;

            font-weight: normal;

            color: #999999;

            span {

              color: #999999;
            }
          }

        }

        .btn-bottom-group {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;

          .btn {
            width: 7.25rem;
            height: 2.5rem;
            margin-top: 10px;
            font-size: 0.729167rem;
            font-weight: bold;

            color: #FFFFFF;
            line-height: 2.5rem;
            text-align: center;

            border-radius: 8px;
            transition: 0.5s;
            box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.09);
            background: linear-gradient(112deg, #7668A1, #B2B1D0);
            border: 2px solid rgba(138, 132, 212, 0.48);

            &.untrained {
              background: #1d7be3;
            }

            &.challenger {
              background: linear-gradient(112deg, #FFB136, #F9CB83);
              border: 2px solid rgba(226, 143, 12, 0.48);


              &.integral {
                background: linear-gradient(112deg, #CF7644, #EFA176);
                border: 2px solid rgba(217, 87, 16, 0.48)
              }
            }

            &.collaborator {
              background: linear-gradient(112deg, #37C13B, #73EC76);
              border: 2px solid rgba(30, 187, 35, 0.48);

            }

            &.competitor {
              background: linear-gradient(112deg, #FFB136, #F9CB83);
              border: 2px solid rgba(226, 143, 12, 0.48);

            }

            &.explorer {
              background: linear-gradient(112deg, #4274CE, #6F9AE9);
              border: 2px solid rgba(38, 99, 208, 0.48);
            }

            &.autonomous {
              background: linear-gradient(112deg, #45D5CD, #78EAE3);
              border: 2px solid rgba(21, 212, 201, 0.48);
            }

            &:hover {
              // background: rgba($color: #1d7be3, $alpha: 0.7);
            }

            &.trained {
              background: #c3cad6;

              &:hover {
                background: rgba($color: #c3cad6, $alpha: 0.7);
              }
            }
          }
        }

        .time-bottom {
          margin: 0 20px;
          padding-bottom: 36px;

          .time {
            color: #1d7be3;
          }
        }
      }
    }
  }

  .no-data {
    min-height: 50vh;
    margin: auto;
    box-sizing: border-box;
    padding-top: 10%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  ::v-deep .el-tabs__nav-wrap::after {
    z-index: 0;
  }
}
</style>
  