<template>
  <div class="exchange">
    <ul class="train-list">
      <!-- 下载模块 -->
      <li class="card" v-for="item in downloadList" :key="item.id">
        <div class="line-1">
          <div class="module">
            {{ $t("language.myCourses.RedeemedRewards") }}
          </div>
          <div class="score">
            <span>{{ item.deductNumber }}</span>{{ $t("language.myCourses.Coins") }}
          </div>
        </div>
        <div class="name">{{ item.introduce }}</div>
        <div class="intro"></div>
        <div class="btn-bottom">
          <div @click="handleDownload(item)" class="btn untrained">
            {{ $t("language.myCourses.Download") }}
          </div>
        </div>
      </li>
      <!-- 问题提交模块 -->
      <li class="card" v-for="item in issue" :key="item.id">
        <div class="line-1">
          <div class="module">
            {{ $t("language.myCourses.PersonalizedFeedback") }}
          </div>

          <div class="score">
            <span>{{ item.deductNumber }}</span>{{ $t("language.myCourses.Coins") }}
          </div>
        </div>
        <div class="name">{{ item.introduce }}</div>
        <div class="intro">
          <div>
            <span style="color: #324057; font-weight: bold">{{ $t("language.myCourses.SubmittedQuestion") }}:</span>
            <div v-html="item.problemContent"></div>
          </div>

          <div v-if="item.replyBy">
            <div>
              <span style="color: #324057; font-weight: bold">{{ $t("language.myCourses.Replier") }}:</span>
              {{ item.replyBy }}
            </div>
            <div>
              <span style="color: #324057; font-weight: bold">{{ $t("language.myCourses.Replied") }}:</span>
              <div v-html="item.replyContent"></div>
            </div>
          </div>
          <div v-else>{{ $t("language.Nodatatemporarily") }}</div>
        </div>
      </li>
      <!-- 上传论文模块 -->
      <!-- 问题提交模块 -->
      <li class="card" v-for="item in thesis" :key="item.id">
        <div class="top-bg"></div>
        <div class="card-box">
          <div class="name">{{ item.introduce }}</div>
          <div class="line-1">
            <div class="module">
              作业批改
            </div>
            <!-- <div class="level">
    上传
      </div> -->

            <!-- <div class="score">
            <span>{{ item.deductNumber }}</span
            >{{ $t("language.myCourses.Coins") }}
          </div> -->
          </div>

          <div class="intro">
            <ul class="upload-ul">
              <li class="upload-li">
                <!-- <div>学生提交作业</div> -->
                <div>
                  学生备注：{{ item.paperRemark }}
                </div>
                <div @click="handleuploadPaperPath(item)" class="btn untrained">
                  下载文件
                </div>
              </li>
              <li class="upload-li">
                <div style="margin-bottom:10px">
                  {{ $t("language.myCourses.Replier") }}：{{ item.replyBy }}
                </div>
                <div>
                  回复内容：
                  <div v-html="item.paperReplyRemark"></div>
                </div>
                <div @click="handlepaperReplyPath(item)" class="btn untrained">
                  {{ $t("language.myCourses.Download") }}
                </div>

              </li>
            </ul>
          </div>

          <div class="btn-bottom">
            <!-- <div        class="btn untrained">提交论文</div> -->
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "exchange",
  props: {
    exchangeHistoryList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    exchangeHistoryList() {
      this.type();
    },
  },
  data() {
    return {
      textarea: "",
      form: {},
      downloadList: [],
      issue: [],
      thesis: [],
    };
  },
  mounted() {
    this.type();
  },
  methods: {
    type() {
      this.downloadList = [];
      this.issue = [];
      this.thesis = [];
      this.exchangeHistoryList.forEach((item) => {
        if (item.exchangeType == 1) {
          this.downloadList.push(item);
        } else if (item.exchangeType == 2) {
          // item.problemContent = item.problemContent.replace(/\n/g, "<br>");

          // item.replyContent = item.replyContent.replace(/\n/g, "<br>");
          this.issue.push(item);
          console.log("this.issue", item);
        } else if (item.exchangeType == 3 || item.exchangeType == 4) {
          this.thesis.push(item);
        }
      });
    },
    handleDownload(item) {
      window.location.href = item.downloadPath;
    },
    handleuploadPaperPath(item) {
      window.location.href = item.uploadPaperPath;
    },
    handlepaperReplyPath(item) {
      window.location.href = item.paperReplyPath;
    },
  },
};
</script>

<style lang="scss" scoped>
.train-list {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  padding-bottom: 2.604167rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  .card {
    margin: 0 14px 14px 0;

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    width: 464px;
    height: 35rem;
    overflow: auto;
    background: #fff;
    border: 1px solid #eeeeee;
    border-radius: 10px;

    .top-bg {
      width: 100%;
      height: 8px;
      background: linear-gradient(90deg, #0160A3, #349BE3);
      border-radius: 8px 8px 0 0;
    }

    .card-box {
      padding: 0 40px 0 40px;
      height: 90%;

      .line-1 {
        padding: 0 1.041667rem;
        height: 3.229167rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 0.833333rem;
        font-weight: bold;
        color: #959eab;

        .module {
          margin-right: 22px;
        }

        .level {
          flex: 1;
        }

        .score {
          font-size: 0.625rem;
          color: #959eab;
          vertical-align: text-bottom;

          span {
            margin-right: 0.520833rem;
            font-size: 0.9375rem;
            color: #1d7be3;
          }
        }
      }

      .name {
        margin: 1.25rem 1.041667rem 0;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        color: #0B151D;
        border-bottom: 0.052083rem dashed #c3cad6;

        height: 3.229167rem;
        // word-break: break-all;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }

      .intro {
        white-space: pre-line;
        margin: 0.625rem 1.041667rem 0.9375rem;
        min-height: 400px;
        font-size: 0.729167rem;
        color: #959eab;
        line-height: 1.145833rem;
        word-break: break-all;
        // display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }

      .upload-demo {
        text-align: center;
      }

      .btn-bottom {
        margin: 0 1.041667rem;
        padding-bottom: 1.875rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .time {
          font-size: 0.729167rem;
          color: #959eab;

          span {
            color: #1d7be3;
          }
        }

      }

      .upload-ul {
        li:first-child {
          border-bottom: 0.052083rem dashed #c3cad6;
          padding: 0 0 20px 0;

        }

        .upload-li {
          // display: flex;
          // align-items: center;
          // justify-content: space-between;

          margin-top: 15px;

          .btn {

            width: 104px;
            height: 34px;
            font-size: 0.729167rem;
            font-weight: bold;
            color: #fafbfc;
            line-height: 2.5rem;
            text-align: center;
            border-radius: 8px;
            transition: 0.5s;
            margin-top: 20px;

            &.untrained {

              background: #0161A4;

            }

            &:hover {
              background: rgba($color: #1d7be3, $alpha: 0.7);
            }

            &.trained {
              background: #c3cad6;

              &:hover {
                background: rgba($color: #c3cad6, $alpha: 0.7);
              }
            }
          }
        }
      }

      .time-bottom {
        margin: 0 20px;
        padding-bottom: 36px;

        .time {
          color: #1d7be3;
        }
      }
    }
  }
}</style>
