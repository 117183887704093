import request from '@/service/lib/request';

export function addVisitPageLog (data) {
    return request({
      url: 'module/tVisitPageLog/addVisitPageLog',
      method: 'post',
      data
    });
  }

  export function addOperationLog (data) {
    return request({
      url: 'module/tOperationLog/addOperationLog',
      method: 'post',
      data
    });
  }