<template>
  <div class="header">
    <div class="nav-topbar">
      <div class="container">
        <!-- <div class="topbar-menu" v-if="userinfo.groupType != 1> -->
        <div class="topbar-menu" v-if="userinfo.groupType != 1">
          <div v-if="userinfo.openRoleIcon == 1 && userinfo.groupType == 2">
            <p>点击以下图标查看你的玩家类型</p>
            <img v-if="userinfo.challenger == 1" src="../assets/images/navIcon_1.png" alt="" title=""
              @click="handleRole('TZZ')">
            <img v-if="userinfo.collaborator == 1" src="../assets/images/navIcon_2.png" alt="" title=""
              @click="handleRole('HZZ')">
            <img v-if="userinfo.competitor == 1" src="../assets/images/navIcon_3.png" alt="" title=""
              @click="handleRole('JZZ')">
            <img v-if="userinfo.explorer == 1" src="../assets/images/navIcon_4.png" alt="" title=""
              @click="handleRole('TSZ')">
            <img v-if="userinfo.autonomous == 1" src="../assets/images/navIcon_5.png" alt="" title=""
              @click="handleRole('ZZZ')">
            <div id="chartContainer" ref="echartsContainer" @mouseenter="zwtmouseenter"></div>
            <div class="char2">
              <div style="width: 600px;height: 500px;" ref="echartsContainer2"></div>
              <p style="color: #ff6600;padding: 0 20px;width: 100%;box-sizing: border-box;">
                超越蓝线，意味着你倾向此玩家类型，超出越多，表示你对这些特质的偏好越强</p>
            </div>

          </div>

        </div>

        <div class="topbar-menu" v-else style="text-align: center;">
          <img src="../assets/images/navIcon_5.png" alt="" title="" @click="handleRole('ZZZ')">



        </div>
        <div class="topbar-user">
          <!-- 北京时间 -->
          <!-- <div class="BJtime">{{ BJtime }}</div> -->
          <!-- <router-link to="/myCourses">我的课程</router-link>
        
        <router-link to="/examination">考试中心</router-link> -->
          <!-- <router-link v-if="userinfo.groupType!=0" to="/myBadge">我的成就</router-link> -->
          <!-- <router-link v-if="userinfo.groupType!=0" to="/rankingList" >我的排行榜</router-link> -->
          <!-- <router-lin :to="item.to"
            @click.native="checkoutRouter(index)" :class="current_Router === index ? 'routerActive' : ''"
            v-show="item.show">
            {{ item.title }}
          </router-link> -->
          <!-- <router-link>答题汇总</router-link> -->
          <a @click="yxpz" class="yxpz"
            v-if="userinfo.openConfigElement && userinfo.groupType != '1' && userinfo.groupType != '0'">游戏教程&游戏配置</a>
          <router-link v-for="(item, index) in routerList" :key="index" :to="item.to"
            @click.native="checkoutRouter(index)" :class="current_Router === index ? 'routerActive' : ''"
            v-show="item.show">
            {{ item.title }}
          </router-link>
          <el-dropdown class="dropdown" @command="handleUser">
            <div class="userbox">
              <el-avatar class="avatar" size="small" :src="avatar
                ? avatar
                : 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
                ">
              </el-avatar>
              <div style="color:#FFFFFF;margin-right: 10px;" href="javascript:;" v-if="username">{{ username }}</div>

              <i style="opacity: 0.75;" class="el-icon-caret-bottom el-icon--right"></i>

              <el-dropdown-menu slot="dropdown" style="margin-top: 30px">
                <router-link to="userInfo" @click.native="handleUserSettings()">
                  <el-dropdown-item icon="el-icon-setting">{{ $t("language.UserSettings.UserSettings")
                  }}</el-dropdown-item>
                </router-link>
                <!-- <el-dropdown-item icon="el-icon-info" >切换语言</el-dropdown-item> -->
                <el-dropdown-item command="out" icon="el-icon-switch-button">{{ $t("language.login.logout")
                }}</el-dropdown-item>
              </el-dropdown-menu>

            </div>
          </el-dropdown>

          <!-- <el-dropdown

            class="dropdown"
            @command="handleLang"
          >
            <img
              style="
                width: 30px;


                position: absolute;
                top: -15px;
                left: 30px;


              "
              src="../assets/images/lang.png"
              alt=""
            />

            <el-dropdown-menu slot="dropdown" style="margin-top: 30px">
              <el-dropdown-item command="chinese">{{
                $t("language.login.chinese")
              }}</el-dropdown-item>
              <el-dropdown-item command="english">{{
                $t("language.login.english")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
    </div>
    <el-dialog title="配置游戏元素" :visible.sync="dialogSetting">
      <el-form>
        <div class="isbtn">
          <span>挑战1【简单】: </span>
          <el-switch v-model="autonomousButton.challenge1" :active-value="1" :inactive-value='0'>
          </el-switch>
          <span class="gzclass">{{ getYXlist.challenge1 }}</span>
        </div>
        <div class="isbtn">
          <span>挑战2【一般】: </span>
          <el-switch v-model="autonomousButton.challenge2" :active-value="1" :inactive-value='0'>
          </el-switch>
          <span class="gzclass">{{ getYXlist.challenge2 }}</span>
        </div>
        <div class="isbtn">
          <span>挑战3【困难】: </span>
          <el-switch v-model="autonomousButton.challenge3" :active-value="1" :inactive-value='0'>
          </el-switch>
          <span class="gzclass">{{ getYXlist.challenge3 }}</span>
        </div>
        <div class="isbtn">
          <span>我的等级: </span>
          <el-switch v-model="autonomousButton.myLevel" :active-value="1" :inactive-value='0'>
          </el-switch>
          <span class="gzclass">{{ getYXlist.myLevel }}</span>
        </div>
        <div class="isbtn">
          <span>团队任务1: </span>
          <el-switch v-model="autonomousButton.teamTask1" :active-value="1" :inactive-value='0'>
          </el-switch>
          <span class="gzclass">{{ getYXlist.teamTask1 }}</span>
        </div>
        <div class="isbtn">
          <span>团队任务2: </span>
          <el-switch v-model="autonomousButton.teamTask2" :active-value="1" :inactive-value='0'>
          </el-switch>
          <span class="gzclass">{{ getYXlist.teamTask2 }}</span>
        </div>

        <div class="isbtn">
          <span>探索天地: </span>

          <el-switch v-model="autonomousButton.explorerList" :active-value="1" :inactive-value='0'>
          </el-switch>
          <span class="gzclass">{{ getYXlist.explorerList }}</span>
        </div>
        <div class="isbtn">
          <span>积分实时排行榜: </span>
          <el-switch v-model="autonomousButton.pointRank" :active-value="1" :inactive-value='0'>
          </el-switch>
          <span class="gzclass">{{ getYXlist.pointRank }}</span>
        </div>
        <div class="isbtn">
          <span>我要提问: </span>
          <el-switch v-model="autonomousButton.topicProposal" :active-value="1" :inactive-value='0'>
          </el-switch>
          <span class="gzclass">{{ getYXlist.topicProposal }}</span>
        </div>
        <div class="zggg" v-html="getYXlist.ALL"></div>
        <div class="sp" style="width: 100%;">
          <!-- <span style="color: #fff;"> {{ userinfo.videoType ? '视频1' : '视频2' }}</span> -->
          <img style="width: 100%;" v-if="userinfo.videoType" src="../assets/vido1.png" alt="">
          <img style="width: 100%;" v-else src="../assets/vido2.png" alt="">
        </div>



      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSetting = false">取 消</el-button>
        <el-button type="primary" @click="savebutoom">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="游戏简介" width="1200px" :visible.sync="yxdia" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false">
      <div class="diamain">
        <div class="diaindex" v-if="diaindex == 1">
          <!-- <h3>视频</h3> -->
          <p style="color: #ff6600;"> 以下为系统使用教程，更多细节请点击【游戏教程&游戏配置】查看</p>
          <div class="sp">
            <!-- <span style="color: #fff;"> {{ userinfo.videoType ? '视频1' : '视频2' }}</span> -->
            <img v-if="userinfo.videoType" src="../assets/vido1.png" alt="">
            <img v-else src="../assets/vido2.png" alt="">
          </div>

          <!-- {{ roleData.TZZ.id }} -->
        </div>
        <div class="diaindex" v-if="diaindex == 2">
          <h3>配置游戏元素</h3>
          <el-form>
            <div class="isbtn">
              <span>挑战1【简单】: </span>
              <el-switch v-model="autonomousButton.challenge1" :active-value="1" :inactive-value='0'>
              </el-switch>

              <span class="gzclass">{{ getYXlist.challenge1 }}</span>
            </div>
            <div class="isbtn">
              <span>挑战2【一般】: </span>
              <el-switch v-model="autonomousButton.challenge2" :active-value="1" :inactive-value='0'>
              </el-switch>
              <span class="gzclass">{{ getYXlist.challenge2 }}</span>
            </div>
            <div class="isbtn">
              <span>挑战3【困难】: </span>
              <el-switch v-model="autonomousButton.challenge3" :active-value="1" :inactive-value='0'>
              </el-switch>
              <span class="gzclass">{{ getYXlist.challenge3 }}</span>
            </div>
            <div class="isbtn">
              <span>我的等级: </span>
              <el-switch v-model="autonomousButton.myLevel" :active-value="1" :inactive-value='0'>
              </el-switch>
              <span class="gzclass">{{ getYXlist.myLevel }}</span>
            </div>
            <div class="isbtn">
              <span>团队任务1: </span>
              <el-switch v-model="autonomousButton.teamTask1" :active-value="1" :inactive-value='0'>
              </el-switch>
              <span class="gzclass">{{ getYXlist.teamTask1 }}</span>
            </div>
            <div class="isbtn">
              <span>团队任务2: </span>
              <el-switch v-model="autonomousButton.teamTask2" :active-value="1" :inactive-value='0'>
              </el-switch>
              <span class="gzclass">{{ getYXlist.teamTask2 }}</span>
            </div>

            <div class="isbtn">
              <span>探索天地: </span>

              <el-switch v-model="autonomousButton.explorerList" :active-value="1" :inactive-value='0'>
              </el-switch>
              <span class="gzclass">{{ getYXlist.explorerList }}</span>
            </div>
            <div class="isbtn">
              <span>积分实时排行榜: </span>
              <el-switch v-model="autonomousButton.pointRank" :active-value="1" :inactive-value='0'>
              </el-switch>
              <span class="gzclass">{{ getYXlist.pointRank }}</span>
            </div>
            <div class="isbtn">
              <span>我要提问: </span>
              <el-switch v-model="autonomousButton.topicProposal" :active-value="1" :inactive-value='0'>
              </el-switch>
              <span class="gzclass">{{ getYXlist.topicProposal }}</span>
            </div>





          </el-form>
        </div>
        <!-- <div class="diaindex" v-if="diaindex == 2">
          <h3>{{ roleData[diaindex - 2].roleName }}</h3>
          <div class="role-introduce" v-html="roleData[diaindex - 2].rule"></div>
        </div>
        <div class="diaindex" v-if="diaindex == 3">
          <h3>{{ roleData[diaindex - 2].roleName }}</h3>
          <div class="role-introduce" v-html="roleData[diaindex - 2].rule"></div>
        </div>
        <div class="diaindex" v-if="diaindex == 4">
          <h3>{{ roleData[diaindex - 2].roleName }}</h3>
          <div class="role-introduce" v-html="roleData[diaindex - 2].rule"></div>
        </div>
        <div class="diaindex" v-if="diaindex == 5">
          <h3>{{ roleData[diaindex - 2].roleName }}</h3>
          <div class="role-introduce" v-html="roleData[diaindex - 2].rule"></div>
        </div>
        <div class="diaindex" v-if="diaindex == 6">
          <h3>{{ roleData[diaindex - 2].roleName }}</h3>
          <div class="role-introduce" v-html="roleData[diaindex - 2].rule"></div>
        </div> -->
        <!-- <div class="diaindex" v-if="diaindex == 2">
          <h3>蛛网图介绍</h3>
        </div> -->
      </div>
      <div slot="footer" class="dialog-footer">

        <!-- <el-button @click="dialogSetting = false">取 消</el-button> -->
        <el-button v-if="diaindex != 1" type="primary" @click="diaindex--">上 一 页</el-button>
        <el-button v-if="diaindex != 2" type="primary" @click="diaindex++">下 一 页</el-button>
        <el-button v-if="diaindex == 2" type="primary" @click="yyd">已 阅</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getrole, findSubjectListByFkId, submitCdmSubjectAnswer, getAutonomousButton,
  saveOrUpdateAutonomousButton, modifyReadIntroduce, tHanldeLog, addCountByType, getYX
} from "@/service/roleApi"
import { setToken, setCookie, getCookie } from "@/service/lib/localToken";
import { getSystemTime } from "@/service/userapi";
import { parseTime } from '@/utlis/date'
import { addVisitPageLog } from "@/service/record";
import dayjs from "dayjs";
import * as echarts from 'echarts';
export default {
  name: "nav-header",
  data() {
    return {
      diaindex: 1,
      yxdia: false,
      roleData: {},
      routerList: [
        { title: "答题汇总", to: '/myCourses/MyCoursesExaminationDetail3', show: true },
        { title: "我的课程", to: '/myCourses', show: true },
        { title: "考试中心", to: '/examination', show: true },
        { title: "积分明细", to: '/PointsDetails', show: true },
        // { title: "我的成就",to:'/myBadge',show:true },
        // { title: "我的排行榜",to:'/rankingList',show:true }
      ],
      current_Router: 1,
      username: JSON.parse(getCookie("userdata")).name,
      avatar: JSON.parse(getCookie("userdata")).picturePath,
      userinfo: JSON.parse(getCookie("userdata")),
      yMd: "",
      times: "",
      BJtime: "",
      dialogSetting: false,
      xgqautonomousButton: null,
      autonomousButton: {
        challenge1: 1,
        challenge2: 1,
        challenge3: 1,
        explorerList: 1,
        myLevel: 1,
        pointRank: 1,
        teamTask1: 1,
        teamTask2: 1,
        teamTask1: 1,
        teamTask2: 1,
        topicProposal: 1,

      },
      answerParams: {

        studentId: JSON.parse(getCookie("userdata")).id,
        studentName: JSON.parse(getCookie("userdata")).name,
        list: [],
      },
      option: {
        title: {
          text: ''
        },
        grid: {
          left: '3%',
          right: '8%',
          button: '3%',
          top: '10%',
          containLabel: true
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '', max: 1 },
            { name: '', max: 1 },
            { name: '', max: 1 },
            { name: '', max: 1 },
            { name: '', max: 1 }
          ]
        },
        series: [
          {
            name: '',
            type: 'radar',
            data: [
              {
                value: [1, 1, 1, 1, 1, 1],
                name: '',
                lineStyle: {
                  color: 'red'
                },
                itemStyle: {
                  color: '#f56c6c'
                },
              },

            ]
          }
        ]
      },
      option2: {
        title: {
          text: ''
        },
        grid: {
          left: '3%',
          right: '8%',
          button: '3%',
          top: '10%',
          containLabel: true
        },
        radar: {
          name: {
            show: true,
            formatter: null,
            textStyle: {
              //设置颜色
              color: '#ff6600',
              fontSize: 24,
              fontWeight: 'bold'
            }
          },
          // shape: 'circle',
          indicator: [
            { name: '挑战者', max: 1 },
            { name: '合作者', max: 1 },
            { name: '竞争者', max: 1 },
            { name: '探索者', max: 1 },
            { name: '自主者', max: 1 }
          ]
        },
        series: [
          {
            name: '',
            type: 'radar',
            areaStyle: {
              // 填充区颜色
              color: 'gulr',
            },
            data: [
              {
                value: [1, 1, 1, 1, 1, 1],
                name: '',
                lineStyle: {
                  color: 'red'
                },
                itemStyle: {
                  color: '#f56c6c'
                },
              },
              {
                value: [0.5, 0.5, 0.5, 0.5, 0.5],
                name: '',
                lineStyle: {
                  color: '#1d7be3'
                },
                areaStyle: {
                  color: "rgba(123, 255, 74, .0)",
                },
                itemStyle: {
                  color: '#1d7be3',

                }
              },
            ]
          }
        ]
      },
      getYXlist: {}
    };
  },
  computed: {},
  mounted() {

    this.getYX()
    if (this.userinfo.groupType == 0) {
      this.routerList[2].show = false
      this.routerList[3].show = false
    }
    getAutonomousButton(this.answerParams.studentId).then(res => {
      if (res.data) {
        this.xgqautonomousButton = { ...res.data }
        this.autonomousButton = res.data
      }
    })
    const chartContainer = document.getElementById('chartContainer');
    console.log('this.userinfo1', this.userinfo);
    if (this.userinfo.readIntroduce == 0) {
      debugger
      this.yxdia = true
    } else {
      this.yxdia = false
    }



    this.SystemTime();
    this.getData()

    // 蛛网图
    if(this.$refs.echartsContainer && this.$refs.echartsContainer2){
      var myChart = echarts.init(this.$refs.echartsContainer);
      var myChart2 = echarts.init(this.$refs.echartsContainer2);
      let oparr = [this.userinfo.challengerZ, this.userinfo.collaboratorZ, this.userinfo.competitorZ, this.userinfo.explorerZ, this.userinfo.autonomousZ]
      this.option.series[0].data[0].value = oparr
      this.option2.series[0].data[0].value = oparr
      myChart.setOption(this.option)
      myChart2.setOption(this.option2)
    }

  },
  methods: {
    getYX() {
      let postData = {
        page: 1,
        pageSize: 10
      };
      getYX(postData).then(res => {

        // this.rolearr = res.data.list;
        let obj = {}
        res.data.list.forEach(item => {
          obj[item.type] = item.rule
        })
        console.log('res', obj);
        this.getYXlist = obj
      });
    },
    zwtmouseenter() {
      let putData = {
        studentId: this.answerParams.studentId,
        type: 1
      }
      addCountByType(putData)
    },
    yyd() {
      let isb = false
      if (this.autonomousButton.challenge1 || this.autonomousButton.challenge2 || this.autonomousButton.challenge3
        || this.autonomousButton.explorerList || this.autonomousButton.myLevel || this.autonomousButton.pointRank
        || this.autonomousButton.teamTask1 || this.autonomousButton.teamTask2 || this.autonomousButton.topicProposal) {
        isb = true
      }


      if (!isb) {
        this.$message({
          message: '请至少选择一项游戏元素',
          type: 'warning'
        });
        return
      }
      let postdata = {
        studentId: this.answerParams.studentId,
        readIntroduce: '1'
      }
      modifyReadIntroduce(postdata).then(res => {
        this.yxdia = false
        this.userinfo.readIntroduce = 1
        setCookie('userdata', JSON.stringify(this.userinfo));
        this.savebutoom()
      })


    },
    getData() {
      getrole().then(res => {

        // let roleData = {}
        // res.data.forEach(item => {
        //   roleData[item.roleCode] = item
        // })
        this.roleData = res.data

        // this.getTrainCultivate();

      })
    },
    savebutoom() {
      let isb = false
      if (this.autonomousButton.challenge1 || this.autonomousButton.challenge2 || this.autonomousButton.challenge3
        || this.autonomousButton.explorerList || this.autonomousButton.myLevel || this.autonomousButton.pointRank
        || this.autonomousButton.teamTask1 || this.autonomousButton.teamTask2 || this.autonomousButton.topicProposal) {
        isb = true
      }


      if (!isb) {
        this.$message({
          message: '请至少选择一项游戏元素',
          type: 'warning'
        });
        return
      }
      let postData = { ...this.autonomousButton }
      postData.studentId = this.answerParams.studentId
      postData.studentName = this.answerParams.studentName
      postData.createBy = this.answerParams.studentName
      postData.createTime = dayjs(new Date()).format('YYYYMMDDHHmmss');

      let xgpostData = {
        updateBefore: JSON.stringify(this.xgqautonomousButton),
        updateAfter: JSON.stringify(this.autonomousButton),
        createBy: this.answerParams.studentId,
        createName: this.answerParams.studentName,
        createTime: dayjs(new Date()).format('YYYYMMDDHHmmss'),
        updateStudentId: this.answerParams.studentId,
        updateStudentName: this.answerParams.studentName

      };


      saveOrUpdateAutonomousButton(postData).then(res => {
        tHanldeLog(xgpostData).then(res => {

        })
        this.$message({
          message: '操作成功,即将自动刷新',
          type: 'success'
        });
        this.dialogSetting = false
        // location.reload();
        setTimeout(function () {
          location.reload()
        }, 3000);
      })
    },
    yxpz() {
      getAutonomousButton(this.answerParams.studentId).then(res => {

        if (res.data) {
          this.xgqautonomousButton = { ...res.data }
          this.autonomousButton = res.data

        }

        this.dialogSetting = true
        let putData = {
          studentId: this.answerParams.studentId,
          type: 2
        }
        addCountByType(putData)
      })
    },
    handleRole(item) {
      this.$router.push({
        path: "/RoleIntroduction",
        query: {
          roleName: item,



        },
      });
    },
    handleUserSettings() {

      this.current_Router = -1

    },
    checkoutRouter(index) {

      this.current_Router = index;



    },
    SystemTime() {
      getSystemTime().then((res) => {
        if (res.code == "000") {
          let date = res.data.replace(/\-/g, '/')
          this.test(new Date(date));
        }
      });
    },

    test(date) {
      // 首先肯定要有一个传入的形参(date)用来初始化 这个date就是你接口获取的初始时间
      // 其二我们肯定需要计时器setInterval 给它每秒执行一次 这样我们的数据才会一直走动
      setInterval(() => {
        // addtime 定义的一个变量 用来接收自动累加的时间 date.setSeconds()这个方法作用就是设置秒的时间
        // let addtime = new Date(date.setSeconds(date.getSeconds() + 1));
        this.BJtime = parseTime(date.setSeconds(date.getSeconds() + 1))
        // console.log( parseTime(date.setSeconds(date.getSeconds() + 1)))
        // // yMd 是我在data 定义用来接收年月日的变量 .getFullYear() .getMonth() .getDate()这三个方法都是用来转换日期格式
        // this.yMd =
        //   addtime.getFullYear() +
        //   "-" +
        //   (addtime.getMonth() + 1).toString().padStart(2, "0") +
        //   "-" +
        //   addtime.getDate().toString().padStart(2, "0");
        // // times 是我在data 定义用来接收时分秒的变量 .getHours() .getMinutes() .getSeconds()这三个方法都是用来转换日期格式
        // this.times =
        //   addtime.getHours().toString().padStart(2, "0") +
        //   ":" +
        //   addtime.getMinutes().toString().padStart(2, "0") +
        //   ":" +
        //   addtime.getSeconds().toString().padStart(2, "0");
        /*
          最后你会发现为什么它们要用toString()和padStart()这两个方法呢
            因为.padStart()这个方法是Es6字符串中的方法其作用就是用来判断当前字符串有几个 我们这种写法就是.padStart(2,'0')如果它有两个就不加0 没有就自动补充0 这样在页面上也好看一点
              大家都知道js是弱类型语音 它们在换算时可能会隐式转换 所以我建议大家这种时候还是手动转换一下比较好
        */
        // this.BJtime = this.yMd+ '  '+  addtime.getHours().toString().padStart(2,'0') + ':' + addtime.getMinutes().toString().padStart(2,'0') + ':' + addtime.getSeconds().toString().padStart(2,'0')
      }, 1000);
    },

    userout() {
      setToken("");
      setCookie("userdata", "");
      this.$router.push({
        path: "/login",
        params: {
          from: "login",
        },
      });
    },
    //切换语言
    handleLang(command) {
      if (command == "chinese") {
        this.$i18n.locale = "zh-CN";
        window.sessionStorage.setItem("lang", this.$i18n.locale);
      }
      if (command == "english") {
        this.$i18n.locale = "en-US";
        window.sessionStorage.setItem("lang", this.$i18n.locale);
      }
    },
    handleUser(command) {

      if (command == "out") {
        this.$confirm("是否确认退出?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let addData = {
              actionType: 1,
              pageName: this.$route.name + '(用户登出)',
              pageUrl: this.$route.path,
              userId: this.userinfo.id,
              username: this.userinfo.account
            }
            addVisitPageLog(addData).then(res => {

            })

            this.userout();
          })
          .catch(() => { });
      }
    },
  },
};
</script>
<style lang="scss">
.role-introduce {
  width: 100%;
  overflow: hidden;

  img {
    width: 100% !important;
  }
}
</style>
<style lang="scss" scoped>
@import "/src/assets/scss/base.scss";
@import "/src/assets/scss/mixin.scss";
@import "/src/assets/scss/config.scss";

.isbtn {
  margin-top: 20px;
  font-size: 18px;
}

.yxpz {
  cursor: pointer;
}

.header {
  .routerActive {
    font-size: 18px;
  }

  .nav-topbar {
    height: 260px;
    line-height: 39px;
    background: url('../assets/images/navheader_bg.png');
    background-size: 100% 100%;
    color: #b0b0b0;

    .container {
      height: 260px;
      line-height: 39px;
      @include flex();

      .topbar-menu {
        margin-bottom: 50px;
        position: relative;

        p {
          width: 100%;
          font-size: 24px;
          text-align: center;
          color: #d7910d;
          font-weight: bold;
        }

        img {
          cursor: pointer;
        }
      }

      .topbar-user {
        margin-bottom: 50px;
        @include flex();

        .BJtime {
          margin-right: 50px;
        }

        .userbox {
          @include flex();
          background: rgba(255, 255, 255, 0.2);
          border-radius: 26px;
          padding: 4px 10px 4px 10px;
          cursor: pointer;

          .avatar {
            height: 44px;
            width: 44px;
            margin: 0 10px 0 0px;

          }

          .el-icon--right {
            // cursor: pointer;
            color: #ffffff;
            // position: absolute;
          }
        }

      }
    }

    a {
      display: inline-block;

      color: #FFFFFF;

      margin-right: 82px;
      text-decoration: none;
    }

    .my-cart {
      width: 110px;
      background-color: #ff6600;
      text-align: center;
      color: #ffffff;
      margin-right: 0;
    }

    .icon-cart {
      margin-right: 4px;
    }
  }

  .char2 {
    width: 600px;
    height: 600px;
    background: #FFFFFF;
    position: absolute;
    box-shadow: 0 5px 11px 0 rgba(2, 15, 58, 0.79);
    top: -50px;
    right: -400px;
    display: none;
    // background-color: #000;
    z-index: 999;

    &:hover {
      display: block;
    }
  }

  #chartContainer {
    width: 240px;
    height: 240px;
    position: absolute;
    right: -220px;
    top: -70px;

    &:hover+.char2 {

      display: block !important;

    }

  }

  .zggg {
    width: 100%;
    margin-top: 20PX;
  }

  .gzclass {
    margin-left: 20px;
    font-size: 12px;
  }

  .diaindex {
    h3 {
      width: 100%;
      text-align: center;
    }

    .sp {
      width: 100%;
      // height: 500px;
      background: #333;

      img {
        width: 100%;
      }
    }

    p {
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
      font-size: 24px;
      color: #666666;
    }
  }
}
</style>
