import { render, staticRenderFns } from "./groupMember2.vue?vue&type=template&id=54e55884&scoped=true&"
import script from "./groupMember2.vue?vue&type=script&lang=js&"
export * from "./groupMember2.vue?vue&type=script&lang=js&"
import style0 from "./groupMember2.vue?vue&type=style&index=0&id=54e55884&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e55884",
  null
  
)

export default component.exports