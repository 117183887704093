/**
 * 权限验证
 * @author LiQingSong
 */
import router from './index';
// import store from '@/store';
// import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
// import { siteTitle, siteLoginRouter, serverLoginUrl } from '@/settings';
// import { isExternal } from '@/utlis/validate';
// import { asyncRoutes } from '@/router';
// import { getcook,setcook} from "@/service/lib/localToken";
// NProgress.configure({ showSpinner: false }); // NProgress Configuration
import { addVisitPageLog } from "@/service/record";
// 不验证重定向的白名单
// const whiteList = [
//     siteLoginRouter
// ];
import {  getCookie } from "@/service/lib/localToken";
router.beforeEach((to, from, next) => {
    
    
    // // 判断该路由是否需要登录权限
    // if (requireAuth) {
    //   if (window.sessionStorage.getItem('login')) {
    //     next()
    //   } else {
    //     next('/login')
    //   }
    // } else {
    //   next()  // 确保一定要有next()被调用
    // }
  
    // start progress bar
    // return
    console.log('路由跳转')
    console.log(to)
    console.log(from)
    NProgress.start();
    // console.log(to);
    let  userinfo= getCookie("userdata") 
    // console.log('userinfo',userinfo);
    
    if(userinfo){
        // 判断有信息 才记录日志
    
        userinfo =  JSON.parse(userinfo)
       
        let addData2= {
            actionType:2,
            pageName:from.name,
            pageUrl:from.path,
            userId:userinfo.id,
            username:userinfo.account
        }
        console.log(to)
        if(addData2.pageName){
        
            addVisitPageLog(addData2).then(res => {
             
            })
        }
        let addData= {
            actionType:1,
            pageName:to.name,
            pageUrl:to.path,
            userId:userinfo.id,
            username:userinfo.account
        }
        
        addVisitPageLog(addData).then(res => {
         
        })
    
      
     
    }
    // 设置网页 title
    // document.title = (to.meta && to.meta.title) + ' - ' + siteTitle;
    next();
    // if (whiteList.indexOf(to.path) !== -1) {
    //     // 在白名单中，直接进入
    //     next();
    // } else {

    //     let hasRoles ='';
    //     // if (store.state.permission.routes.length !==0){
    //     //     hasRoles = store.state.permission.routes;
    //     // }
    //     // console.log(to);
    //     // console.log(store.dispatch('permission/getdata'));
    //     // console.log(store.state.permission.routes);
    //     // let hasRoles = store.state.permission.routes;
    //     // const hasRoles = false;
    //     if (hasRoles) {
    //         next();
    //     } else {
    //         try {
    //             // if( getcook('userType') === 'T'){
    //             //     setcook('roles','teacher');
    //             // }else{
    //             //     setcook('roles','admin');
    //             // }
    //             // 获取用户角色权限
    //             // 注意:角色必须是一个对象数组! 例如: ['admin'] or ,['test','edit']

    //             // const roles = getcook('roles');

    //             // console.log(asyncRoutes);

    //             // const accessRoutes = [];

    //             // asyncRoutes.forEach(route => {
    //             //     const tmp = { ...route };

    //             //     tmp.children = [];
    //             //     if(route.children){
    //             //         route.children.forEach(item => {

    //             //            if(item.meta.roles.includes(roles)){
    //             //                tmp.children.push(item);
    //             //            }
    //             //         });
    //             //     }

    //             //     accessRoutes.push(tmp);
    //             // });
    //             // console.log(accessRoutes);
    //             // // 根据角色生成可访问路由映射
          
    //             // router.addRoutes(accessRoutes);
    //             // store.dispatch('permission/setdata', accessRoutes);
          
    //             next({...to,replace: true});
              


    //         } catch (error) {
    //             // 删除Token
    //             //  store.dispatch('user/resetToken');

    //             Message.error(error || 'Has Error');

    //             // 跳转到登录
    //             if (isExternal(serverLoginUrl)) {
    //                 window.location.href = serverLoginUrl;
    //             } else {
    //                 next(siteLoginRouter + "?redirect=" + to.fullPath);
    //             }
    //             NProgress.done();
    //         }
    //     }
    // }
});

router.afterEach((to, from, next) => {
    // console.log('进入')

    // console.log(to)
    // let  userinfo= getCookie("userdata") 

    // if(userinfo){
    //     // 判断有信息 才记录日志
    //     userinfo =  JSON.parse(userinfo)
    //     console.log('记录日志')
    //     let addData= {
    //         actionType:1,
    //         pageName:to.name,
    //         pageUrl:to.path,
    //         userId:userinfo.id,
    //         username:userinfo.account
    //     }
    //     addVisitPageLog(addData).then(res => {
    //         console.log(res)
    //         console.log('记录成功')
    //     })
    // }
    NProgress.done();
 
});

// beforeRouteLeave((to, from) => {
//     console.log('页面离开')
//     console.log(to)
//     let  userinfo= getCookie("userdata") 

//     if(userinfo){
//         // 判断有信息 才记录日志
//         userinfo =  JSON.parse(userinfo)
//         console.log('记录日志')
//         let addData= {
//             actionType:2,
//             pageName:to.name,
//             pageUrl:to.path,
//             userId:userinfo.id,
//             username:userinfo.account
//         }
//         addVisitPageLog(addData).then(res => {
//             console.log(res)
//             console.log('记录成功')
//         })
//     }
    
 
// });