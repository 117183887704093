<template>
  <div class="search-input">
    <el-input
      v-model="searchValue"
      slot="reference"
      class="search"
      :placeholder="placeholder"
      @keyup.enter.native="doInputSelect"
    >
      <i
        slot="suffix"
        class="el-icon-search search-ico search"
        @click="doInputSelect"
      ></i>.nav-topbar
    </el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchValue: "",
    };
  },
  watch: {},

  props: {
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: 20,
    },
  },
  watch: {},
  methods: {
    doInputSelect() {
      this.$emit("toSearch", this.searchValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  margin: 15px 0;
  display: flex;
  justify-content: flex-end;
  ::v-deep {
    .search input {
      width: 320px;
      height: 32px;
      background: #f5f5f5;
      border-radius: 16px;
      padding-left: 15px;
    }
    .el-input__suffix {
      height: 100%;
      position: absolute;
      right: 15px;
      display: flex;
      align-items: center;
    }
  }
}

.search {
  position: relative;
  cursor: pointer;
}
</style>
