import request from '@/service/lib/request';
import request2 from '@/service/lib/request2';

// 查询考试题目
export function findSubjectListByFkId (params) {

    return request({
        url: 'module/tProjectCourseSubject/findSubjectListByFkId',
        method: 'get',
        params
    });
}
//我的课程-提交答案
export function addStudentSubjectRecord (data) {

    return request({
        url: 'module/tProjectStudentSubject/addStudentSubjectRecord',
        method: 'post',
        data
    });
}
//公共试卷-提交答案
export function addStudentTestRecord (data) {

    return request({
        url: 'module/tProjectStudentTest/addStudentTestRecord',
        method: 'post',
        data
    });
}
//课后答题-提交答案
export function submitExercises (data) {

    return request({
        url: 'module/studentExercise/submitExercises',
        method: 'post',
        data
    });
}
//公共试卷获取用户历史回答
export function queryStudentTestByParam (params) {

    return request({
        url: 'module/tProjectStudentTest/queryStudentTestByParam',
        method: 'get',
        params
    });
}
//课后答题历史
export function findStudentExerciseRecordPage (data) {

    return request({
        url: 'module/studentExercise/findStudentExerciseRecordPage',
        method: 'post',
        data
    });
}