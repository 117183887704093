import request from '@/service/lib/request';


//学员和课时获取积分明细列表
export function findIntegralDetailBy (data) {

    return request({
        url: 'module/tProjectIntegralDetail/findIntegralDetailBy',
        method: 'post',
        data
    });
}
// 查询总积分排行
export function querySumTotalIntegral (params) {

    return request({
        url: 'module/tMidStudentHour/querySumTotalIntegral',
        method: 'get',
        params

    });
}
//分页查询学员课时关联列表(包含学员姓名与头像)
export function findMidListByPage (data) {

    return request({
        url: 'module/tMidStudentHour/findMidListByPage1',
        method: 'post',
        data
    });
}
