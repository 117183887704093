<template>
  <div class="classDiscussion">
    <div class="container">
      <div class="classHour-title">

        <el-button class="title-bottom" type="info" icon="el-icon-back" circle @click="$router.go(-1)"></el-button>

        <p class="title-text">{{ this.$route.query.courseName }}</p>
      </div>

      <div class="table-box">

        <div class="table-td" v-for="(item, index) in tableData" :key="index">
          <div>
            <div class="table-imgbox">
              <img v-if="starList.includes(item.prizeType)" src="../../../assets/images/unlock.png" alt="">
              <img v-else src="../../../assets/images/lock.png" alt="">
              <div>
                <div class="table-integral">{{ item.prizeName }}</div>
              </div>
            </div>
          </div>
          <div v-if="starList.includes(item.prizeType)"  class="remark" @click="xz(item)">
            
            下载
          </div>
       
          <div class="remark hui" v-else> 
            <span v-if="item.prizeType == 1">完成课前任务解锁</span>
            <span v-if="item.prizeType == 2">完成课堂任务解锁</span>
            <span v-if="item.prizeType == 3">完成课后任务解锁</span>
            <span v-if="item.prizeType == 4">完成全部任务解锁</span>
            <!-- {{ item.prizeType == 1?'完成课前任务解锁':'完成课堂任务解锁' }} -->
          </div>
        </div>



      </div>

    </div>
  </div>
</template>
  
<script>
import {
  findClassHourExplorePage
} from "@/service/kcApi";
import {findMidListByPage1 } from "@/service/myCourses";

import { getCookie } from "@/service/lib/localToken";
export default {
  name: "rankingList",
  data() {
    return {

      tableData: [

      ],
      parms: {

        hourId: "",
        page: 1,
        pageSize: 999
      },
      boxData: [],
      selectId: 0,
      starList:[],
      courseId:''
    }
  },
  mounted() {
    if (this.$route.query.RouterCourseId) {
      console.log('123', this.$route.query.RouterCourseId);
      this.parms.hourId = this.$route.query.RouterCourseId
      this.courseId = this.$route.query.courseId
      this.handleMyLevel()
  
  
    }

  },
  methods: {
    xz(item){
      console.log(item)
      this.$addLog('ENTER_EXPLORER_DOWNLOAD',this.courseId,item.hourId)
      window.location.href = item.annexUrl;
    },

    handleMyLevel() {
      let postData ={
        hourId:this.parms.hourId,
        studentId:getCookie("userId"),
        page:1,
        pageSize:1
      }
      findMidListByPage1(postData).then(res => {

        console.log(res.data.list[0].preClassStar)
        // 课堂星星
        console.log(res.data.list[0].inClassStar)
        if(res.data.list[0].preClassStar){
          this.starList.push(1)
        }
        if(res.data.list[0].inClassStar){
          this.starList.push(2)
        }
        if(res.data.list[0].postClassStar){
          this.starList.push(3)
        }
        if(res.data.list[0].preClassStar && res.data.list[0].inClassStar && res.data.list[0].postClassStar){
          this.starList.push(4)
        }
        this.init()
  
      })

    },



    init() {
      findClassHourExplorePage(this.parms).then(res => {
        this.tableData = res.data.list
      })
    }
  }
}
</script>
  
<style lang="scss" scoped>
.container {
  .classHour-title {
    position: relative;
    text-align: center;
    margin: 20px 0 20px 0;
    height: 150px;

    .title-bottom {
      position: absolute;
      left: 0;

    }

    .title-text {
      font-size: 32px;
      font-family: Adobe Heiti Std;
      font-weight: bold;

      color: #303133;
      font-size: 32px;


    }

  }

  .el-carousel__item h3 {

    color: #303133;

    font-size: 32px;

    line-height: 150px;
    margin: 0;
  }


  .table-box {


    border: 8px solid #FAD294;
    border-radius: 27px;

    padding: 37px 85px 37px 85px;
    background: url('../../../assets/images/Explorebg.png');
    background-size: cover;

    .table-th {
      height: 68px;



      border-radius: 34px;

      div {
        text-align: center;
        line-height: 68px;
        color: #EB1008;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .table-td {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 50px 0 50px;
      background: rgba(235, 16, 8, 0.05);
      box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.07);

      border-radius: 34px;
      margin-bottom: 20px;

      .table-imgbox {
        display: flex;
        align-items: center;
        padding: 5px 0 2px 0;
        text-align: center;

        color: #4D4D4D;
        font-weight: bold;
        font-size: 18px;

        img {
          margin-right: 25px;
        }
      }

      .table-avatarName {
        padding: 10px 0 10px 0;
        text-align: center;

        color: #4D4D4D;
        font-weight: bold;
        font-size: 18px;

        .box {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .avatar {
            height: 48px;
            width: 48px;
            margin-right: 10px;
          }

          .userName {
            font-size: 14px;

            font-weight: bold;
            color: #4D4D4D;
          }
        }

      }

      .table-integral {
        text-align: center;

        color: #4D4D4D;
        font-weight: bold;
        font-size: 14px;
        padding: 28px 0 29px 0;
      }

      .remark {
        text-align: center;


        color: #FFFFFF;
        cursor: pointer;
        font-size: 14px;

        // width: 100px;  
        padding: 0 20px;
        height: 42px;
        line-height: 42px;
        background: linear-gradient(0deg, #E74C32, #F19826, #F8CA66);
        border-radius: 21px;
      }
      .hui{
        background: #c2bebe;
        color: #333333;
      }
    }

  }

  .search-box {
    position: relative;
    margin: 0 auto;
    width: 1440px;
    padding: 0.520833rem 0;
    border-bottom: 0.052083rem dashed #c3cad6;

    .line {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .label {
        margin: 0 1.041667rem 0 0;
        font-size: 1.041667rem;
        font-weight: bold;
        color: #7c8697;
        line-height: 2.083333rem;
        cursor: default;
      }

      ul {
        display: flex;
      }

      .opt {
        margin-right: 1.145833rem;
        font-size: 1.041667rem;
        font-weight: bold;
        color: #324057;
        line-height: 2.083333rem;
        cursor: pointer;
        transition: 0.5s;

        &.active {
          color: #1d7be3;
          cursor: default;
        }
      }
    }
  }
}
</style>
  