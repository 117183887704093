<template>
  <main class="training">
    <div class="article">
      <el-button class="title-bottom" type="info" icon="el-icon-back" circle @click="$router.go(-1)"></el-button>
      <!-- <div class="title">111</div> -->
      <!-- <div class="time">
    222
      </div> -->
      <!-- <div class="content" v-html="trainQuestions.content">333</div> -->
    </div>
    <div class="training-test" v-if="trainQuestions.length > 0">
      <div class="top-bg"></div>
      <div class="title" style="line-height:30px;padding: 20px ;text-align: left;"
        v-html="swannotation(trainQuestions[activeNum].name)">
        <!-- {{ trainQuestions[activeNum] ? trainQuestions[activeNum].name : "" }} -->
      </div>
      <div class="test-box" v-if="!isTrained">
        <div class="title-line">
          <div class="name">
            <!-- v-show="trainQuestions[activeNum].answer" -->
            <span v-if="trainQuestions[activeNum]">
              <div v-if="trainQuestions[activeNum].studentAnswer">
                <span style="color: #2c7bfd" v-if="trainQuestions[activeNum].answer ==
                  trainQuestions[activeNum].studentAnswer
                  ">
                  本题正确答案为:{{
                    trainQuestions[activeNum].answer
                  }}
                  {{ $t("language.myCourses.AnswerTrue")
                  }}<span v-if="userinfo.groupType != 0 &&
  trainQuestions[activeNum].score != 0
  ">
                    获得相应积分</span></span>

                <span v-else-if="trainQuestions[activeNum].answer !==
                  trainQuestions[activeNum].studentAnswer
                  " style="color: #fd422c">本题正确答案为:{{
    trainQuestions[activeNum].answer
  }}

                  很遗憾您答错了</span>
                  <div >本题解析：
                      <div v-if="trainQuestions[activeNum].subjectAnalysis" v-html="trainQuestions[activeNum].subjectAnalysis"></div>
                      <div v-else>暂无解析</div>
                    <!-- {{ trainQuestions[activeNum].subjectAnalysis ?trainQuestions[activeNum].subjectAnalysis:'暂无解析'}} -->
                  </div>
                <p v-if="activeNum == (trainQuestions.length - 1)">

                  <span>本次测试结束，您一共答对{{ totaldata.num }}题
                    <span v-if="userinfo.groupType != 0">
                      {{ $t("language.myCourses.achieving") }}{{ totaldata.fraction
                      }}{{ $t("language.myCourses.pointsintotal") }}
                    </span>
                  </span>

                </p>
              </div>
            </span>
          </div>
          <div class="pos-count">
            <span>{{ activeNum + 1 }}</span> /
            {{ trainQuestions.length }}
          </div>
        </div>
        <div class="question-box">
          <!-- <div class="left">{{ activeNum + 1 }}</div> -->
          <div class="right">

            <div class="answer" v-for="(item, idx) in JsonsubjectItem" :key="idx">
              <span style="
                  color: rgb(44, 123, 253);
                  font-size: 20px;
                  font-weight: bold;
                  margin-right: 10px;
                " v-if="idx == 0">A</span>
              <span style="
                  color: rgb(44, 123, 253);
                  font-size: 20px;
                  font-weight: bold;
                  margin-right: 10px;
                " v-if="idx == 1">B</span>
              <span style="
                  color: rgb(44, 123, 253);
                  font-size: 20px;
                  font-weight: bold;
                  margin-right: 10px;
                " v-if="idx == 2">C</span>
              <span style="
                  color: rgb(44, 123, 253);
                  font-size: 20px;
                  font-weight: bold;
                  margin-right: 10px;
                " v-if="idx == 3">D</span>
              <el-radio v-model="curAnswerEn" :disabled="!showSubmit" :label="item.value" :class="{
                'el-radio': true,
                showError: showError && item == curAnswerEn,
              }">
                {{ item.label }}
              </el-radio>

            </div>
          </div>
        </div>
        <div class="btns">
          <!-- <el-button
            type="primary"
            size="medium"
            v-if="activeNum > 0"
            :loading="btnLoadings.prev"
            @click="goBack()"
            >上一题</el-button
          > -->
          <el-button type="primary" size="medium" v-if="showSubmit" @click="goSubmit()">提交</el-button>
          <el-button type="primary" size="medium" v-if="activeNum > 0" @click="Previous()">上一题</el-button>
          <el-button type="primary" size="medium" v-if="showNext" @click="goNext()">下一题</el-button>
        </div>
      </div>
      <!-- <div class="btns" v-if="isTrained">
        <el-button type="primary" size="medium" disabled>已完成培训</el-button>
      </div> -->
    </div>
    <div class="no-data" v-else>暂无数据</div>
  </main>
</template>

<script>
import { setToken, setCookie, getCookie } from "@/service/lib/localToken";
import { getSystemTime } from "@/service/userapi";
import {
  findSubjectListByFkId,
  addStudentSubjectRecord,
} from "@/service/examinationDetail";
import { DebounceBy } from "@/utlis/DebounceBy";
export default {
  name: "training",
  async asyncData() { },
  data() {
    return {
      userinfo: JSON.parse(getCookie("userdata")),
      btnLoadings: {
        prev: false,
        next: false,
        finish: false,
      },
      activeNum: 0,
      curAnswerEn: "",
      answers: [],
      showError: false,
      JsonsubjectItem: [],
      fkId: "", //课程ID
      subjectTag: "", //答题类型(0-课前,1-课堂,2-问卷 3-课后)
      studentId: "", //学生ID
      showSubmit: false,
      showNext: false,
      answerParams: {
        answerType: this.$route.query.subjectTag,
        studentId: this.$route.query.studentId,
        hourId: this.$route.query.fkId,
        createBy: JSON.parse(getCookie("userdata")).name,
        list: [
          {
            answer: "",
            subjectId: "",
          },
        ],
      },
      isTrained: false,
      trainQuestions: [],

      params: {},
      // 总分
      totaldata: {
        num: 0,
        fraction: 0,
      },
      start_date: this.$route.query.start_date,
      end_date: this.$route.query.end_date,

    };
  },

  watch: {

    curAnswerEn() {
      this.showError = false;
    },
    activeNum() {
      if (this.trainQuestions[this.activeNum].studentAnswer) {
        this.showNext = true;
        this.showSubmit = false;
        this.curAnswerEn = this.trainQuestions[this.activeNum].studentAnswer;
      } else {
        this.showSubmit = true;
        this.showNext = false;
        this.curAnswerEn = "";
      }
    },
  },
  created() {
    this.fkId = this.$route.query.fkId;
    this.subjectTag = this.$route.query.subjectTag;
    this.studentId = this.$route.query.studentId;
    this.params = {
      fkId: this.$route.query.fkId,
      subjectTag: this.$route.query.subjectTag,
      studentId: this.$route.query.studentId,
    };
  },

  async mounted() {
    console.log("userinfo.groupType", this.userinfo.groupType);

    this.init();
  },
  methods: {
    swannotation(text) {

      return text ? text.replace(/[\n\r]/g, '<br>') : ""
    },
    init() {
      this.getTrainCultivate();
      //   this.findFinishCultivateId();
    },
    // 计算总分
    counttotale() {
      console.log(this.trainQuestions);
      let num = 0;
      let fraction = 0;
      this.trainQuestions.forEach((item) => {
        if (item.answer == item.studentAnswer) {
          num++;
          fraction += item.score;
        }
      });
      console.log(num, fraction);
      this.totaldata.num = num;
      this.totaldata.fraction = fraction;
    },
    async getTrainCultivate() {
      console.log("查询");
      findSubjectListByFkId(this.params).then((res) => {
        console.log("res", res);
        if (res.code == "000" && res.data.length > 0) {
          this.trainQuestions = res.data;
          this.counttotale();
          this.JsonsubjectItem = JSON.parse(
            this.trainQuestions[this.activeNum].subjectItem
          );

          if (this.trainQuestions[this.activeNum].studentAnswer) {
            this.showNext = true;
            this.showSubmit = false;
            this.curAnswerEn =
              this.trainQuestions[this.activeNum].studentAnswer;
          } else {
            this.showSubmit = true;
            this.showNext = false;
          }
          console.log("   this.subjectItem", this.JsonsubjectItem);
        }
      });
    },
    Previous() {
      --this.activeNum;
      console.log(" this.activeNum", this.activeNum);
      this.JsonsubjectItem = JSON.parse(
        this.trainQuestions[this.activeNum].subjectItem
      );
    },
    goNext() {
      if (Number(this.activeNum) < Number(this.trainQuestions.length - 1)) {
        ++this.activeNum;
        console.log(" this.activeNum", this.activeNum);
        this.JsonsubjectItem = JSON.parse(
          this.trainQuestions[this.activeNum].subjectItem
        );
      } else {
        this.$router.push({
          path: "/myCourses",
        });
      }
    },

    estimateTime(start_date, end_date, stamp) {

      //开始时间
      let start = new Date(
        start_date.replace(/-/g, "/").replace(/-/g, "/")
      ).getTime();
      //结束时间
      let end = new Date(
        end_date.replace(/-/g, "/").replace(/-/g, "/")
      ).getTime();
      //当前时间
      stamp = new Date(stamp.replace(/-/g, "/").replace(/-/g, "/")).getTime();
      if (stamp > end) {

        let msg;
        if (this.$i18n.locale == "zh-CN") {
          msg = this.$confirm("对不起，答题时间已结束", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
        } else {
          msg = this.$confirm("Sorry, the quiz is over", {
            confirmButtonText: "confirm",
            cancelButtonText: "cancel",
            type: "warning",
          });
        }
        msg.then(() => {
          this.$router.push({
            path: "/myCourses",
          });
        });

      } else if (start <= stamp && stamp <= end) {
        /*写你自己的业务代码*/
        if (!this.curAnswerEn) {
          if (this.$i18n.locale == "zh-CN") {
            return this.$message({
              message: "请选择答案！",
              type: "warning",
            });
          } else {
            return this.$message({
              message: "PleaseSelectTheAnswer！",
              type: "warning",
            });
          }

        }
        console.log("this.value", this.curAnswerEn);
        this.answerParams.list[0].answer = this.curAnswerEn;
        this.answerParams.list[0].subjectId =
          this.trainQuestions[this.activeNum].id;
        console.log("this.answerParams", this.answerParams);
        addStudentSubjectRecord(this.answerParams).then((res) => {
          console.log("res", res);
          if (res.code == "000" && res.data.length == 0) {
            this.init();
          } else {
            if (this.$i18n.locale == "zh-CN") {
              return this.$message({
                message: "请勿重复作答！",
                type: "warning",
              });
            } else {
              return this.$message({
                message: "Please do not repeat answering！",
                type: "warning",
              });
            }
          }
        });
      }
    },
    goSubmit: DebounceBy(function (value) {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(this.start_date, this.end_date, res.data);
        }
      });

    }, 1000),
    // goSubmit() {
    //   if (!this.curAnswerEn) {
    //     return this.$message({
    //       message: "请选择答案！",
    //       type: "warning",
    //     });
    //   }
    //   this.answerParams.list[0].answer = this.curAnswerEn;
    //   this.answerParams.list[0].subjectId =
    //     this.trainQuestions[this.activeNum].id;
    //   console.log("this.answerParams", this.answerParams);
    //   addStudentSubjectRecord(this.answerParams).then((res) => {
    //     console.log("res", res);
    //     if (res.code == "000") {
    //       this.init();
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.training {
  // background: #fafbfc;
}

.article {
  position: relative;
  margin: 0 auto;
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;
  padding: 2rem 6.25rem;
  background: #fff;
  border-bottom: 0.052083rem solid #c3cad6;

  .title {
    font-size: 1.5625rem;
    font-weight: bold;
    color: #324057;
    line-height: 1.5625rem;
    text-align: center;
  }

  .time {
    margin: 0.9375rem 0 1.458333rem;
    font-size: 14px;
    font-weight: bold;
    color: #c3cad6;
    line-height: 1.5625rem;
    text-align: center;
  }

  .content {
    word-break: break-all;
  }
}

.training-test {
  position: relative;
  margin: 28px auto;
  width: 100rem;
  background: #fff;
  padding-bottom: 1.458333rem;

  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);

  .top-bg {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, #0160A3, #349BE3);
    border-radius: 8px 8px 0 0;
  }

  .title {
    font-size: 1.5625rem;
    font-weight: bold;
    color: #324057;
    line-height: 5.833333rem;
    text-align: center;
  }

  .test-box {
    position: relative;
    margin: 0 24px;
    // border: 1px solid #c3cad6;
    border-radius: 8px;

    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1.875rem;
      // height: 4.583333rem;

      // border-bottom: 0.052083rem solid #c3cad6;
      .name {
        font-size: 20px;
        font-weight: bold;
        color: #324057;
      }

      .pos-count {
        text-align: bottom;
        font-size: 1.041667rem;
        font-weight: bold;
        color: #324057;

        span {
          font-size: 24px;
        }
      }
    }
  }

  .question-box {
    display: flex;
    padding: 36px;
    margin-bottom: 15px;

    background: #F5F7FB;

    .left {
      font-size: 1.25rem;
      font-weight: bold;
      color: #324057;
    }

    .right {
      margin-left: 34px;
      word-wrap: break-word;
      word-break: break-all;
      flex: 1;

      .question {
        padding-bottom: 30px;
        font-size: 20px;
        font-weight: bold;
        color: #324057;
      }

      .answer {
        margin-bottom: 20px;
        border-bottom: 1px dashed #D9D9D9;
        padding-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .el-radio {
          word-wrap: break-word;
          word-break: break-all;
          white-space: normal;
          font-size: 1.041667rem;

          &.showError {
            ::v-deep {
              .el-radio__label {
                color: #d52c0b;
              }
            }
          }
        }
      }
    }
  }

  .btns {
    padding-bottom: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-button {
      border-radius: 8px;
      margin: 0 12px;
      box-sizing: border-box;
      width: 120px;
      height: 48px;
    }

    .el-button--primary {
      color: #fff;
    }

    .el-button--primary.is-active,
    .el-button--primary:active {
      background: #3a8ee6;
      border-color: #3a8ee6;
      color: #fff;
    }
  }
}

.no-data {
  min-height: 50vh;
  margin: auto;
  box-sizing: border-box;
  padding-top: 10%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

::v-deep {
  .el-radio__label {
    color: #959dab;
    line-height: 1.5;
    font-size: 18px !important;
  }
}</style>
