<template>
  <div class="integralTable">
      <div class="container">
             <el-table
      :data="tableData"
          border
      style="width: 100%">
      <el-table-column
        prop="createTime"
        :label="$t('language.myCourses.Dates')"
        width="180">
          <template slot-scope="scope">

        <span  >{{ formatDate(scope.row.createTime) }}</span>
      </template>
      </el-table-column>
      <el-table-column
        prop="detailDescribe"
        :label="$t('language.myCourses.Descriptions')"
        >
            <template slot-scope="scope">
            <span v-if="$i18n.locale == 'zh-CN'">{{scope.row.detailDescribe}}</span>
                  <span v-else>{{scope.row.detailDescribe2}}</span>
          </template>
      </el-table-column>
      <el-table-column
        prop="numberTotal"
         :label="$t('language.myCourses.sum')"
        width="180"
        >
           <template slot-scope="scope">

        <span  v-if=" scope.row.type==0">+{{  scope.row.numberTotal}}</span>
         <span  v-else>-{{  scope.row.numberTotal}} {{$t("language.myCourses.coin")}}，-{{$t("language.myCourses.zerointegral")}}</span>
      </template>
      </el-table-column>
    </el-table>
      </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
 name:"integralTable",

      props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
       data() {
        return {

        }
      },
      methods:{
           formatDate(data) {
      return dayjs(data).format("YYYY-MM-DD HH:mm:ss");
    },
      }
}
</script>

<style lang="scss" scoped>
.integralTable{

}
</style>
