import Cookies from 'js-cookie';
import { siteTokenKey } from '@/settings';

/**
 * 获取本地Token
 * @author LiQingSong
 */
export function getToken () {
  return Cookies.get(siteTokenKey);
}

/**
 * 设置存储Token
 * @author LiQingSong
 */
export function setToken (token) {

  return Cookies.set(siteTokenKey, token);
}
export function setCookie (name,value) {

  return Cookies.set(name, value);
}
export function getCookie (name) {

  return Cookies.get(name);
}
// 储存用户名
export function setName (name) {
  return Cookies.set('userNane', name);
}
export function getName () {
  return Cookies.get('userNane');
}
export function setLogo (logo) {
  return Cookies.set('logo', logo);
}
export function setcompany (data) {
  return Cookies.set('company', data);
}
export function getcompany () {
  return Cookies.get('company');
}
/**
 * 移除本地Token
 * @author LiQingSong
 */
export function removeToken () {
  return Cookies.remove(siteTokenKey);
}
