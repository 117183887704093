<template>
    <div class="groupMember">
        <!-- <p class="title">组员查询</p> -->
        <!-- <el-divider></el-divider> -->
        <div class="content">
            <div class="content-search">
                <!-- <el-input
    placeholder="请输入您要查询的组员名称"
    v-model="input4">
    <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch"></i>
  </el-input> -->
            </div>

            <div class="content-result-box" v-for="item in groupMemberList">


                <el-avatar class="avatar" :size="40" :src="item.picturePath
                ">
                </el-avatar>
                <div class="username">{{ item.nickName }}</div>




            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'groupMember',
    props: {

        groupMemberList: {
            type: Array,
              default: []
        },

    },
    data() {
        return {
            input4: ''
        }
    },
    methods: {
        handleSearch() {
            console.log('handleSearch');

        }
    }
}
</script>

<style lang="scss" scoped>
.groupMember {
    text-align: center;

    .title {
        font-size: 24px;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        color: #030303;
    }

    .content {
        margin: 40px auto;
        width: 90%;

        .content-search {
            margin-bottom: 25px;
        }

        .content-result-box {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .username {
                font-size: 16px;
                font-family: Adobe Heiti Std;
                font-weight: bold;
                color: #1A1A1A;
                margin-left: 14px;
            }
        }
    }
}
</style>