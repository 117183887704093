<template>
  <div class="myTrain">
    <div class="top-bar">
      <div class="wrap">
        <div class="train-number-box">
          <p class="frequency">{{ badgeData.badgeTotal }}</p>
          <p class="number">{{ $t("language.MyAchievements.Badges") }}</p>
        </div>
        <div class="train-lv-box">
          <div class="icon-hz">
            <p class="icon-hz-number"></p>
          </div>
          <p class="train-lv-name"></p>
        </div>
        <div class="train-integral-box">
          <p class="frequency">{{ badgeData.totalIntegral }}</p>
          <p class="number">{{ $t("language.MyAchievements.Points") }}</p>
        </div>
      </div>
    </div>
    <h1 style="text-align: center; margin: 20px 0 20px 0">
      {{ $t("language.MyAchievements.Badges") }}
    </h1>
    <div class="search-box">
      <div class="line">
        <div class="label">{{ $t("language.myCourses.Course") }}</div>
        <ul>
          <li
            v-for="(item, index) in boxData"
            :key="index"
            :class="{
              opt: true,
              active: selectId === index,
            }"
            @click="doSelect(index, item.id)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="myTrain-content" v-loading="contentLoading">
      <div class="myTrain-card-box">
        <el-row :gutter="24" v-if="true" type="flex" justify="center">
          <el-col
            :span="4"
            style="
              margin-bottom: 20px;
              text-align: center;
              display: flex;
              align-items: center;
              flex-direction: column;
            "
          >
            <div :class="[badgeData.fullyPreparedTotal==0 ? ' filter' : ' ']" class="FullyPrepared"></div>
          <p :class="[badgeData.fullyPreparedTotal==0 ? 'font-gray ' : 'font-green']">
              x{{ badgeData.fullyPreparedTotal }}
            </p>
          </el-col>
          <el-col
            :span="4"
            style="
              margin-bottom: 20px;
              text-align: center;
              display: flex;
              align-items: center;
              flex-direction: column;
            "
          >
            <!-- <img style="width:200px" src="../../assets/images/FullyPrepared.jpg" alt=""> -->
            <div :class="[badgeData.quizWhizTotal==0 ? ' filter' : ' ']" class="QuizWhiz"></div>
         <p :class="[badgeData.quizWhizTotal==0 ? 'font-gray' : 'font-green']">
              x{{ badgeData.quizWhizTotal }}
            </p>
          </el-col>
          <el-col
            :span="4"
            style="
              margin-bottom: 20px;
              text-align: center;
              display: flex;
              align-items: center;
              flex-direction: column;
            "
          >
            <!-- <img style="width:200px" src="../../assets/images/FullyPrepared.jpg" alt=""> -->
            <div :class="[badgeData.taskCompletionTotal==0 ? ' filter' : ' ']" class="TaskCompletion"></div>
          <p :class="[badgeData.taskCompletionTotal==0 ? 'font-gray' : 'font-green']">
              x{{ badgeData.taskCompletionTotal }}
            </p>
          </el-col>
          <el-col
            :span="4"
            style="
              margin-bottom: 20px;
              text-align: center;
              display: flex;
              align-items: center;
              flex-direction: column;
            "
          >
            <!-- <img style="width:200px" src="../../assets/images/FullyPrepared.jpg" alt=""> -->
            <div :class="[badgeData.taskMasterTotal==0 ? ' filter' : ' ']" class="TaskMaster"></div>
        <p :class="[badgeData.taskMasterTotal==0 ? 'font-gray' : 'font-green']">
              x{{ badgeData.taskMasterTotal }}
            </p>
          </el-col>
          <el-col
            :span="4"
            style="
              margin-bottom: 20px;
              text-align: center;
              display: flex;
              align-items: center;
              flex-direction: column;
            "
          >
            <!-- <img style="width:200px" src="../../assets/images/FullyPrepared.jpg" alt=""> -->
            <div :class="[badgeData.topPerformerTotal==0 ? ' filter' : ' ']" class="topPerformer"></div>
           <p :class="[badgeData.topPerformerTotal==0 ? 'font-gray' : 'font-green']">
              x{{ badgeData.topPerformerTotal }}
            </p>
          </el-col>
        </el-row>

        <div class="no-data" v-else>{{ $t("language.Nodatatemporarily") }}</div>
        <h1 style="text-align: center; margin: 20px 0 20px 0 ;">
          {{ $t("language.MyAchievements.Certificate") }}
        </h1>
        <div>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column
              prop="name"
              :title="$t('language.MyAchievements.Title')"
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              :title="$t('language.MyAchievements.IssueDate')"
            >
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  formatDate(scope.row.createTime)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleDownload(scope.$index, scope.row)"
                  >{{ $t("language.myCourses.Download") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryBadgeTotal, findAnnexListByFkId } from "@/service/myBadge";
import dayjs from "dayjs";
import { getCookie } from "@/service/lib/localToken";
import { findCourseListByStudentId } from "@/service/userapi";
export default {
  data() {
    return {
      boxData: [],
      TrainingStatisticsList: [],
      tableData: [],
      tableLoading: false,
      nikeNmae: "测试",
      level: "",
      TrainingStatistics: {},
      contentLoading: false,
      RecordList: [],
      draftList: [],
      moduleList: [],
      levelIdList: [],
      params: {
        pageNum: 1,
        pageSize: 6,
        total: 0,
        currentPage: 1,
        userId: "111",
      },
      badgeData: {},
      parms: {
        courseId: "",
        studentId: getCookie("userId"),
      },
      selectId: 0,
    };
  },
  created() {
    // this.getDicts()
    // this.getlevelIdDicts()
  },
  mounted() {
    // this.getTrainingStatistics()
    // this.getRecordList()
    this.getCourses();
  },
  methods: {
    formatDate(data) {
      return dayjs(data).format("YYYY-MM-DD HH:mm:ss");
    },
    getCourses() {
      let id = getCookie("userId");
      console.log("getCookieid", id);
      findCourseListByStudentId({ id: id }).then((res) => {
        if (res.code == "000" && res.data) {
          this.boxData = res.data;
          this.parms.courseId = res.data[0].id;
          this.init();
        }
      });
    },
    doSelect(index, id) {
      this.selectId = index;
      this.parms.courseId = id;
      this.init();
      console.log(index, id, this.selectId);
      // if (this.selectIds !== id) {
      //   this.$set(this.selectIds, idx, id);
      //   this.$emit("change", this.selectIds);
      // }
    },
    init() {
      queryBadgeTotal(this.parms).then((res) => {
        this.badgeData = res.data;
      });
      findAnnexListByFkId({ fkId: getCookie("userId") }).then((res) => {
        console.log("res", res);
        this.tableData = res.data;
      });
    },
    handleDownload(scope, row) {
      window.location.href = row.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.myTrain {
  box-sizing: border-box;
  padding-bottom: 40px;
  // background: #fafbfc;
  padding: 0 !important;
  .top-bar {
    background: url("../../assets/images/knowledge-detail.png") center center;
    background-size: cover;
    height: 148px;
    .wrap {
      width: 1440px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .train-number-box {
        text-align: center;
        margin-right: 140px;

        .frequency {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin: 0;
        }
        .number {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin: 8px 0 0 0;
        }
      }
      .train-lv-box {
        text-align: center;
        margin-right: 140px;
        .icon-hz {
          background: url("../../assets/images/icon_hz.png") center center;
          background-size: cover;
          width: 93px;
          height: 91px;
          position: relative;
          .icon-hz-number {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 20px;
            text-align: center;
          }
        }
        .train-lv-name {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
          margin: 0;
        }
      }
      .train-integral-box {
        text-align: center;
        .frequency {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin: 0;
        }
        .number {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin: 8px 0 0 0;
        }
      }
    }
  }
.filter{
  filter: grayscale(100%);
}
  .myTrain-content {
    width: 1440px;
    margin: 0 auto;
    margin-top: 45px;
    .myTrain-card-box {
      ::v-deep .el-card {
        border-radius: 8px !important;
      }
      .FullyPrepared {
        width: 200px;
        height: 200px;
        //   background-color: red;
        background: url("../../assets/images/FullyPrepared.jpg");
        background-size: 100% 100%;
      }
      .QuizWhiz {
        width: 200px;
        height: 200px;
        //   background-color: red;
        background: url("../../assets/images/QuizWhiz.jpg");
        background-size: 100% 100%;
      }
      .TaskCompletion {
        width: 200px;
        height: 200px;
        //   background-color: red;
        background: url("../../assets/images/TaskCompletion.jpg");
        background-size: 100% 100%;
      }
      .TaskMaster {
        width: 200px;
        height: 200px;
        //   background-color: red;
        background: url("../../assets/images/TaskMaster.jpg");
        background-size: 100% 100%;
      }
      .topPerformer {
        width: 200px;
        height: 200px;
        //   background-color: red;
        background: url("../../assets/images/topPerformer.jpg");
        background-size: 100% 100%;
      }
      .box-card {
        ::v-deep .el-card__header {
          border-bottom: 2px dashed #e6ebf5 !important;
          padding: 20px;
        }
        .box-card-title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #959eab;
        }
        .box-card-integral {
          float: right;
          padding: 3px 0;
          .box-card-integral-number {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1d7be3;
            margin-right: 9px;
          }
          .box-card-integral-title {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #959eab;
          }
        }
        .content-title {
          margin-bottom: 19px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #324057;
        }
        .content-abstract {
          margin-bottom: 19px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #959eab;
        }
        .content-time-box {
          .content-time-title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #959eab;
          }
          .content-time {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1d7be3;
            margin-left: 10px;
          }
        }
      }
    }
    .pagination-box {
      text-align: right;
      margin-top: 50px;
    }
  }
}
.font-gray{
  font-size: 25px; color: #a8abaf;
      font-weight: bold;
}
.font-green{
  font-size: 25px; color: #1bd904; font-weight: bold;
}
.search-box {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  padding: 0.520833rem 0;
  border-bottom: 0.052083rem dashed #c3cad6;
  .line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .label {
      margin: 0 1.041667rem 0 0;
      font-size: 1.041667rem;
      font-weight: bold;
      color: #7c8697;
      line-height: 2.083333rem;
      cursor: default;
    }
    ul {
      display: flex;
    }
    .opt {
      margin-right: 1.145833rem;
      font-size: 1.041667rem;
      font-weight: bold;
      color: #324057;
      line-height: 2.083333rem;
      cursor: pointer;
      transition: 0.5s;
      &.active {
        color: #1d7be3;
        cursor: default;
      }
    }
  }
}
.no-data {
  min-height: 50vh;
  margin: auto;
  box-sizing: border-box;
  padding-top: 15%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
</style>
