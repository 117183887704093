<template>
  <div class="trophyQuery">

    <div class="content">

      <div class="content-result">
        <span>团队评分：</span>
        <el-rate v-model="value1" :max="7" :disabled="is"></el-rate>


      </div>
      <div>
        <el-button style="background: linear-gradient(0deg, #E74C32, #F19826, #F8CA66);margin-top: 50px;" type="warning"
          round @click="closeMyself"> {{is?'关闭':'提交'}} </el-button>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'trophyQuery',
  props: {

    collaboratorTeamScore: {
      type: Number,
      default: 0
    },

  },
  data() {
    return {
      value1: null,
      is:false
    }
  },
  mounted() {
    console.log('组件周期')
    console.log(this.collaboratorTeamScore)
    if(this.collaboratorTeamScore){
      this.value1  = this.collaboratorTeamScore
      this.is = true
    }

  },
  methods: {
    closeMyself() {
      let data = {
        value1:this.value1,
        is:this.is
      }
      this.$emit('on-close',data)
    }
  }
}
</script>
  
<style lang="scss" scoped>
.trophyQuery {
  text-align: center;

  .title {
    font-size: 24px;
    font-family: Adobe Heiti Std;
    font-weight: bold;
    color: #030303;
  }

  .content {
    margin: 40px auto;
    width: 90%;

    .content-search {
      margin-bottom: 25px;
    }

    .content-result {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        text-align: left;
        font-size: 16px;
        font-family: Adobe Heiti Std;
        font-weight: 400;
        color: #191919;
      }

      .content-result-left {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .introduce {
          margin-left: 5px;
          text-align: left;

          .title {
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: bold;
            color: #1A1A1A;
            margin-bottom: 5px;
          }

          .describe {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #C7C8C8;
          }
        }
      }

      .content-result-right {
        .text {
          width: 100px;
          height: 42px;
          background: linear-gradient(0deg, #E74C32, #F19826, #F8CA66);
          border-radius: 21px;
          line-height: 42px;

          color: #FFFFFF;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
        }
      }
    }
  }
}</style>