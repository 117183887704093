<template>
  <li class="card">
    <div class="line-1">
      <div class="module">
        {{ isBtns ? cardData.moduleName : cardData.module }}
      </div>
      <div class="level">
        {{ isBtns ? cardData.levelName : cardData.level }}
      </div>
      <div
        style="display: flex; align-items: center"
        class="score"
        v-if="userinfo.groupType != 0 && userinfo.groupType != 1"
      >
        {{ $t("language.myCourses.point") }}
        <img src="../../../assets/images/coin.png" alt="" />
      </div>
    </div>
    <div class="name">{{ cardData.name }}</div>
    <div class="intro"></div>
    <div class="btn-bottom">
      <div class="time">
        {{ $t("language.myCourses.PreclassTime") }}：
        <span
          >{{ formatDate(cardData.startTime) }} ~{{
            formatDate(cardData.beforeClassTime)
          }}
        </span>
      </div>
    </div>
    <div class="btn-bottom">
      <div class="time">
        {{ $t("language.myCourses.InclassTime") }}：
        <span
          >{{ formatDate(cardData.beforeClassTime) }} ~{{
            formatDate(cardData.answerTime)
          }}
        </span>
      </div>
    </div>
    <div class="btn-bottom">
      <div class="time">
        {{ $t("language.myCourses.AfterclassTime") }}：
        <span
          >{{ formatDate(cardData.answerTime) }} ~{{
            formatDate(cardData.endTime)
          }}
        </span>
      </div>
    </div>
    <div class="btn-bottom" v-if="isBtns" @click.stop>
      <div :title="$t('language.myCourses.PreclassMaterialsDownload')" class="btn untrained" @click="handleDownload">
        {{ $t("language.myCourses.PreclassMaterialsDownload") }}
      </div>
      <div :title="$t('language.myCourses.PreclassQuiz')" @click="handleBeforeClass" class="btn untrained">
        {{ $t("language.myCourses.PreclassQuiz") }}
      </div>
      <div :title="$t('language.myCourses.InclassQuiz')" @click="handleClassroom" class="btn untrained">
        {{ $t("language.myCourses.InclassQuiz") }}
      </div>
      <div :title="$t('language.myCourses.AfterclassDiscussion')" @click="handleAfterClass" class="btn untrained">
        {{ $t("language.myCourses.AfterclassDiscussion") }}
      </div>

    </div>
    <div class="btn-bottom" v-if="isBtns" @click.stop>
          <div
        v-if="userinfo.groupType == 2"
        @click="handleIntegral"
        class="btn untrained"
        :title="$t('language.myCourses.RewardsRedemption')"
      >
        {{ $t("language.myCourses.RewardsRedemption") }}
      </div>
      <div
        @click="handRankingList"
        class="btn untrained"
        v-if="userinfo.groupType != 0"
        :title="$t('language.myCourses.LeaderboardbySession')"
      >
        {{ $t("language.myCourses.LeaderboardbySession") }}
      </div>
      <div
        @click="handleBadge"
        class="btn untrained"
        v-if="userinfo.groupType != 0"
        :title="$t('language.myCourses.BadgesandPointsbySession')"
      >
        {{ $t("language.myCourses.BadgesandPointsbySession") }}
      </div>
    </div>
    <el-dialog :title="$t('language.myCourses.LeaderboardbySession')" :visible.sync="dialogTableVisible">
      
      <el-table :data="gridData" border style="width: 100%; margin-top: 20px">
       
           <el-table-column
           :label="$t('language.MyLeaderboard.Rank')"
        type="index"
        width="80"
        ></el-table-column>
        <el-table-column
          prop="nickName"
        
          :label="$t('language.myCourses.Names')"
          width=""
        ></el-table-column>

        <el-table-column prop="picturePath"  :label="$t('language.myCourses.ProfilePhotos')" width="">
          <template slot-scope="scope">
            <img :src="scope.row.picturePath" alt="" width="80" height="80" />
          </template>
        </el-table-column>
        <el-table-column
          prop="totalIntegral"
          sortable
           :label="$t('language.myCourses.Points')"
          width=""
        ></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      width="1200px"
      :title="$t('language.myCourses.BadgesandPointsbySession')"
      :visible.sync="dialogTableVisibleBadge"
    >
      <el-table :data="BadgeData" border style="width: 100%; margin-top: 20px">
        <el-table-column
          prop="fullyPrepared"
          sortable
          label="徽章(Fully Prepared)"
          width="180"
        >
          <template slot-scope="scope">
            <span
              :class="scope.row.fullyPrepared != 0 ? 'greencolor' : 'redcolor'"
              >{{ scope.row.fullyPrepared == 0 ? "未获得" : "已获得" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="taskCompletion"
          sortable
          label="徽章(Task Completion)"
          width="180"
        >
          <template slot-scope="scope">
            <span
              :class="scope.row.taskCompletion != 0 ? 'greencolor' : 'redcolor'"
              >{{ scope.row.taskCompletion == 0 ? "未获得" : "已获得" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="quizWhiz"
          sortable
          label="徽章(Quiz Whiz)"
          width="180"
        >
          <template slot-scope="scope">
            <span
              :class="scope.row.quizWhiz != 0 ? 'greencolor' : 'redcolor'"
              >{{ scope.row.quizWhiz == 0 ? "未获得" : "已获得" }}</span
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="taskMaster"
          sortable
          label="徽章 (Task Master)"
          width="180"
        >
          <template slot-scope="scope">
            <span
              :class="scope.row.taskMaster != 0 ? 'greencolor' : 'redcolor'"
              >{{ scope.row.taskMaster == 0 ? "未获得" : "已获得" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="topPerformer"
          sortable
          label="徽章(Top Performer)"
          width="180"
        >
          <template slot-scope="scope">
            <span
              :class="scope.row.topPerformer != 0 ? 'greencolor' : 'redcolor'"
              >{{ scope.row.topPerformer == 0 ? "未获得" : "已获得" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="totalIntegral"
          sortable
          :label="$t('language.myCourses.Points')"
          width="80"
        ></el-table-column>

        <el-table-column
          prop="frontAnswer"
          sortable
           :label="$t('language.myCourses.Preclassquiz')"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="frontDownload"
          sortable
          :label="$t('language.myCourses.Preclassdownload')"




          width="80"
        ></el-table-column>
        <el-table-column
          prop="frontTask"
          sortable
           :label="$t('language.myCourses.Preclasstaskcompletion')"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="classroomAnswer"
          sortable
         :label="$t('language.myCourses.Inclassquiz')"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="classroomManual"
          sortable
            :label="$t('language.myCourses.Inclassvoluntaryparticipation')"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="currency"
          sortable
            :label="$t('language.myCourses.coin')"
          width="80"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </li>
</template>

<script>
import { findMidListByPage } from "@/service/rankingList";
import { getCookie } from "@/service/lib/localToken";
import { getSystemTime } from "@/service/userapi";
import { downloadClassHourAnnex } from "@/service/myCourses";
import dayjs from "dayjs";
export default {
  props: {
    cardData: {
      type: Object,
      default: () => {},
    },
    isBtns: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    console.log('userinfo.groupType',this.userinfo.groupType)
  },
  data() {
    return {
      language: window.sessionStorage.getItem("lang"),
      userinfo: JSON.parse(getCookie("userdata")),
      parms: {
        page: 1,
        pageSize: 999,
        hourId: this.cardData.id,
         groupType:JSON.parse(getCookie("userdata")).groupType
      },
      Badgeparms: {
        page: 1,
        pageSize: 999,
        hourId: this.cardData.id,
        name: JSON.parse(getCookie("userdata")).name,
        groupType:JSON.parse(getCookie("userdata")).groupType
      },
      gridData: [],
      BadgeData: [],
      dialogTableVisible: false,
      dialogTableVisibleBadge: false,
      //  用户分组为2的时候才显示积分兑换  用户为0的时候不显示积分
    };
  },
  methods: {
    formatDate(data) {
      return dayjs(data).format("YYYY-MM-DD HH:mm:ss");
    },
    //判断点击按钮时时间是否在范围内
    estimateTime(start_date, end_date, stamp, BtnType) {
      console.log(start_date, end_date)
      //开始时间
      let start = new Date(
        start_date.replace(/-/g, "/").replace(/-/g, "/")
      ).getTime();
      //结束时间
      let end = new Date(
        end_date.replace(/-/g, "/").replace(/-/g, "/")
      ).getTime();
      //当前时间
      stamp = new Date(stamp.replace(/-/g, "/").replace(/-/g, "/")).getTime();
      console.log(stamp);
      if (start > stamp) {
        if(BtnType == "Integral"){
             this.$router.push({
        path: "/integralExchange",
        query: { hourId: this.cardData.id ,
           IntegralEnd:0
                    },
      });
        }else{
     if (this.$i18n.locale == "zh-CN") {
          this.$message({
            message: "未开始",
            type: "warning",
          });
        } else {
          this.$message({
            message: "Not Started",
            type: "warning",
          });
        }
        }
   
      } else if (stamp > end) {
        if(BtnType == "PreclassMaterialsDownload") {
          window.location.href = this.cardData.annexPath;
        }else if(BtnType == "AfterClass"){
            this.$router.push({
            path: "/classDiscussion",
            query: {
              fkId: this.cardData.id,
              subjectTag: 1,
              studentId: getCookie("userId"),
              AfterClassEnd:0
            },
          });
        }else if(BtnType == "Integral"){
             this.$router.push({
        path: "/integralExchange",
        query: { hourId: this.cardData.id ,
           IntegralEnd:0
                    },
      });
        }
        else{
    if (this.$i18n.locale == "zh-CN") {
          this.$message({
            message: "已结束",
            type: "warning",
          });
        } else {
          this.$message({
            message: "Finished",
            type: "warning",
          });
        }
        }

      } else if (start <= stamp && stamp <= end) {
        if (BtnType == "BeforeClass") {
          this.$router.push({
            path: "/myCourses/myCoursesExaminationDetail",
            query: {
              fkId: this.cardData.id,
              subjectTag: 0,
              studentId: getCookie("userId"),
              start_date,
               end_date, 
              
            },
          });
        } else if (BtnType == "Classroom") {
          this.$router.push({
            path: "/myCourses/myCoursesExaminationDetail",
            query: {
              fkId: this.cardData.id,
              subjectTag: 1,
              studentId: getCookie("userId"),
              start_date,
               end_date, 
            },
          });
        } else if (BtnType == "AfterClass") {
          this.$router.push({
            path: "/classDiscussion",
            query: {
              fkId: this.cardData.id,
              subjectTag: 1,
              studentId: getCookie("userId"),
               AfterClassEnd:1,
                    start_date,
               end_date, 
            },
          });
        }else if(BtnType == "PreclassMaterialsDownload"){
    downloadClassHourAnnex({
        studentId: getCookie("userId"),
        hourId: this.cardData.id,
      }).then((res) => {
        // console.log(this.cardData.annexPath)
        if (res.data != 0) {
          this.$message.success(res.message);
        }
        if (res.code == "000") {
          window.location.href = this.cardData.annexPath;
        }
      });
        }else if(BtnType == "Integral"){
                   this.$router.push({
        path: "/integralExchange",
        query: { hourId: this.cardData.id ,
           IntegralEnd:1
                    },
      });
        }
      }
    },
    //资料下载按钮事件
    handleDownload() {
        getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(
            this.formatDate(this.cardData.startTime),
            this.formatDate(this.cardData.beforeClassTime),
            res.data,
            "PreclassMaterialsDownload"
          );
        }
      });

    },
    //课前按钮事件
    handleBeforeClass() {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(
            this.formatDate(this.cardData.startTime),
            this.formatDate(this.cardData.beforeClassTime),
            res.data,
            "BeforeClass"
          );
        }
      });
    },
    //课堂按钮事件
    handleClassroom() {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(
            this.formatDate(this.cardData.beforeClassTime),
            this.formatDate(this.cardData.answerTime),
            res.data,
            "Classroom"
          );
        }
      });
    },
    //课后按钮事件
    handleAfterClass() {
      getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(
            this.formatDate(this.cardData.answerTime),
            this.formatDate(this.cardData.endTime),
            res.data,
            "AfterClass"
          );
        }
      });
    },
    handleIntegral() {
            getSystemTime().then((res) => {
        if (res.code == "000" && res.data) {
          this.estimateTime(
            this.formatDate(this.cardData.startTime),
            this.formatDate(this.cardData.endTime),
            res.data,
            "Integral"
          );
        }
      });
  
    },
    //积分排行点击事件
    handRankingList() {
      findMidListByPage(this.parms).then((res) => {
        this.dialogTableVisible = true;
        this.gridData = res.data.list;
      });
    },
    handleBadge() {
      findMidListByPage(this.Badgeparms).then((res) => {
        this.dialogTableVisibleBadge = true;
        this.BadgeData = res.data.list;
      });
    },
  },
};
</script>
<style>
.redcolor {
  color: red;
}
.greencolor {
  color: #3cd53c;
}
</style>
<style lang="scss" scoped>
.card {
  width: 464px;
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  .line-1 {
    padding: 0 1.041667rem;
    height: 3.229167rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.052083rem dashed #c3cad6;
    font-size: 0.833333rem;
    font-weight: bold;
    color: #959eab;
    .module {
      margin-right: 22px;
    }
    .level {
      flex: 1;
    }
    .score {
      font-size: 0.625rem;
      color: #959eab;
      vertical-align: text-bottom;
      span {
        margin-right: 0.520833rem;
        font-size: 0.9375rem;
        color: #1d7be3;
      }
    }
  }
  .name {
    margin: 1.25rem 1.041667rem 0;
    font-size: 0.9375rem;
    font-weight: bold;
    color: #324057;
    line-height: 0.9375rem;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .intro {
    margin: 0.625rem 1.041667rem 0.9375rem;
    height: 3.4375rem;
    font-size: 0.729167rem;
    color: #959eab;
    line-height: 1.145833rem;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .btn-bottom {
    margin: 0 1.041667rem;
    padding-bottom: 1.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .time {
      font-size: 0.729167rem;
      color: #959eab;
      span {
        color: #1d7be3;
      }
    }
    .btn {
      width: 7.25rem;
      height: 2.5rem;
      font-size: 0.729167rem;
      font-weight: bold;
      color: #fafbfc;
      line-height: 2.5rem;
      text-align: center;
      border-radius: 0.416667rem;
      transition: 0.5s;
      &.untrained {
        background: #1d7be3;
      }
      &:hover {
        background: rgba($color: #1d7be3, $alpha: 0.7);
      }
      &.trained {
        background: #c3cad6;
        &:hover {
          background: rgba($color: #c3cad6, $alpha: 0.7);
        }
      }
    }
  }
  .time-bottom {
    margin: 0 20px;
    padding-bottom: 36px;
    .time {
      color: #1d7be3;
    }
  }
}
</style>
