<template>
  <div class="exchange">
    <ul class="train-list">
      <!-- 下载模块 -->
      <li class="card" v-for="item in downloadList" :key="item.id">
        <div class="card-box">
        <div class="top-bg"></div>
        <div class="line-1">
          <div class="module">
             兑换奖品
          </div>
          <!-- <div class="level">
    课后习题
      </div> -->
          <div class="score">
            <span>{{ item.exchangeNumber }}</span
            >{{ $t("language.myCourses.Coins") }}
          </div>
        </div>
        <div class="name">{{ item.introduce }}</div>
        <div class="intro"></div>
        <div class="btn-bottom">
          <el-button :disabled="IntegralEnd" style="
background: #0161A4;" size="medium"  @click="handleDownload(item)" type="primary" >
            {{ $t("language.myCourses.Download") }}
          </el-button>
        </div>
      </div>
      </li>
      <!-- 问题提交模块 -->
      <li class="card" v-for="item in issue" :key="item.id">
        <div class="top-bg"></div>
        <div class="card-box">
        <div class="line-1">
          <div class="module">
            {{ $t("language.myCourses.PersonalizedFeedback") }}
          </div>
          <!-- <div class="level">
    多段文本框
      </div> -->
      
          <!-- <div class="score">
            <span>{{ item.exchangeNumber }}</span
            >货币兑换
          </div> -->
        </div>
        <div class="name">{{ item.introduce }}</div>
        <div class="name">
          {{ $t("language.myCourses.Pleasetypeyourquestionbelow") }}
        </div>
        <div class="intro">
          <el-input
            type="textarea"
            :rows="12"
            placeholder="请输入内容"
            v-model="item.textarea"
          >
          </el-input>
        </div>
        <div class="btn-bottom">
          <el-button :disabled="IntegralEnd" style="
background: #0161A4;" size="medium"  @click="handleissue(item)" type="primary">
            提交
          </el-button>
        </div>
      </div>
      </li>
      <!-- 上传论文模块 -->
      <!-- 问题提交模块 -->
      <li class="card" v-for="item in thesis" :key="item.id">
        <div class="top-bg"></div>
        <div class="card-box">
        <div class="line-1">
          
          <div class="module">
            {{ item.introduce }}
          </div>
          <!-- <div class="level">
    上传
      </div> -->
          <!-- <div class="score" v-if="item.exchangeNumber">
            <span>{{ item.exchangeNumber }}</span
            >货币兑换
          </div> -->
        </div>
        <!-- <div class="name">{{ item.introduce }}</div> -->
        <div class="name">
          请在下方上传想要助教批改的1道简单题
        </div>
        <div class="intro">
          <el-input
            v-model="paperRemark"
            type="textarea"
  :rows="5"
  placeholder="请输入备注内容"
            style="margin-top: 15px"
          ></el-input>
          <el-upload
            class="avatar-uploader"
            :headers="headers"
            :action="fileUrl"
            :show-file-list="true"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="1"
            :on-exceed="handleExceed"

          >
            <el-button :disabled="IntegralEnd" v-if="!IntegralEnd"  style="margin-top: 15px;
background: #0161A4;"  size="medium" type="primary">{{
              $t("language.myCourses.Clicktosubmit")
            }}</el-button>
          </el-upload>

         
        </div>

        <div class="btn-bottom">
          <el-button :disabled="IntegralEnd"  @click="handlethesis(item)" style="
background: #0161A4;" size="medium" type="primary">
            提交
          </el-button>
        </div>
      </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { prizeExchange } from "@/service/integralExchange";
import { getCookie, getToken } from "@/service/lib/localToken";

export default {
  name: "exchange",

  props: {
    exchangeList: {
      type: Array,
      default: () => [],
    },
    IntegralEnd:{
      type: Boolean,
        default: () => true
    }
  },
  watch: {
    exchangeList() {
      this.type();
    },
  },
  data() {
    return {
      headers: {
        Authorization: getToken(),
      },
      fileUrl:
        "https://admin.goplayedu.com/study-manager/module/file/fileUpload",
      fileList: [],
      downloadList: [],
      issue: [],
      thesis: [],
      uploadPaperPath: "", //论文上传路径
      paperRemark: "", //论文备注
      textarea: "",
      form: {},
      parms: {},
    };
  },
  mounted() {
    this.type();
    console.log('this.in',this.IntegralEnd)
  },
  methods: {
    type() {
      this.downloadList = [];
      this.issue = [];
      this.thesis = [];
      this.exchangeList.forEach((item) => {

        if (item.exchangeType == 1) {
          this.downloadList.push(item);
        } else if (item.exchangeType == 2) {
          this.issue.push(item);
        } else if (item.exchangeType == 3 || item.exchangeType == 4) {
          this.thesis.push(item);
        }
      });
    },
    handleDownload(item) {
      let msg
      if(this.$i18n.locale == "zh-CN"){
                msg =   this.$confirm(
        `此次操作将消费${item.exchangeNumber}货币，是否继续?  ${item.exchangeNumber}积分 = ${item.exchangeNumber}货币`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
      }else{
           msg =   this.$confirm(
       `This redemption will cost ${item.exchangeNumber} coins，continue or not? ${item.exchangeNumber} points = ${item.exchangeNumber} coins`,
        "hint",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
      }


        msg.then(() => {
          console.log(item);
          this.parms.hourId = item.hourId;
          this.parms.id = item.id;
          this.parms.studentId = getCookie("userId");
          prizeExchange(this.parms).then((res) => {
            console.log(res);
            if (res.code == "000") {
              if (this.$i18n.locale == "zh-CN") {
                window.location.href = res.data;
                this.$message({
                  message: "兑换成功！",
                  type: "success",
                });
              } else {
                window.location.href = res.data;
                this.$message({
                  message: "Redeemed successfully!",
                  type: "success",
                });
              }
            this.$emit('show');
            }
          });
        })
        .catch(() => {});
    },

    handleissue(item) {
       let msg
      if(this.$i18n.locale == "zh-CN"){
                msg =   this.$confirm(
            `此次操作将消费${item.exchangeNumber}货币，是否继续? ${item.exchangeNumber}积分 = ${item.exchangeNumber}货币`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
      }else{
           msg =   this.$confirm(
      `This redemption will cost ${item.exchangeNumber} coins，continue or not? ${item.exchangeNumber} points = ${item.exchangeNumber} coins`,
        "hint",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
      }
      msg.then(() => {
          console.log(item);
          this.parms.hourId = item.hourId;
          this.parms.id = item.id;
          this.parms.studentId = getCookie("userId");
          this.parms.problemContent = item.textarea;
          prizeExchange(this.parms).then((res) => {
            console.log(res);
            if (res.code == "000") {
              this.$message({
                message: "提交成功！",
                type: "success",
              });
                  this.$emit('show');
            }
          });
        })
        .catch(() => {});
    },
    handlethesis(item) {
        let msg
      if(this.$i18n.locale == "zh-CN"){
                msg =   this.$confirm(
        `是否提交`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
      }else{
           msg =   this.$confirm(
        `This redemption will cost ${item.exchangeNumber} coins，continue or not? ${item.exchangeNumber} points = ${item.exchangeNumber} coins`,
        "hint",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
      }
      msg.then(() => {
          console.log(item);
          this.parms.hourId = item.hourId;
          this.parms.id = item.id;
          this.parms.studentId = getCookie("userId");
          this.parms.paperRemark = this.paperRemark;
          this.parms.uploadPaperPath = this.uploadPaperPath;
          prizeExchange(this.parms).then((res) => {
            console.log(res);
            if (res.code == "000") {
              this.$message({
                message: "提交成功！",
                type: "success",
              });
            }
                this.$emit('show');
          });
        })
        .catch(() => {});
    },
    handleExceed(files, fileList) {
        if(this.$i18n.locale == "zh-CN"){
  this.$message.warning(`最多上传一个文件!`);
        }else{
  this.$message.warning(`Upload a file!`);
        }

    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.uploadPaperPath = file.response.location;

    },
    beforeAvatarUpload() {},
    handleRemove() {},
  },
};
</script>

<style lang="scss" scoped>
.train-list {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  padding-bottom: 2.604167rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  .card {
    margin: 0 14px 14px 0;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    width: 100%;
    height: 35rem;
   overflow:auto;

    background: #fff;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    .top-bg {
        width: 100%;
        height: 8px;
        background: linear-gradient(90deg, #0160A3, #349BE3);
        border-radius: 8px 8px 0 0;
      }
      .card-box{ 
        padding: 0 40px 0 40px;
    .line-1 {
      padding: 0 1.041667rem;
      height: 3.229167rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 0.052083rem dashed #c3cad6;
      font-size: 0.833333rem;
      font-weight: bold;
      color: #959eab;
      .module {
        font-size: 20px;
font-family: Adobe Heiti Std;
font-weight: bold;
color: #0B151D;
      }
      .level {
        flex: 1;
      }
      .score {
        font-size: 0.625rem;
        color: #959eab;
        vertical-align: text-bottom;
        span {
          margin-right: 0.520833rem;
          font-size: 0.9375rem;
          color: #1d7be3;
        }
      }
    }
    .name {
      margin: 1.25rem 1.041667rem 0;
      font-size: 0.9375rem;
      font-weight: bold;
      color: #324057;
      line-height: 0.9375rem;
      // word-break: break-all;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }
    .intro {
      margin: 0.625rem 1.041667rem 0.9375rem;
      height: 19rem;
      font-size: 0.729167rem;
      color: #959eab;
      line-height: 1.145833rem;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .upload-demo {
      text-align: center;
    }
    .btn-bottom {
      margin: 0 1.041667rem;
      padding-bottom: 1.875rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .time {
        font-size: 0.729167rem;
        color: #959eab;
        span {
          color: #1d7be3;
        }
      }
      .btn {
        width: 6.25rem;
        height: 2.5rem;
        font-size: 0.729167rem;
        font-weight: bold;
        color: #fafbfc;
        line-height: 2.5rem;
        text-align: center;
        border-radius: 0.416667rem;
        transition: 0.5s;
        &.untrained {
          background: #1d7be3;
        }
        &:hover {
          background: rgba($color: #1d7be3, $alpha: 0.7);
        }
        &.trained {
          background: #c3cad6;
          &:hover {
            background: rgba($color: #c3cad6, $alpha: 0.7);
          }
        }
      }
    }
    .time-bottom {
      margin: 0 20px;
      padding-bottom: 36px;
      .time {
        color: #1d7be3;
      }
    }
  }
  }
}
</style>
