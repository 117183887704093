<template>
  <section class="title-line">
    <div class="wrap">
      <div class="left">
        <h2 v-if="url" style="cursor: pointer" @click="toPage(url)">
          {{ title }}
        </h2>
        <h2 v-else>{{ title }}</h2>
        <p>{{ tip }}</p>
      </div>
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "ApMaTitleLine",
  props: {
    title: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    tip: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    toPage(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.title-line {
  .wrap {
    position: relative;
    margin: 0 auto;
    width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #c3cad6;
    .left {
      display: flex;
      align-items: baseline;
      h2 {
        font-size: 22px;
        color: #324057;
        margin-right: 2.291667rem;
        font-weight: bold;
      }
    }
  }
}
</style>