// 防抖
export const DebounceBy = (fn, t) => {
  let delay = t || 500
  console.log(t)
  let timer
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer)
    }

    let callNow = !timer

    timer = setTimeout(() => {
      timer = null
    }, delay)

    if (callNow) fn.apply(this, args)
  }
}