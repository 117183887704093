var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"training"},[_c('div',{staticClass:"article"},[_c('el-button',{staticClass:"title-bottom",attrs:{"type":"info","icon":"el-icon-back","circle":""},on:{"click":function($event){return _vm.$router.go(-1)}}})],1),(_vm.trainQuestions.length > 0)?_c('div',{staticClass:"training-test"},[_c('div',{staticClass:"top-bg"}),_c('div',{staticClass:"title",staticStyle:{"line-height":"30px","padding":"20px","text-align":"left"},domProps:{"innerHTML":_vm._s(_vm.swannotation(_vm.trainQuestions[_vm.activeNum].name))}}),(!_vm.isTrained)?_c('div',{staticClass:"test-box"},[_c('div',{staticClass:"title-line"},[_c('div',{staticClass:"name"},[(_vm.trainQuestions[_vm.activeNum])?_c('span',[(_vm.trainQuestions[_vm.activeNum].studentAnswer)?_c('div',[(_vm.trainQuestions[_vm.activeNum].answer ==
                _vm.trainQuestions[_vm.activeNum].studentAnswer
                )?_c('span',{staticStyle:{"color":"#2c7bfd"}},[_vm._v(" 本题正确答案为:"+_vm._s(_vm.trainQuestions[_vm.activeNum].answer)+" "+_vm._s(_vm.$t("language.myCourses.AnswerTrue"))),(_vm.userinfo.groupType != 0 &&
_vm.trainQuestions[_vm.activeNum].score != 0
)?_c('span',[_vm._v(" 获得相应积分")]):_vm._e()]):(_vm.trainQuestions[_vm.activeNum].answer !==
                _vm.trainQuestions[_vm.activeNum].studentAnswer
                )?_c('span',{staticStyle:{"color":"#fd422c"}},[_vm._v("本题正确答案为:"+_vm._s(_vm.trainQuestions[_vm.activeNum].answer)+" 很遗憾您答错了")]):_vm._e(),_c('div',[_vm._v("本题解析： "),(_vm.trainQuestions[_vm.activeNum].subjectAnalysis)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.trainQuestions[_vm.activeNum].subjectAnalysis)}}):_c('div',[_vm._v("暂无解析")])]),(_vm.activeNum == (_vm.trainQuestions.length - 1))?_c('p',[_c('span',[_vm._v("本次测试结束，您一共答对"+_vm._s(_vm.totaldata.num)+"题 "),(_vm.userinfo.groupType != 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("language.myCourses.achieving"))+_vm._s(_vm.totaldata.fraction)+_vm._s(_vm.$t("language.myCourses.pointsintotal"))+" ")]):_vm._e()])]):_vm._e()]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"pos-count"},[_c('span',[_vm._v(_vm._s(_vm.activeNum + 1))]),_vm._v(" / "+_vm._s(_vm.trainQuestions.length)+" ")])]),_c('div',{staticClass:"question-box"},[_c('div',{staticClass:"right"},_vm._l((_vm.JsonsubjectItem),function(item,idx){return _c('div',{key:idx,staticClass:"answer"},[(idx == 0)?_c('span',{staticStyle:{"color":"rgb(44, 123, 253)","font-size":"20px","font-weight":"bold","margin-right":"10px"}},[_vm._v("A")]):_vm._e(),(idx == 1)?_c('span',{staticStyle:{"color":"rgb(44, 123, 253)","font-size":"20px","font-weight":"bold","margin-right":"10px"}},[_vm._v("B")]):_vm._e(),(idx == 2)?_c('span',{staticStyle:{"color":"rgb(44, 123, 253)","font-size":"20px","font-weight":"bold","margin-right":"10px"}},[_vm._v("C")]):_vm._e(),(idx == 3)?_c('span',{staticStyle:{"color":"rgb(44, 123, 253)","font-size":"20px","font-weight":"bold","margin-right":"10px"}},[_vm._v("D")]):_vm._e(),_c('el-radio',{class:{
              'el-radio': true,
              showError: _vm.showError && item == _vm.curAnswerEn,
            },attrs:{"disabled":!_vm.showSubmit,"label":item.value},model:{value:(_vm.curAnswerEn),callback:function ($$v) {_vm.curAnswerEn=$$v},expression:"curAnswerEn"}},[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),0)]),_c('div',{staticClass:"btns"},[(_vm.showSubmit)?_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":function($event){return _vm.goSubmit()}}},[_vm._v("提交")]):_vm._e(),(_vm.activeNum > 0)?_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":function($event){return _vm.Previous()}}},[_vm._v("上一题")]):_vm._e(),(_vm.showNext)?_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":function($event){return _vm.goNext()}}},[_vm._v("下一题")]):_vm._e()],1)]):_vm._e()]):_c('div',{staticClass:"no-data"},[_vm._v("暂无数据")])])}
var staticRenderFns = []

export { render, staticRenderFns }