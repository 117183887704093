<template>
  <div class="userInfo">
    <div class="container">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('language.UserSettings.BasicInformation')" name="data">
          <el-form ref="form" :model="dataform" label-width="150px">
            <el-form-item :label="$t('language.UserSettings.Account')">
              <el-input v-model="dataform.account" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('language.UserSettings.Name')">
              <el-input v-model="dataform.name" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('language.UserSettings.UserName')">
              <el-input :disabled="dataform.modifyTag != 0" v-model="dataform.nickName"></el-input>
            </el-form-item>

            <el-form-item :label="$t('language.UserSettings.PhoneNumber')">
              <el-input v-model="dataform.phoneNumber"></el-input>
            </el-form-item>
            <el-form-item :label="$t('language.UserSettings.Gender')">
              <el-radio-group v-model="dataform.gender">
                <el-radio :label="1">{{$t("language.UserSettings.man")}}</el-radio>
                <el-radio :label="0">{{$t("language.UserSettings.woman")}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('language.UserSettings.Age')">
              <el-input v-model="dataform.age"></el-input>
            </el-form-item>
            <el-form-item :label="$t('language.UserSettings.Major')">
              <el-input v-model="dataform.major"></el-input>
            </el-form-item>
            <el-form-item :label="$t('language.UserSettings.Grade')">
              <el-input v-model="dataform.grade"></el-input>
            </el-form-item>
            <el-form-item :label="$t('language.UserSettings.Mail')">
              <el-input v-model="dataform.mail"></el-input>
            </el-form-item>
            <el-form-item :label="$t('language.UserSettings.ProfilePhoto')">
              <el-upload class="avatar-uploader" :action="VUE_APP_file" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="dataform.picturePath" :src="dataform.picturePath" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button @click="userSub" type="primary">{{
                $t("language.UserSettings.Submit")
              }}</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('language.UserSettings.ChangePassword')" name="password">
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item :label="$t('language.UserSettings.OldPassword')" prop="oldpass">
              <el-input v-model="ruleForm.oldpass"></el-input>
            </el-form-item>

            <el-form-item :label="$t('language.UserSettings.NewPassword')" prop="pass">
              <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$t('language.UserSettings.ConfirmPassword')" prop="checkPass">
              <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">{{
                $t("language.UserSettings.Submit")
              }}</el-button>
              <el-button @click="resetForm('ruleForm')">{{
                $t("language.UserSettings.Reset")
              }}</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="bah2">
      <a href="https://beian.miit.gov.cn" target="_blank">ICP备:京ICP备2024071304号-1</a>
    </div>
  </div>
</template>

<script>
import { setCookie, getCookie } from "@/service/lib/localToken";
import { modifyStudent, modifyPassword } from "@/service/userapi";
import VUE_APP_APIHOST from "@/service/lib/requestcofig";

export default {
  name: "userInfo",
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      VUE_APP_file: VUE_APP_APIHOST + "module/file/fileUpload",
      activeName: "data",
      dataform: {
        gender: 1,
      },
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur", required: true }],
        checkPass: [
          { validator: validatePass2, trigger: "blur", required: true },
        ],
        oldpass: [{ trigger: "blur", required: true, message: "请输入旧密码" }],
      },
    };
  },
  mounted () {
    this.getuserdata();
  },
  methods: {
    getuserdata () {
      let userdata = JSON.parse(getCookie("userdata"));
      console.log(userdata);
      this.dataform = userdata;
    },
    userSub () {
      console.log(this.dataform);
      let postdata = { ...this.dataform };
      let userdata = JSON.parse(getCookie("userdata"));
      if(postdata.nickName != userdata.nickName){
        postdata.modifyTag = 1
        this.dataform.modifyTag = 1
      }
      delete postdata.groupType
      modifyStudent(postdata).then((res) => {
        this.$message.success("修改成功");
        setCookie("userdata", JSON.stringify(this.dataform));
      });
    },
    //标签页切换
    handleClick (tab, event) {
      console.log(tab, event);
    },
    //密码修改提交
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let postdata = {
            oldPassword: this.ruleForm.oldpass,
            newPassword: this.ruleForm.pass,
            id: this.dataform.id,
          };
          modifyPassword(postdata).then((res) => {
            this.$message.success("修改成功");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //密码修改重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    //上传事件
    handleAvatarSuccess (res, file) {
      console.log(file)
      this.dataform.picturePath = file.response.location;
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '/src/assets/scss/mixin.scss';
@import '/src/assets/scss/config.scss';
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
