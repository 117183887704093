<template>
    <div>
    <div class="dialog-wrap">
     <!-- <div class="dialog-cover" v-if="isShow" @click="closeMyself"></div> -->
     <div class="dialog-cover" v-if="isShow" ></div>
     <transition name="drop">
     <div class="dialog-content" v-if="isShow">
         
        <div class="level-bg0" :class="'level-bg'+startNum">
            <div class="top">
                <p>恭喜你</p>
                <!-- <p>升级成功啦</p> -->
            </div>
          <div class="center">
              <div class="Ellipse"></div>
              <p>你现在的等级是{{challengerLevel}}级</p>
              <div class="Ellipse"></div>
          </div>
          <div class="bottom">
            <img src="../../../assets/images/levelIcon.png" alt="">
            <p>Level {{ challengerLevel }}</p>
          </div>
          <div class="bottom-button">
            <el-button class="dialog-buttonclose" icon="el-icon-close" circle size="mini" @click="closeMyself"  ></el-button>
          </div>
        </div>
      <!-- <p class="dialog-close" @click="closeMyself">x</p> -->
      <!-- <el-button class="dialog-buttonclose" icon="el-icon-close" circle size="mini" @click="closeMyself" v-if="buttonShow"></el-button> -->
      
      
      
     </div>
     </transition>
    </div>
    </div>
   </template>

<script >
 export default {
 props: {
  isShow: {
  type: Boolean,
  default: false
  },
 
  buttonShow:{
    type: Boolean,
  default: true
  },
  challengerLevel:{
    type:Number,
    default:0
  },
  startNum:{
    type:Number,
    default:0
  },
 },
 data () {
  return {
  }
 },
 methods: {
  closeMyself () {
  this.$emit('on-close')
  
  }
 }
 }
</script>

<style scoped lang="scss">
 .drop-enter-active {
 transition: all .5s ease;
 }
 .drop-leave-active {
 transition: all .3s ease;
 }
 .drop-enter {
 transform: translateY(-500px);
 }
 .drop-leave-active {
 transform: translateY(-500px);
 }
 .dialog-wrap {
 position: fixed;
 width: 100%;
 height: 100%;
 }
 .top-bg {
        width: 100%;
        height: 8px;
        background: linear-gradient(90deg, #0160A3, #349BE3);
        border-radius: 8px 8px 0 0;
      }
      .title{
      font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: bold;
  color: #030303;
  text-align: center;
  margin: 50px 0 40px 0;
   }
   .slot-box{
    /* padding: 50px; */
    margin: auto;
   }
 .dialog-cover {
 background: #000;
 opacity: .3;
 position: fixed;
 z-index: 5;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 }
 .dialog-content {
 /* width: 50%; */
 width: 700px;
 height: 100%;
 position: fixed;
 /* max-height: 50%; */
 overflow: auto;
 /* background: #fff; */
 top: 20%;
 left: 50%;
 /* margin-left: -25%; */
 margin-left: -350px;
 z-index: 10;
 /* border: 2px solid #464068; */
 /* padding: 2%; */
 line-height: 1.6;
 
border-radius: 8px;
 }
 .level-bg0{
    width: 490px;
    height: 563px;
    background: url('../../../assets/images/Level0.png');
    background-size: contain;
    margin: auto;
    position: relative;
    .top{
        position: absolute;
        transform: translate(-50%);
      left: 50%;
      top: 215px;
      text-align: center;
      p{
        font-size: 28px;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #ffff;
line-height: 47px;
text-shadow: 0px 6px 5px rgba(255,210,218,0.56), 0px 6px 5px rgba(248,210,217,0.56);
      };
    }
    .center{
        width: 197px;
height: 25px;
background: #C57842;
box-shadow: 0px 4px 4px 0px rgba(248,105,0,0.35);
border-radius: 10px;
position: absolute;
        transform: translate(-50%);
      left: 50%;
      top: 340px;
      text-align: center;
      display: flex;
    align-items: center;
    justify-content: space-around;
      p{
        font-size: 14px;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #FFF4A8;
 
      }
      .Ellipse{
        width: 10px;
    height: 10px;
    background: url('../../../assets/images/Ellipse.png');
    background-size: contain;
      }
    }
    .bottom{
        position: absolute;
        transform: translate(-50%);
      left: 50%;
      top: 412px;
      display: flex;
    align-items: center;
    p{
        font-size: 28px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: white;
    text-shadow: 0px 6px 5px rgba(255, 210, 218, 0.56), 0px 6px 5px rgba(248, 210, 217, 0.56);
    margin-left: 13px;
    }
    }
    .bottom-button{
       
      button{
        position: absolute;
    transform: translate(-50%);
    left: 50%;
    top: 593px;
    text-align: center;
    width: 31px;
    height: 31px;
      }
    }
 }
 .level-bg1{
  background: url('../../../assets/images/Level1.png');
 }
 .level-bg2{
  background: url('../../../assets/images/Level2.png');
 }
 .level-bg3{
  background: url('../../../assets/images/Level3.png');
 }
 
 .dialog-close {
 position: absolute;
 right: 5px;
 top: 5px;
 width: 20px;
 height: 20px;
 text-align: center;
 cursor: pointer;
 }
 .dialog-close:hover {
 color: #4fc08d;
 }
 .dialog-buttonclose{
    position: absolute;
 right: 20px;
 top: 30px;
 /* width: 31px;
 height: 31px;
 text-align: center; */
 }
</style>