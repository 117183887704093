<template>
  <div class="examination">
    <div class="container">
      <div class="application-train">
        <!-- 标题栏 -->
        <!-- <ApMaTitleLine :title="$t('language.myCourses.CourseSelection')">
        </ApMaTitleLine> -->

        <!-- <div class="search-box">
          <div class="line">
           
            <ul>
              <li
                v-for="(item, index) in boxData"
                :key="index"
                :class="{
                  opt: true,
                  active: selectId === index,
                }"
                @click="doSelect(index, item.id)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div> -->
        <!-- 课程卡片 -->
        <div class="CoursesCard-box">
          <el-card shadow="hover" v-for="(item, index) in boxData" :key="index" class="card"
            :body-style="{ padding: '0px' }" @click.native="handleCard(item)">
            <img src="../../assets/images/carbg.jpg" class="image">
            <div style="padding:30px;">
              <span style="font-size: 20px;font-weight: normal;color: #0B151D;">{{ item.name }}</span>
              <!-- <div style="height:70px;overflow: auto;" class="bottom clearfix">
                <div class="classHour-title">当前课时：</div>
                <div class="classHour-content">
                  <div style="margin-top: 8px;" v-for="(item1, index2) in sessionName[index].data" :key="index2">
                    {{ item1.name }}
                  </div>
                </div>

              </div> -->
            </div>
            <div class="bottm">
              <div class="content">
                <!-- v-if="userinfo.competitor == 1" -->
                <el-button  type="text"
                  @click.stop="handleRankingList(item)">课程总排行榜</el-button>
                <!-- <el-button v-if="userinfo.collaborator == 1" type="text" class="content-button"
                  @click.stop="handleGroupMember(item)">小组成员</el-button>
                <el-button v-if="userinfo.collaborator == 1" type="text" class="content-button"
                  @click.stop="handleTrophyQuery(item)">团队奖章</el-button>
                <el-button v-if="userinfo.collaborator == 1 && item.teamScoreFlag" type="text" class="content-button"
                  @click.stop="handleTeamMutual(item)">组员互评</el-button> -->
              </div>
            </div>
          </el-card>

        </div>
        <!-- 培训列表 -->
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="$t('language.myCourses.TheCurrentSession')" name="now"></el-tab-pane>
          <el-tab-pane :label="$t('language.myCourses.AllSections')" name="history"></el-tab-pane>
        </el-tabs>
        <ul class="train-list" v-loading="loading">
          <template v-if="list.length"
            ><KnAnTrCard
              v-for="card in list"
              :cardData="card"
              :key="card.id"
              @change="selectSearch"
          /></template>
          <div class="no-data" v-else>{{$t("language.Nodatatemporarily")}}</div>
     
        </ul> -->
      </div>
    </div>
    <!-- <groupMember :isShow="dialogGroupMember"></groupMember> -->
    <!-- <el-dialog width="40%" :visible.sync="dialogTrophyQuery">
      <trophyQuery></trophyQuery>

    </el-dialog> -->
    <Dialog :isShow="dialogGroupMember" title="组员查询" @on-close="handleGroupMemberClose">
      <groupMember :groupMemberList = 'groupMemberList'></groupMember>
    </Dialog>
    <Dialog :isShow="dialogTrophyQuery" title="奖杯查询" @on-close="handleTrophyQueryClose">
      <trophyQuery :collaboratorTeamMeanScore="collaboratorTeamMeanScore"></trophyQuery>
    </Dialog>
    <Dialog :isShow="dialogConsentForm" :buttonShow="false" title="知情同意书">
      <ConsentForm @on-close="handleConsentFormClose" :ConsentForm="ConsentForm"></ConsentForm>
    </Dialog>
    <Dialog :isShow="dialogConsentForm2" :buttonShow="false" :title="NoticesData.title">
      <ConsentForm @on-close="handleConsentFormClose2" :ConsentForm="NoticesData.content"></ConsentForm>
    </Dialog>
    <Dialog :isShow="dialogTeamMutual" title="团队评分" @on-close="dialogTeamMutual =false">
      <TeamMutual :collaboratorTeamScore ='collaboratorTeamScore'  @on-close="handleTeamMutualClose"></TeamMutual>
    </Dialog>
    <div class="bah">
      <a href="https://beian.miit.gov.cn" target="_blank">ICP备:京ICP备2024071304号-1</a>
    </div>
  </div>
</template>

<script>
import ApMaTitleLine from "./components/ApMaTitleLine.vue";
import ApMaTitleLineSearchBox from "./components/ApMaTitleLineSearchBox.vue";
import KnAnTrSearch from "./components/KnAnTrSearch.vue";
import KnAnTrCard from "./components/KnAnTrCard.vue";
import pagination from "../../components/Pagination.vue";
import groupMember from "./components/groupMember.vue";
import trophyQuery from "./components/trophyQuery.vue";
import ConsentForm from "./components/ConsentForm.vue";
import TeamMutual from "./components/TeamMutual.vue";
import { modifyAgree } from '@/service/kcApi'
import { getCookie, setCookie,setToken } from "@/service/lib/localToken";

import { findMidStudentListByPage ,scoreCollaboratorTeam,getStudentNotices,readNotice} from "@/service/roleApi";
import { findCourseListByStudentId } from "@/service/userapi";
import { getConsentForm } from '@/service/roleApi'
import { findClassHourListByParam } from "@/service/myCourses";
export default {
  name: "myCourses",
  components: {
    ApMaTitleLine,
    ApMaTitleLineSearchBox,
    KnAnTrSearch,
    KnAnTrCard,
    pagination,
    groupMember,
    trophyQuery,
    ConsentForm,
    TeamMutual

  },
  data() {
    return {
      dialogGroupMember: false,
      dialogTrophyQuery: false,
      dialogTeamMutual: false,
      dialogConsentForm: false,
      ConsentForm: '',
      activeName: "now",

      selectId: 0,
      loading: false,
      pageSizes: [6, 12, 24, 36, 48],
      list: [],
      trainList: [],
      pager: {
        pageNum: 1,
        pageSize: 6,
        total: 0,
      },

      searchForm: {
        name: "",
        published: 1,
        moduleId: "",
        levelId: "",
      },
      ClassHourParam: {
        courseId: "",
        reqType: 0,
        studentId: getCookie("userId"),
      },
      boxData: [],
      sessionId: [],
      sessionName: [],
      userinfo: JSON.parse(getCookie("userdata")),
      groupMemberList:[],
      collaboratorTeamMeanScore:0,
      collaboratorTeamScore:0,
      collaboratorTeamcourseId:'',
      noticeslist:[],
      dialogConsentForm2:false,
      ConsentForm2:'',
      Noticestitle:'',
      NoticesData:{

      }

    };
  },
  mounted() {
    console.log(this.userinfo)
    if(!this.userinfo){
      setToken("");
      setCookie("userdata", "");
      this.$router.push({
        path: "/login",
        params: {
          from: "login",
        },
      });
    }
    this.getCourses();
    this.getnot()

    // if (!this.userinfo.agree) {
    //   getConsentForm().then(res => {
    //     console.log(res)
    //     console.log(res.data.content)
    //     this.ConsentForm = res.data.content
    //     this.dialogConsentForm = true
    //   })
    // }


  },
  methods: {
    // 已读通知
    handleConsentFormClose2() {
    
      let postData = {
        noticeId:this.NoticesData.noticeId,
        studentId:this.userinfo.id
      }
      console.log(postData)
      readNotice(postData).then(res =>{
        this.dialogConsentForm2 = false
       
        this.$nextTick(() => {
                  this.getnot()
                });
      })
    },
    // 获取未读公告
    getnot(){
      let postData ={
        studentId:this.userinfo.id,
        readStatus:'0',
        page:1,
        pageSize:999
      }
      getStudentNotices(postData).then(res => {
        console.log(res)
        this.noticeslist = res.data.list
        if(res.data.list.length >0){
          this.NoticesData  =res.data.list[0]
          // this.Noticestitle = res.data.list[0].title
          // this.ConsentForm2 = res.data.list[0].content
          this.dialogConsentForm2 = true
        }
      })
    },
    handleGroupMemberClose() {
      this.dialogGroupMember = false
    },
    handleGroupMember(item) {
      this.$addLog('ENTER_TEAM_MEMBER',item.id)
      findMidStudentListByPage({ studentId: this.userinfo.id, id: item.id, page: 1, pageSize: 1 }).then(res => {
        console.log(res.data.list[0])
        if (res.data.list[0].collaboratorTeam) {
          // 用自己的分组序号去查询学员
          findMidStudentListByPage({ collaboratorTeam: res.data.list[0].collaboratorTeam, id: item.id, page: 1, pageSize: 999 }).then(res2 => {
            console.log(res2)
            this.groupMemberList = res2.data.list
            this.dialogGroupMember = true
        
          })

        
        } else {
          this.$message({
            message: "暂未设置分组，请联系管理员",
            type: "warning",
          });
        }
      })

    },
    handleTrophyQuery(item) {
      this.$addLog('ENTER_BEST_TEAM',item.id,)
      findMidStudentListByPage({ studentId: this.userinfo.id, id: item.id, page: 1, pageSize: 1 }).then(res => {
        console.log(res.data.list[0].collaboratorTeamMeanScore)
        if (res.data.list[0].collaboratorTeam) {
          // 用自己的分组序号去查询学员
          this.dialogTrophyQuery = true
          this.collaboratorTeamMeanScore = Number(res.data.list[0].collaboratorTeamMeanScore) 

        
        } else {
          this.$message({
            message: "暂未设置分组，请联系管理员",
            type: "warning",
          });
        }
      })
  
    },
    handleTeamMutual(item) {
      this.$addLog('ENTER_TEAM_EVAL',item.id)
      findMidStudentListByPage({ studentId: this.userinfo.id, id: item.id, page: 1, pageSize: 1 }).then(res => {
        console.log(res.data.list[0].collaboratorTeamScore)
        if (res.data.list[0].collaboratorTeam) {
          // 用自己的分组序号去查询学员
          this.dialogTeamMutual = true
        this.collaboratorTeamScore = res.data.list[0].collaboratorTeamScore
          this.collaboratorTeamcourseId = item.id
        
        } else {
          this.$message({
            message: "暂未设置分组，请联系管理员",
            type: "warning",
          });
        }
      })
    
    },
    handleTeamMutualClose(data) {
      console.log(data)
      if(!data.is){
        let postdata ={
          courseId:this.collaboratorTeamcourseId,
          score:data.value1,
          studentId:this.userinfo.id
        }
        scoreCollaboratorTeam(postdata).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        })
      }
      this.dialogTeamMutual = false
    },
    handleTrophyQueryClose() {
      this.dialogTrophyQuery = false
    },
    handleConsentFormClose() {
      this.dialogConsentForm = false
      let postData = {
        id: this.userinfo.id,
        agree: 1
      }
      modifyAgree(postData).then(res => {
        console.log(res)
        this.userinfo.agree = 1
        setCookie('userdata', JSON.stringify(this.userinfo));
      })
    },
    handleRankingList(item) {
      this.$addLog('ENTER_COURSE_RANK',item.id)
      console.log('item', item);
      this.$router.push({
        path: "/rankingList",
        query: {
          RouterCourseId: item.id,
          courseName: item.name,




        },
      });
    },
    handleCard(item) {

      this.$router.push({
        path: "/myClassHour",
        query: {
          courseId: item.id,
          courseName: item.name,
          cardData: item

        },
      });
    },
    async getCourses() {
      let id = getCookie("userId");
      console.log("getCookieid", id);
      await findCourseListByStudentId({ id: id }).then((res) => {
        if (res.code == "000" && res.data) {

          this.boxData = res.data;
          this.ClassHourParam.courseId = res.data[0].id;
          this.getClassHour();
          this.sessionId = Array.from(res.data, ({ id }) => id);

          let proList = []
          this.sessionId.forEach((elemt, key) => {


            proList.push(
              new Promise((resolve, reject) => {
                findClassHourListByParam({
                  courseId: elemt,
                  reqType: 0,
                  studentId: getCookie("userId")
                })

                  .then(res => {

                    return resolve(res)
                  }).catch(_ => {
                    return resolve('') // 注意
                  })
              })

            )
          })

          Promise.all(proList).then(res => {
            console.log('123', res)  // [1,2,3,4,5,6,7,8,9,10]
            this.sessionName = res
            console.log('this.sessionName', this.sessionName);

          }).catch(_ => {
            // 错误时的处理
          })






        }
      });
      console.log('this.sessionId', this.sessionId);
      console.log('boxData', this.boxData);
      console.log('this.sessionName', this.sessionName);
    },
    doSelect(index, id) {
      this.selectId = index;
      this.ClassHourParam.courseId = id;
      this.getClassHour();
      console.log(index, id, this.selectId);
      // if (this.selectIds !== id) {
      //   this.$set(this.selectIds, idx, id);
      //   this.$emit("change", this.selectIds);
      // }
    },
    //课程id和类型获取课时列表
    getClassHour() {
      findClassHourListByParam(this.ClassHourParam).then((res) => {
        if (res.code == "000" && res.data) {
          // console.log("findClassHourListByParam", res);
          this.list = res.data;
        }
      });
    },
    handleToSearch() { },
    selectSearch() { },
    change() { },
    handleCurrentChange() {
      this.getClassHour()
    },
    handleClick() {
      console.log(this.activeName);
      if (this.activeName == "now") {
        this.ClassHourParam.reqType = 0;
        this.getClassHour();
      } else {
        this.ClassHourParam.reqType = 1;
        this.getClassHour();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/scss/mixin.scss";
@import "/src/assets/scss/config.scss";

.examination {
  margin-top: 25px;

  .application-train {
    box-sizing: border-box;
    padding-top: 0px !important;

    // background: #fafbfc;
    .search-box {
      position: relative;
      margin: 0 auto;
      width: 1440px;
      padding: 0.520833rem 0;
      border-bottom: 0.052083rem dashed #c3cad6;

      .line {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .label {
          margin: 0 1.041667rem 0 0;
          font-size: 1.041667rem;
          font-weight: bold;
          color: #7c8697;
          line-height: 2.083333rem;
          cursor: default;
        }

        ul {
          display: flex;
        }

        .opt {
          margin-right: 1.145833rem;
          font-size: 1.041667rem;
          font-weight: bold;
          color: #324057;
          line-height: 2.083333rem;
          cursor: pointer;
          transition: 0.5s;

          &.active {
            color: #1d7be3;
            cursor: default;
          }
        }
      }
    }

    .CoursesCard-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .card {
        width: 320px;
        height: 300px;
        // padding-bottom: 100px;
        border-radius: 8px;
        margin-right: 40px;
        position: relative;
        cursor: pointer;

        .classHour-title {
          font-size: 14px;
          color: #999999;
          margin-bottom: 15px;
        }

        .classHour-content {
          font-size: 14px;
          color: #999999;

        }

        .bottm {
          border-top: 1px solid #F5F5F5;
          padding: 22px 10px 22px 10px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;

          .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

        }

        .bottom {
          margin-top: 13px;
          line-height: 12px;
        }

        .button {
          padding: 0;
          float: right;
        }

        .image {
          width: 100%;
          display: block;
        }

        .clearfix:before,
        .clearfix:after {
          display: table;
          content: "";
        }

        .clearfix:after {
          clear: both
        }
      }

    }

    .train-list {
      position: relative;
      margin: 0 auto;
      width: 1440px;
      padding-bottom: 2.604167rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      .card {
        margin: 0 14px 14px 0;

        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }

      .pagination-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .no-data {
    min-height: 50vh;
    margin: auto;
    box-sizing: border-box;
    padding-top: 10%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .el-dialog {
    border-radius: 8px;
  }
}</style>
