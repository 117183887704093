<template>
  <div>
    <div class="search-box">
      <div class="line" v-for="(item, idx) in boxData" :key="idx">
        <div class="label">{{ item.label }}</div>
        <ul>
          <li
            v-for="(option, optIdx) in [
              { dictValue: -1, dictLabel: '全部' },
              ...item.opt,
            ]"
            :key="optIdx"
            :class="{
              opt: true,
              active: selectIds[idx] === option.dictValue,
            }"
            @click="doSelect(idx, option.dictValue)"
          >
            {{ option.dictLabel }}
          </li>
        </ul>
      </div>
    </div>
    <div class="result-count">
      <p>
        筛选（<span>{{ resultCount }}</span
        >条结果）
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectIds: [],
    };
  },
  props: {
    boxData: {
      type: Array,
      default: () => [],
    },
    defaultSelectIds: {
      type: Array,
      default: () => [],
    },
    resultCount: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    boxData(val) {
      val.forEach((item, idx) => {
        if (
          item.opt.findIndex((elem) => elem.dictValue === this.selectIds[idx]) <
          0
        ) {
          this.$set(this.selectIds, idx, -1);
        }
      });
    },
  },
  mounted() {
    this.boxData.forEach((item, idx) => {
      if (
        item.opt.findIndex((elem) => elem.dictValue === this.selectIds[idx]) < 0
      ) {
        if (typeof this.defaultSelectIds[idx] === "undefined") {
          this.$set(this.selectIds, idx, -1);
        } else {
          this.$set(this.selectIds, idx, this.defaultSelectIds[idx]);
        }
      }
    });
  },
  methods: {
    doSelect(idx, id) {
      if (this.selectIds !== id) {
        this.$set(this.selectIds, idx, id);
        this.$emit("change", this.selectIds);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  padding: 0.520833rem 0;
  border-bottom: 0.052083rem dashed #c3cad6;
  .line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .label {
      margin: 0 1.041667rem 0 0;
      font-size: 1.041667rem;
      font-weight: bold;
      color: #7c8697;
      line-height: 2.083333rem;
      cursor: default;
    }
    ul {
      display: flex;
    }
    .opt {
      margin-right: 1.145833rem;
      font-size: 1.041667rem;
      font-weight: bold;
      color: #324057;
      line-height: 2.083333rem;
      cursor: pointer;
      transition: 0.5s;
      &.active {
        color: #1d7be3;
        cursor: default;
      }
    }
  }
}
.result-count {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  font-size: 14px;
  color: #9da4b1;
  line-height: 3.229167rem;
  span {
    color: #324057;
  }
}
</style>