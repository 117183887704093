<template>
  <div>
    <div class="search-box">
      <div class="line" >
        <!-- <div class="label">{{ item.label }}</div> -->
         <div class="label">课程</div>
        <ul>
          <li
         v-for="(item, idx) in boxData" :key="idx"
            :class="{
              opt: true,
              active: selectId === item.id,
            }"
            @click="doSelect(idx, item.id)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectId:'',
    };
  },
  props: {
    boxData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
 
  },
  mounted() {
    console.log('boxData',this.boxData)
    this.selectId = this.boxData[0].id  
  },
  methods: {
    doSelect(idx, id) {
      console.log(idx,id,this.selectIds)
      // if (this.selectIds !== id) {
      //   this.$set(this.selectIds, idx, id);
      //   this.$emit("change", this.selectIds);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  padding: 0.520833rem 0;
  border-bottom: 0.052083rem dashed #c3cad6;
  .line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .label {
      margin: 0 1.041667rem 0 0;
      font-size: 1.041667rem;
      font-weight: bold;
      color: #7c8697;
      line-height: 2.083333rem;
      cursor: default;
    }
    ul {
      display: flex;
    }
    .opt {
      margin-right: 1.145833rem;
      font-size: 1.041667rem;
      font-weight: bold;
      color: #324057;
      line-height: 2.083333rem;
      cursor: pointer;
      transition: 0.5s;
      &.active {
        color: #1d7be3;
        cursor: default;
      }
    }
  }
}
.result-count {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  font-size: 14px;
  color: #9da4b1;
  line-height: 3.229167rem;
  span {
    color: #324057;
  }
}
</style>