<template>
  <div id="app">

    <router-view />

  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  mounted() {
  },

  methods: {

  }
};
</script>
<style  lang='scss'>
@import './assets/scss/reset.scss';
@import './assets/scss/config.scss';
@import './assets/scss/button.scss';

#app {
  /*max-width: 1440px;*/
  margin: 0 auto;
}

.bah {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  font-size: 12px;
  text-align: center;
  color: #666666;
  line-height: 40px;

  a {
    color: #666666;
  }
}
.bah2 {

  width: 100%;
  bottom: 0;
  left: 0;
  font-size: 12px;
  text-align: center;
  color: #666666;
  line-height: 40px;

  a {
    color: #666666;
  }
}
</style>
