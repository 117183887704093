

// let VUE_APP_APIHOST = 'http://124.222.5.115/study-manager/'
let VUE_APP_APIHOST = 'https://admin.goplayedu.com/study-manager/'
// let VUE_APP_APIHOST = 'http://192.168.0.110:8008/study-manager'
// let VUE_APP_APIHOST = 'http://124.222.5.115:9090/study-manager'
// let VUE_APP_file = 'http://124.222.5.115/study-manager/module/file/fileUpload'
let VUE_APP_file = 'https://admin.goplayedu.com/study-manager/module/file/fileUpload'
// let VUE_APP_file = 'http://124.222.5.115:9090/study-manager/module/file/fileUpload'
export default VUE_APP_APIHOST

// http://admin.goplayedu.com/#/classHour