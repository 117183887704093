<template>
  <div class="classDiscussion">
    <div class="container">
      <div   class="classDiscussion-title">
        <!-- <img src="../../../assets/images/back.png" alt=""> -->
        <el-button class="title-bottom" type="info" icon="el-icon-back" circle @click="$router.go(-1)"></el-button>
      
        <p class="title-text">{{ subjectName}}</p>
      </div>
      <div class="container-subject">
        <!-- <h1 class="container-title">{{ subjectName }}</h1> -->
        <div class="container-topic" v-html="subjectContent"></div>
      </div>
      
      <div style="text-align: left; margin: 35px 0 35px 0">
        <span> 排序：</span>
        <el-radio-group @input="handleSelect" v-model="InteractDetailParms.sort">
    <el-radio :label="3">时间倒序</el-radio>
    <el-radio :label="2">时间正序</el-radio>
    <el-radio :label="0">点赞倒序</el-radio>
    <el-radio :label="1">点赞正序</el-radio>
  </el-radio-group>
        <!-- <el-select
          v-model="InteractDetailParms.sort"
          placeholder="请选择排序方式"
          @change="handleSelect"
        >
          <el-option
            v-if="userdata.groupType != 0"
            label="点赞正序"
            :value="1"
          ></el-option>
          <el-option
            v-if="userdata.groupType != 0"
            label="点赞倒序"
            :value="0"
          ></el-option>
          <el-option
            label="时间正序"
            :value="2"
          ></el-option>
          <el-option
            label="时间倒序"
            :value="3"
          ></el-option>
        </el-select> -->
      </div>
      <ul class="container-ul">
        <li
          v-for="(item, index) in subjectList"
          :key="item.id"
          class="container-li"
        >
          <div class="commentbox">
            <el-row>
              <el-col :span="4">
                <div class="commentbox-user">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="item.picturePath"
                    fit="fill"
                  >
                    <div slot="error" class="image-slot">
                      <el-image
                        style="width: 100px; height: 100px;
border-radius: 8px;"
                        :src="url"
                        fit="fill"
                      >
                      </el-image>
                    </div>
                  </el-image>
                  <p class="username">{{ item.createBy }}</p>
                </div>
              </el-col>
              <el-col :span="20" style="padding: 20px;background: #FFFFFF;">
                <div class="commentbox-content">{{ item.answerContent }}</div>

                <div class="commentbox-bottom">
                  <div>
                    <div
                      @click="handleLike(item)"
                      style="cursor: pointer"
                      v-if="userdata.groupType != 0"
                    >
                      <img
                        v-if="item.tag == 0"
                        style="width: 15px; height: 15px"
                        src="../../assets/images/ck_icon_dz2.png"
                        alt=""
                      />
                      <img
                        v-else
                        src="../../assets/images/ck_icon_dz1.png"
                        alt=""
                      />
                      <span class="commentbox-bottom-time">{{
                        "(" + item.praiseNumber + ")"
                      }}</span>
                    </div>
                  </div>

                  <div class="commentbox-bottom-time">
                    {{ formatDate(item.createTime) }}
                  </div>
                  <div class="commentbox-bottom-btn" @click="handlereply(item,index)">
                    {{
                      item.isShow
                        ? $t("language.myCourses.PackUp")
                        : $t("language.myCourses.Viewhereply") +
                          "(" +
                          item.replyNumber +
                          ")"
                    }}
                  </div>
                </div>
                <transition name="fade">
                  <div class="commentbox-duplex" v-show="item.isShow">
                    <ul>
                      <li v-for="row in item.details" :key="row.id">
                        <div class="commentbox-duplex-box">
                          <el-avatar
                            shape="square"
                            size="small"
                            :src="row.picturePath"
                          ></el-avatar>
                          <span class="username"
                            >{{ row.replyName }}
                            <span
                              v-if="row.parentName != null && row.parentName"
                              >{{
                                $t("language.myCourses.Reply") +
                                row.parentName +
                                "："
                              }}</span
                            >
                          </span>
                          <span>{{ row.replyContent }}</span>
                        </div>
                        <div class="commentbox-duplex-bottom">
                          <div class="commentbox-duplex-bottom-time">
                            {{ formatDate(row.createTime) }}
                          </div>
                          <div
                            class="commentbox-duplex-bottom-btn"
                            @click="handleDuplexReply(row)"
                          >
                            {{ $t("language.myCourses.Reply") }}
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="commentbox-duplex-input">
                      <el-input
                        v-model="item.replyContent"
                        :placeholder="placeholder"
                      ></el-input>
                      <el-button
                        @click="handleLevelAnnounce(item, index)"
                        style="margin: 20px 0 10px 0;width: 104px;
height: 34px;
background: linear-gradient(90deg, #0160A3, #349BE3);
border-radius: 8px;"
                        type="primary"
                      >
                        {{ $t("language.myCourses.publish") }}</el-button
                      >
                    </div>
                  </div>
                </transition>
              </el-col>
            </el-row>
          </div>
        </li>
      </ul>
      <!-- <div class="no-data"  >暂时还无人发言!</div> -->
      <div style="text-align: right; margin: 10px">
        <pagination
          :total.sync="InteractDetailParms.total"
          :page.sync="InteractDetailParms.page"
          :pageSizes="pageSizes"
          :limit.sync="InteractDetailParms.pageSize"
          @pagination="handleCurrentChange"
        />
      </div>

      <div style="width: 1220px; margin: 10px auto">
        <el-input
          type="textarea"
          :rows="10"
          placeholder="请输入内容"
          v-model="answerParms.answerContent"
        >
        </el-input>
        <el-button
          @click="handleAnnounce"
          style="margin: 15px 0 30px 0; float: right;width: 104px;
height: 34px;background: linear-gradient(90deg, #0160A3, #349BE3);
border-radius: 8px;"
          type="primary"
        >
          {{ $t("language.myCourses.publish") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  tProjectClassHourInteract,
  tProjectInteractDetail,
  addHourInteract,
  findInteractPraiseByChild,
  addInteractPraise,
} from "@/service/classDiscussion";
import { getCookie } from "@/service/lib/localToken";
import pagination from "../../components/Pagination.vue";
import dayjs from "dayjs";
import { DebounceBy } from "@/utlis/DebounceBy";
export default {
  name: "classDiscussion",
  components: { pagination },
  data() {
    return {
      pageSizes: [10, 20, 30, 40, 50],
      placeholder: "请输入内容",
      DuplexReplyID: "",
      input: "",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      isShow: true,
      textarea: "",
      id: this.$route.query.fkId,
      AfterClassEnd: true,
      detailParentId: "",
      subjectList: [],
      subjectContent: "",
      subjectName: "",
      userdata: JSON.parse(getCookie("userdata")),
      answerParms: {
        answerContent: "",
        hourId: this.$route.query.fkId,
        studentId: getCookie("userId"),
      },
      interactParms: {
        detailParentId: "",
        hourId: this.$route.query.fkId,
        interactId: "",
        replyContent: "",
        studentId: getCookie("userId"),
      },
      InteractDetailParms: {
        hourId: this.$route.query.fkId,
        studentId: getCookie("userId"),
        sort: 3,
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {},
  mounted() {
    this.getTProjectInteractDetail();

    if (this.$route.query.AfterClassEnd == 0) {
      this.AfterClassEnd = false;
    } else {
      this.AfterClassEnd = true;
    }
  },
  methods: {
    formatDate(data) {
      return dayjs(data).format("YYYY-MM-DD HH:mm:ss");
    },
    getTProjectInteractDetail(index) {
      tProjectClassHourInteract(this.InteractDetailParms).then((res) => {
        console.log(res);
        this.subjectContent = res.data.subjectContent;
        this.subjectName = res.data.subjectName;
        res.data.pageData.list.forEach((element) => {
          element.replyContent = "";
          element.isShow = false;
          element.details = [];
        });
        console.log('index1',index);
        if (index) {
          console.log('index0',index);
          res.data.pageData.data[index].isShow = false;
        }
        this.subjectList = res.data.pageData.list;
        this.InteractDetailParms.total = res.data.pageData.total;
      });
    },

    handleAnnounce: DebounceBy(function (value) {
      addHourInteract(this.answerParms).then((res) => {
        console.log("res", res);
        if (res.code == "000") {
          this.$message({
            message: "发表成功",
            type: "success",
          });
          location.reload();
        }
      });
    }, 1000),
    //楼中楼发表

    handleLevelAnnounce: DebounceBy(function (item, index) {
      console.log(item);

      let interactParms = { ...this.interactParms };
      interactParms.interactId = item.id;
      interactParms.replyContent = this.subjectList[index].replyContent;
      console.log(this.subjectList[index].replyContent);

      tProjectInteractDetail(interactParms).then((res) => {
        console.log("res", res);
        if (res.code == "000") {
          this.$message({
            message: "发表成功",
            type: "success",
          });
          item.replyContent = "";
          // location.reload()
          // this.getTProjectInteractDetail(index);
          findInteractPraiseByChild({ interactId: item.id }).then((res) => {
            if (res.code == "000") {
              item.details = res.data;
            }
          });
        }
      });
    }, 1000),
    handlereply(item,index) {
      console.log("item", item);
      this.placeholder="请输入内容"
      if (!item.isShow) {
        findInteractPraiseByChild({ interactId: item.id }).then((res) => {
          if (res.code == "000") {
            item.details = res.data;
            item.isShow = !item.isShow;
          }
        });
      } else {
        this.getTProjectInteractDetail(index);
        item.isShow = !item.isShow;
      }
    },
    handleDuplexReply(row) {
      console.log(row);
      this.placeholder = `回复${row.createBy}：`;
      this.interactParms.detailParentId = row.id;
    },
    //翻页事件
    handleCurrentChange(val) {
      console.log("val", val);
      this.InteractDetailParms.page = val.page;
      this.InteractDetailParms.pageSize = val.limit;
      this.getTProjectInteractDetail();
    },
    //排序选择框改变事件
    handleSelect(val) {
      console.log("val", val);
      this.interactParms.sort = val;
      this.getTProjectInteractDetail();
    },
    //点赞
    handleLike(item) {
      if (item.tag == 0) {
        let studentId = getCookie("userId");
        if (item.studentId == studentId) {
          if (this.$i18n.locale == "zh-CN") {
            this.$message({
              message: "无法给自己点赞！",
              type: "warning",
            });
          } else {
            this.$message({
              message: "Can't give yourself a 'like'!",
              type: "warning",
            });
          }
        }
        //  else if (this.AfterClassEnd == false) {
        //   if (this.$i18n.locale == "zh-CN") {
        //     this.$message({
        //       message: "对不起，课后讨论已结束无法点赞！",
        //       type: "warning",
        //     });
        //   } else {
        //     this.$message({
        //       message:
        //         " Sorry, the discussion is over. You are unable to give 'likes'!",
        //       type: "warning",
        //     });
        //   }
        // } 
        else {
          addInteractPraise({ id: item.id, studentId: studentId }).then(
            (res) => {
              if (res.code == "000") {
                this.getTProjectInteractDetail();
              }
            }
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.classDiscussion {
  .container {
    width: 1220px;
    margin-top: 20px;
    .classDiscussion-title{
      position: relative;
  text-align: center;
  margin: 20px 0 20px 0;
  height: 150px;
 .title-bottom{
  position: absolute;
  left: 0;
  
 }
  .title-text{
    font-size: 32px;
font-family: Adobe Heiti Std;
font-weight: bold;
 
color: #303133;
    font-size: 32px;
 
 
  }
    }
    .container-subject {
      border: 1px solid #e1e4e6;
      margin-bottom: 15px;
      padding: 15px;
      .container-title {
        margin: auto;
        text-align: center;
      }
      .container-topic {
        margin: auto;
        // text-align: center;
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }

    .container-ul {
      width: 1220px;
      margin: auto;
      li:first-child{
        
border-radius: 8px 8px 0px 0px;
      }
       
      .container-li {
        height: auto;
        min-height: 170px;
        border: 1px solid #e1e4e6;
        background: #F5F7FB;
        .commentbox {
          .commentbox-user {
            padding: 40px 40px 0 40px;
            text-align: center;
            // background:red;
            // min-height: 170px;
            // height: auto;
            .username {
              margin-top: 15px;
              color: #2d64b3;
            }
          }
          .commentbox-content {
            padding: 20px;
            height: auto;
            min-height: 170px;
            
color: #666666;
          }
          .commentbox-bottom {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 5px;
            .commentbox-bottom-time {
             
color: #666666;
              margin-right: 5px;
            }
            .commentbox-bottom-btn {
             
color: #0160A3;
              cursor: pointer;
            }
          }
          .commentbox-duplex {
            padding: 4px 15px 14px;
            background: #f7f8fa;
            li {
              padding-top: 10px;
            }
            .commentbox-duplex-box {
              display: flex;
              align-items: center;
              .username {
                color: #2d64b3;
                margin: 0 3px 0 10px;
              }
            }
            .commentbox-duplex-bottom {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .commentbox-duplex-bottom-time {
                color: #666666;
                margin-right: 5px;
              }
              .commentbox-duplex-bottom-btn {
                color: #2d64b3;
                cursor: pointer;
              }
            }
            .commentbox-duplex-input {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-end;

              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  .no-data {
    min-height: 50vh;
    margin: auto;
    box-sizing: border-box;
    padding-top: 10%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
