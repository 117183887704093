import request from '@/service/lib/request';
// 获取知情同意书
export function getConsentForm() {

  return request({
    url: '/module/tInformConsentForm/getConsentForm',
    method: 'get'
  });

}
// 修改同意书
export function saveOrUpdateConsentForm(data) {

  return request({
    url: '/module/tInformConsentForm/saveOrUpdateConsentForm',
    method: 'post',
    data
  });
}
export function getrole() {

  return request({
    url: '/module/tCdmRole?page=1&size=999',
    method: 'get'
  });
}
// 修改角色规则
export function SettCdmRole(data) {

  return request({
    url: '/module/tCdmRole/' + data.idwd + '/modify',
    method: 'post',
    data
  });
}
// 新增题目
export function addCourseSubjectList(data) {
  return request({
    url: 'module/tCdmRoleSubject/batchInsertOrUpdateCdmRoleSubject',
    method: 'post',
    data
  });

}
export function findSubjectListByFkId(data) {
  return request({
    url: 'module/tCdmRoleSubject/findCdmRoleSubjectPage',
    method: 'post',
    data
  });

}
//删除
export function deleteSubjectById(data) {
  return request({
    url: 'module/tCdmRoleSubject/' + data.id + '/delete',
    method: 'post',
    data
  });

}
export function importspecialInfo (file)  {
  // new 一个formDate表单对象
  const formData = new FormData();
  // 把文件添加到表单对象中
  formData.append('file', file);
  return request({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: '/module/tProjectStudent/importStudentCdmRole',
    method: 'post',
    data: formData
  });
}
// 提交题目
export function submitCdmSubjectAnswer(data) {
  return request({
    url: 'module/tCdmRoleSubjectHistory/submitCdmSubjectAnswer',
    method: 'post',
    data
  });

}


// 获取元素配置
export function getAutonomousButton(id) {
  return request({
    url: 'module/tAutonomousButton/getAutonomousButton/'+id,
    method: 'get'
  });

}
// 设置元素
export function saveOrUpdateAutonomousButton(data) {
  return request({
    url: 'module/tAutonomousButton/saveOrUpdateAutonomousButton',
    method: 'post',
    data
  });

}

// 设置元素日志
export function tHanldeLog(data) {
  return request({
    url: 'module/tHanldeLog',
    method: 'post',
    data
  });

}
// 查询团队
export function findMidStudentListByPage(data) {
  return request({
    url: 'module/tMidStudentCourse/findMidStudentListByPage',
    method: 'post',
    data
  });

}
// 团队评分
export function scoreCollaboratorTeam(data) {
  return request({
    url: 'module/tMidStudentCourse/scoreCollaboratorTeam',
    method: 'post',
    data
  });

}
// 查询未读公告
export function getStudentNotices(data) {
  return request({
    url: '/module/notice/getStudentNotices',
    method: 'post',
    data
  });

}
// 已读公告
export function readNotice(data) {
  return request({
    url: '/module/notice/readNotice/'+data.noticeId+'/'+data.studentId,
    method: 'post',
    data
  });

}

// 已读介绍
export function modifyReadIntroduce(data) {
 
  return request({
    url: '/module/tProjectStudent/modifyReadIntroduce/'+data.studentId,
    method: 'PUT',
    data
  });

}

//  学生点击记录
export function addCountByType(data) {
 
  return request({
    url: '/module/tProjectStudent/addCountByType/'+data.studentId+'/'+data.type,
    method: 'PUT',
    data
  });

}


export function getYX(data) {
  return request({
    url: 'module/tGameElementRule/findGameElementRuleList',
    method: 'post',
    data
  });

}