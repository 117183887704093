<template>
  <div class="trophyQuery">

    <div class="content">
      <!-- <div class="content-search">
              <el-input
      placeholder="请输入您要查询的奖杯名称"
      v-model="input4">
      <i slot="suffix" class="el-input__icon el-icon-search"></i>
    </el-input>
          </div> -->
      <div class="content-result">
        <div class="content-result-left">


          <img v-if="collaboratorTeamMeanScore >= 5" src="../../../assets/images/dy1.png" alt="" style="margin-right: 10px;">
          <img v-else src="../../../assets/images/dy2.png" alt="" style="margin-right: 10px;">
          <div class="introduce">
            <p class="title">最佳团队奖</p>
            <p class="describe">团队平均分得分：{{ collaboratorTeamMeanScore }}分</p>
          </div>




        </div>
        <div class="content-result-right ">
          <div :class="collaboratorTeamMeanScore >= 5 ?'text':'text-done'"> {{ collaboratorTeamMeanScore >= 5 ? '已获得' : '未获得' }} </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'trophyQuery',
  props: {

    collaboratorTeamMeanScore: {
      type: Number,
      default: 0
    },

  },

  data() {
    return {
      input4: ''
    }
  }
}
</script>
  
<style lang="scss" scoped>
.trophyQuery {
  text-align: center;

  .title {
    font-size: 24px;
    font-family: Adobe Heiti Std;
    font-weight: bold;
    color: #030303;
  }

  .content {
    margin: 40px auto;
    width: 90%;

    .content-search {
      margin-bottom: 25px;
    }

    .content-result {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content-result-left {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .introduce {
          margin-left: 5px;
          text-align: left;

          .title {
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: bold;
            color: #1A1A1A;
            margin-bottom: 5px;
          }

          .describe {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #C7C8C8;
          }
        }
      }

      .content-result-right {
        .text {
          width: 100px;
          height: 42px;
          background: linear-gradient(0deg, #E74C32, #F19826, #F8CA66);
          border-radius: 21px;
          line-height: 42px;

          color: #FFFFFF;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
        }
        .text-done {
          width: 100px;
          height: 42px;
          background: rgba(206, 206, 206, 1);
          border-radius: 21px;
          line-height: 42px;

          color: #FFFFFF;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          cursor: pointer;
        }
      }
    }
  }
}</style>