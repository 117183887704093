<template>
  <div class="classDiscussion">
    <div class="container">
      <div v-if="this.$route.query.RouterCourseId || this.$route.query.list" class="classHour-title">
        <!-- <img src="../../../assets/images/back.png" alt=""> -->
        <el-button class="title-bottom" type="info" icon="el-icon-back" circle @click="$router.go(-1)"></el-button>

        <p class="title-text">{{ this.$route.query.courseName }}</p>
      </div>
      <!-- <h1 style="    text-align: center;
    margin: 20px 0 20px 0;">{{ $t("language.MyLeaderboard.LeaderboardSum") }}</h1> -->
      <div v-else class="block" style="text-align: center; margin: 20px 0 20px 0;">

        <el-carousel @change="handleCar" indicator-position="none" :autoplay="false" arrow="always" height="150px">
          <el-carousel-item v-for="(item, index) in boxData" :key="index">
            <h3 class="small">{{ item.name }}</h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="search-box">
          <div class="line">
            <div class="label">{{$t("language.myCourses.Course")}}</div>
            <ul>
              <li
                v-for="(item, index) in boxData"
                :key="index"
                :class="{
                  opt: true,
                  active: selectId === index,
                }"
                @click="doSelect(index, item.id)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div> -->
      <div class="table-box">
        <el-row class="table-th">
          <el-col :span="4">
            <div class=" ">排名</div>
          </el-col>
          <el-col :span="3">
            <div class=" ">头像/昵称</div>
          </el-col>
          <el-col :span="2">
            <div class=" ">积分</div>
          </el-col>
          <el-col :span="15">
            <div class=" "></div>
          </el-col>
        </el-row>
        <el-row :class="[item.studentId == userid ? 'table-td-active' : 'table-td']" type="flex" justify="start"
          v-for="(item, index) in tableData" :key="index">
          <el-col :span="4">
            <div class="table-imgbox">
              <img v-if="index + 1 == 1" src="../../assets/images/NO1.png" alt="">
              <img v-else-if="index + 1 == 2" src="../../assets/images/NO2.png" alt="">
              <img v-else-if="index + 1 == 3" src="../../assets/images/NO3.png" alt="">
              <div style="padding:26px 0 28px 0" v-else>{{ index + 1 }}</div>
            </div>
          </el-col>
          <el-col :span="3">
            <div class=" table-avatarName">
              <div class="box">
                <el-avatar class="avatar" size="small" :src="
                  item.picturePath
                    ? item.picturePath
                    : 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
                ">
                </el-avatar>
                <div class="userName">{{ item.nickName }}</div>

              </div>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="table-integral">{{ item.totalIntegral }}</div>
          </el-col>
          <el-col :span="15">
            <div v-if="item.studentId == userid && index == 0" class="remark">恭喜你，你是第一名！</div>
            <div v-if="item.studentId == userid && index !== 0" class="remark">请继续加油，你距离上一名还差{{
              tableData[index - 1].totalIntegral - item.totalIntegral }}分</div>
          </el-col>
        </el-row>

        <!-- <el-table
      :data="tableData"
      style="margin:auto">
      <el-table-column
        label="排名"
        type="index"
        width="80"
       
        >
         
      </el-table-column>
         <el-table-column prop="picturePath"  label="头像/昵称" width="">
          <template slot-scope="scope">
            <div style="display: flex;
    align-items: center;">

        
            <img style="margin-right:10px;border-radius: 24px;" :src="scope.row.picturePath" alt="" width="48" height="48" />
            <span>{{ scope.row.nickName }}</span>
          </div>
          </template>
          
        </el-table-column>
      <el-table-column
        prop="nickName"
        label="昵称"
       >
      </el-table-column>
 
           <el-table-column prop="totalIntegral" sortable  label="总积分" ></el-table-column>
    </el-table> -->
      </div>

    </div>
  </div>
</template>

<script>
import {
  querySumTotalIntegral
} from "@/service/rankingList";
import { findCourseListByStudentId } from "@/service/userapi";
import { getCookie } from "@/service/lib/localToken";
export default {
  name: "rankingList",
  data() {
    return {
      userid: getCookie("userId"),
      tableData: [],
      parms: {

        courseId: "",
        groupType:''
      },
      boxData: [],
      selectId: 0,
    }
  },
  mounted() {


    if (this.$route.query.RouterCourseId) {
      console.log('123', this.$route.query.RouterCourseId);
      this.parms.courseId = this.$route.query.RouterCourseId
      this.init()
    }else if(this.$route.query.list){
      console.log('this.$route.query.list',this.$route.query.list);
      
      this.tableData = this.$route.query.list
      console.log('this.boxData',this.boxData);
      
    } else {
      this.getCourses();
    }

  },
  methods: {



    handleCar(now, old) {
      console.log('now', now);
      console.log('old', old);
      console.log('boxData', this.boxData[now].id);
      this.parms.courseId = this.boxData[now].id;
      this.init();
    },
    getCourses() {
      let id = getCookie("userId");
      console.log("getCookieid", id);
      findCourseListByStudentId({ id: id }).then((res) => {
        if (res.code == "000" && res.data) {
          this.boxData = res.data;
          this.parms.courseId = res.data[0].id;
          this.init();
        }
      });
    },
    doSelect(index, id) {
      this.selectId = index;
      this.parms.courseId = id;
      this.init();
      console.log(index, id, this.selectId);
      // if (this.selectIds !== id) {
      //   this.$set(this.selectIds, idx, id);
      //   this.$emit("change", this.selectIds);
      // }
    },

    init() {
      querySumTotalIntegral(this.parms).then(res => {
        this.tableData = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .classHour-title {
    position: relative;
    text-align: center;
    margin: 20px 0 20px 0;
    height: 150px;

    .title-bottom {
      position: absolute;
      left: 0;

    }

    .title-text {
      font-size: 32px;
      font-family: Adobe Heiti Std;
      font-weight: bold;

      color: #303133;
      font-size: 32px;


    }

  }

  .el-carousel__item h3 {

    color: #303133;

    font-size: 32px;

    line-height: 150px;
    margin: 0;
  }


  .table-box {


    border: 8px solid #FAD294;
    border-radius: 27px;

    padding: 37px 85px 37px 85px;

    .table-th {
      height: 68px;



      border-radius: 34px;

      div {
        text-align: center;
        line-height: 68px;
        color: #EB1008;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .table-td {

      background: rgba(235, 16, 8, 0.05);
      box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.07);

      border-radius: 34px;
      margin-bottom: 20px;

      .table-imgbox {
        padding: 5px 0 2px 0;
        text-align: center;

        color: #4D4D4D;
        font-weight: bold;
        font-size: 18px;

        img {}
      }

      .table-avatarName {
        padding: 10px 0 10px 0;
        text-align: center;

        color: #4D4D4D;
        font-weight: bold;
        font-size: 18px;

        .box {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .avatar {
            height: 48px;
            width: 48px;
            margin-right: 10px;
          }

          .userName {
            font-size: 14px;

            font-weight: bold;
            color: #4D4D4D;
          }
        }

      }

      .table-integral {
        text-align: center;

        color: #4D4D4D;
        font-weight: bold;
        font-size: 14px;
        padding: 28px 0 29px 0;
      }

      .remark {
        text-align: center;

        color: #4D4D4D;
        font-weight: bold;
        font-size: 14px;
        padding: 26px 0 27px 0;
      }
    }

    .table-td-active {

      background: rgba(235, 16, 8, 0.18);
      box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.07);

      border-radius: 34px;
      margin-bottom: 20px;

      .table-imgbox {
        padding: 5px 0 2px 0;
        text-align: center;

        color: #4D4D4D;
        font-weight: bold;
        font-size: 18px;

        img {}
      }

      .table-avatarName {
        padding: 10px 0 10px 0;
        text-align: center;

        color: #4D4D4D;
        font-weight: bold;
        font-size: 18px;

        .box {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .avatar {
            height: 48px;
            width: 48px;
            margin-right: 10px;
          }

          .userName {
            font-size: 14px;

            font-weight: bold;
            color: #EB1008;
          }
        }

      }

      .table-integral {
        text-align: center;

        color: #EB1008;
        font-weight: bold;
        font-size: 14px;
        padding: 28px 0 29px 0;
      }

      .remark {
        text-align: center;

        color: #EB1008;
        font-weight: bold;
        font-size: 14px;
        padding: 26px 0 27px 0;
      }
    }
  }

  .search-box {
    position: relative;
    margin: 0 auto;
    width: 1440px;
    padding: 0.520833rem 0;
    border-bottom: 0.052083rem dashed #c3cad6;

    .line {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .label {
        margin: 0 1.041667rem 0 0;
        font-size: 1.041667rem;
        font-weight: bold;
        color: #7c8697;
        line-height: 2.083333rem;
        cursor: default;
      }

      ul {
        display: flex;
      }

      .opt {
        margin-right: 1.145833rem;
        font-size: 1.041667rem;
        font-weight: bold;
        color: #324057;
        line-height: 2.083333rem;
        cursor: pointer;
        transition: 0.5s;

        &.active {
          color: #1d7be3;
          cursor: default;
        }
      }
    }
  }
}</style>
