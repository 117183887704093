import request from '@/service/lib/request';

//查询课后讨论
export function tProjectClassHourInteract (data) {

    return request({
        url: 'module/tProjectClassHourInteract/findInteractPraiseByList',
        method: 'post',
        data
    });
}
//课后回复（针对题目回复）
export function addHourInteract (data) {

    return request({
        url: 'module/tProjectClassHourInteract/addHourInteract',
        method: 'post',
        data
    });
}
//课后互动讨论回复
export function tProjectInteractDetail (data) {

    return request({
        url: 'module/tProjectInteractDetail/addInteractDetail',
        method: 'post',
        data
    });
}
//互动主题id查询回复列表
export function findInteractPraiseByChild (params) {

    return request({
        url: 'module/tProjectClassHourInteract/findInteractPraiseByChild',
        method: 'get',
        params
    });
}
//思考题回答点赞
export function addInteractPraise (params) {

    return request({
        url: 'module/tProjectClassHourInteract/addInteractPraise',
        method: 'get',
        params
    });
}