import request from '@/service/lib/request';
//分页查询奖品兑换列表(含附件路径)
export function findPrizeExchangeListByPage(data) {

    return request({
        url: 'module/tProjectPrizeExchange/findPrizeExchangeListByPage',
        method: 'post',
        data
    });
}
//学生兑换奖品
export function prizeExchange(data) {

    return request({
        url: 'module/tProjectPrizeExchange/prizeExchange',
        method: 'post',
        data
    });
}
// 学员和课时查询兑换历史
export function findExchangeRecordListBy(params) {

    return request({
        url: 'module/tProjectExchangeRecord/findExchangeRecordListBy',
        method: 'get',
        params
    });
}
//学员和课时获取积分明细列表
export function findIntegralDetailBy(data) {

    return request({
        url: 'module/tProjectIntegralDetail/findIntegralDetailBy',
        method: 'post',
        data
    });
}